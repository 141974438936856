import { EmojiButton } from "@joeattardi/emoji-button";

const ProseMirrorMenuEmojiButton = ({ Name, Description, menubarClassName, dispatchMetaData }) => {
  let picker = new EmojiButton({
    position: "bottom-end",
    zIndex: 999,
    showVariants: false,
  });
  picker.on("emoji", (selection) => {
    dispatchMetaData({ type: "addEmoji", emoji: selection.emoji });
  });

  const toggleEmojiPicker = () => {
    picker.togglePicker(document.querySelector(".trigger"));
  };

  return (
    <button
      className={"trigger " + menubarClassName + "__icon" + Name}
      data-command-name={Name}
      data-tooltip={Description}
      onClick={toggleEmojiPicker}
      data-icon-type="toggle"
      style={{ fontSize: "20px", padding: "0 8px" }}
    >
      🙂
    </button>
  );
};
export default ProseMirrorMenuEmojiButton;
