import { SwrReq } from "../util";
import { SwrRes } from "../util";

export const useLevelList = (query) => {
  const { data, error } = SwrReq(`/admin/user-licenses`, query);
  return SwrRes(data, error);
};

export const useLevelDetail = (id) => {
  const { data, error } = SwrReq(`/admin/user-licenses/${id}`);
  return SwrRes(data, error);
};
