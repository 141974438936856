/**
 * 이모지를 삽입합니다.
 * @type {() => import("prosemirror-state").Command}
 */
export const insertEmoji = (emoji) => (state, dispatch) => {
  const { selection } = state;
  const { from, to } = state.selection;
  const transaction = state.tr.insertText(emoji, from, to);
  transaction.setSelection(
    state.selection.constructor.near(
      transaction.doc.resolve(selection.head + emoji.length)
    )
  );
  dispatch(transaction);
  return true;
};
