import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

/**
 * iframe 노드에서 개별적으로 대응하는 플랫폼들은 다음과 같습니다.
 * 
 *  1. Youtube:     유튜브 임베드 기능, 썸네일(data-youtube-id) 추출 기능.
 *  2. Google Form: 구글 폼 임베드 이후 높이 조절 기능.
 *  3. 콜러스 플레이어: 콜러스 플레이어의 크기 조절을 방지하는 기능.
 * 
 * 그외의 플랫폼은 data-platform="etc" 로 분류합니다.
 * 
 * iframe 노드의 NodeView, SCSS 파일을 참고해주세요.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const iframeNodeSpec = {
  attrs: {
    src: {},
    'data-youtube-id': {default: ''},
    'data-platform': {default: 'etc'},
    frameborder: {default: 0},
    allowfullscreen: {default: true},
    class: {default: MEDISTREAM_SCHEMA_STYLE.nodes.iframe},
    height: {default: 'auto'}
  },
  group: 'block',
  marks: '',
  draggable: true,
  toDOM: node => [
    'div',
    {
      style: 'display: flex;',
    },
    [
      'iframe',
      {
        src: node.attrs.src,
        frameborder: 0,
        allowfullscreen: true,
        class: node.attrs.class,
        height: node.attrs.height,
        'data-platform': node.attrs['data-platform'],
        'data-youtube-id': node.attrs['data-youtube-id'],
      },
    ]
  ],
  parseDOM: [
    {
      tag: 'iframe',
      getAttrs(dom) {
        const src = dom.getAttribute('src') || ''
        let platform = 'etc'
        if (src.startsWith('https://www.youtube.com/embed/')) {
          platform = 'youtube'
        } else if (src.startsWith('https://docs.google.com/forms/') || src.startsWith('https://forms.gle/')) {
          platform = 'google-form'
        } else if (src.startsWith('https://v.kr.kollus.com/')) {
          platform = 'kollus'
        }
        return {
          src: dom.getAttribute('src') || '',
          frameborder: dom.getAttribute('frameborder') || 0,
          allowfullscreen: dom.getAttribute('allowfullscreen') || true,
          height: dom.getAttribute('height') || 700,
          'data-youtube-id': dom.getAttribute('data-youtube-id') || '',
          'data-platform': platform,
        }
      }
    }
  ]
}
