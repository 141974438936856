import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const delDom = ['del', {class: MEDISTREAM_SCHEMA_STYLE.marks.del}, 0]

/**
 * 취소선은 <del> 태그로 표현됩니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const delMarkSpec = {
  toDOM: () => delDom,
  parseDOM: [{tag: 'del'}, {style: 'text-decoration=line-through'}],
}
