import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const blockquoteDOM = [
  'blockquote',
  {class: MEDISTREAM_SCHEMA_STYLE.nodes.blockquote},
  0,
]

/**
 * 인용구는 <blockquote> 태그로 표현됩니다.
 * 한 개 이상의 Paragraph 혹은 Heading 을 컨텐츠로 가집니다.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const blockquoteNodeSpec = {
  content: '(paragraph|heading)+',
  group: 'block',
  defining: true,
  parseDOM: [{tag: 'blockquote'}],
  toDOM() {
    return blockquoteDOM
  },
}
