import { Node } from "prosemirror-model";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

const ALIGN_PATTERN = /(left|right|center|justify)/;

/**
 * 본문 블록은 p 태그로 표현됩니다.
 * 최소 0 개 이상의 인라인을 컨텐츠로 가집니다.
 *
 * 참고한 자료: https://github.com/chanzuckerberg/czi-prosemirror/blob/master/src/paragraphNodeSpec.js
 *      https://discuss.prosemirror.net/t/implementing-alignment/731/4
 *
 * @type {import("prosemirror-model").NodeSpec}
 */
export const paragraphNodeSpec = {
  attrs: {
    align: { default: null },
  },
  content: "inline*",
  group: "block",
  parseDOM: [{ tag: "p", getAttrs }],
  toDOM,
};

/**
 *
 * @param {HTMLElement} dom
 * @returns {Object}
 */
function getAttrs(dom) {
  const { textAlign } = dom.style;

  let align = dom.getAttribute("align") || textAlign || "";

  align = ALIGN_PATTERN.test(align) ? align : null;

  return { align };
}

/**
 *
 * @param {import('prosemirror-model').Node} node
 * @returns {Array<any>}
 */
function toDOM(node) {
  const { align } = node.attrs;
  const attrs = {
    class: MEDISTREAM_SCHEMA_STYLE.nodes.paragraph,
  };

  let style = "";

  if (align && align !== "left") {
    style += `text-align: ${align};`;
  }

  style && (attrs.style = style);

  return ["p", attrs, 0];
}

export const toParagraphDOM = toDOM;
export const getParagraphNodeAttrs = getAttrs;
