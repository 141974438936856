import { SwrReq } from "../util";
import { SwrRes } from "../util";

export const useMemberList = (query) => {
  const { data, error } = SwrReq(`/admin/users`, query);
  return SwrRes(data, error);
};

export const useMemberCount = () => {
  const { data, error } = SwrReq("/admin/users/count");
  return SwrRes(data, error);
};

export const useMemberDetail = (id) => {
  const { data, error } = SwrReq(`/admin/users/${id}`);
  return SwrRes(data, error);
};
