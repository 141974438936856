import {
  wrapIn,
  setBlockType,
  chainCommands,
  toggleMark,
  exitCode,
  joinUp,
  joinDown,
  lift,
} from 'prosemirror-commands'
import {
  wrapInList,
  splitListItem,
  liftListItem,
  sinkListItem,
} from 'prosemirror-schema-list'
import {undo, redo} from 'prosemirror-history'
import {undoInputRule} from 'prosemirror-inputrules'
import {
  addColumnAfter,
  addColumnBefore,
  addRowAfter,
  addRowBefore,
  deleteColumn,
  deleteRow,
  goToNextCell,
} from '@medistream/prosemirror-tables'

import { addCallout, toggleCellBorder } from '../commands/table'

const mac =
  typeof navigator != 'undefined'
    ? /Mac|iP(hone|[oa]d)/.test(navigator.platform)
    : false

/**
 * 에디터에서 사용할 단축키를 정의합니다.
 * 
 * 참고한 자료: https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/keymap.ts
 * 
 * @param {import('prosemirror-model').Schema} schema
 */
export const buildKeymap = (schema) => {
  const keys = {}

  const bind = (key, cmd) => {
    keys[key] = cmd
  }

  const shiftEnter = chainCommands(exitCode, (state, dispatch) => {
    if (dispatch)
      dispatch(state.tr.replaceSelectionWith(schema.nodes.hard_break.create()).scrollIntoView())
    return true
  })

  bind('Enter', splitListItem(schema.nodes.list_item))
  
  bind('Shift-Enter', shiftEnter)
  
  bind('Tab', chainCommands(sinkListItem(schema.nodes.list_item), goToNextCell(1)))
  
  bind('Shift-Tab', chainCommands(liftListItem(schema.nodes.list_item), goToNextCell(-1)))

  bind('Mod-z', undo)
  bind('Mod-Z', undo)

  if (mac) {
    bind('Shift-Mod-z', redo)
    bind('Shift-Mod-Z', redo)
  } else {
    bind('Mod-y', redo)
    bind('Mod-Y', redo)
  }

  bind('Mod-B', toggleMark(schema.marks.strong))
  bind('Mod-b', toggleMark(schema.marks.strong))

  bind('Mod-I', toggleMark(schema.marks.em))
  bind('Mod-i', toggleMark(schema.marks.em))

  bind('Mod-U', toggleMark(schema.marks.underline))
  bind('Mod-u', toggleMark(schema.marks.underline))

  bind('Mod-BracketLeft', lift)

  bind('Ctrl-Alt-S', toggleMark(schema.marks.del))
  bind('Ctrl-Alt-s', toggleMark(schema.marks.del))
  
  bind('Ctrl-Alt-H', chainCommands(setBlockType(schema.nodes.paragraph), setBlockType(schema.nodes.heading, {level: 2})))
  bind('Ctrl-Alt-h', chainCommands(setBlockType(schema.nodes.paragraph), setBlockType(schema.nodes.heading, {level: 2})))
  
  bind('Ctrl-Alt-M', deleteRow)
  bind('Ctrl-Alt-m', deleteRow)
  
  bind('Ctrl-Alt-L', deleteColumn)
  bind('Ctrl-Alt-l', deleteColumn)
  
  bind('Ctrl-Alt-A', toggleCellBorder)
  bind('Ctrl-Alt-a', toggleCellBorder)

  bind('Ctrl-Alt-z', addCallout)
  bind('Ctrl-Alt-Z', addCallout)

  bind('Ctrl-Alt-9', wrapInList(schema.nodes.ordered_list))
  
  bind('Ctrl-Alt-8', wrapInList(schema.nodes.bullet_list))

  bind('Ctrl-Alt-7', wrapIn(schema.nodes.blockquote))
  
  bind('Ctrl-Alt-ArrowRight', addColumnAfter)
  
  bind('Ctrl-Alt-ArrowLeft', addColumnBefore)
  
  bind('Ctrl-Alt-ArrowUp', addRowBefore)
  
  bind('Ctrl-Alt-ArrowDown', addRowAfter)

  bind('Alt-ArrowUp', joinUp)

  bind('Alt-ArrowDown', joinDown)

  bind('Backspace', undoInputRule)

  return keys
}
