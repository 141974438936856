import {
  inputRules,
  wrappingInputRule,
  textblockTypeInputRule,
  smartQuotes,
  emDash,
  ellipsis,
  InputRule,
} from 'prosemirror-inputrules'

/**
 * 참고한 자료: https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/inputrules.ts
 */

/**
 * Given a blockquote node type, returns an input rule that turns `"> "`
 * at the start of a textblock into a blockquote.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @returns
 */
export function blockQuoteRule(nodeType) {
  return wrappingInputRule(/^\s*>\s$/, nodeType)
}

/**
 * Given a list node type, returns an input rule that turns a number
 * followed by a dot at the start of a textblock into an ordered list.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @returns
 */
export function orderedListRule(nodeType) {
  return wrappingInputRule(
    /^(1)\.\s$/,
    nodeType,
    match => ({order: +match[1]}),
    (match, node) => node.childCount + node.attrs.order == +match[1],
  )
}

/**
 * Given a list node type, returns an input rule that turns a bullet
 * (dash, plush, or asterisk) at the start of a textblock into a
 * bullet list.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @returns
 */
export function bulletListRule(nodeType) {
  return wrappingInputRule(/^\s*([-+*])\s$/, nodeType)
}

/**
 * Given a code block node type, returns an input rule that turns a
 * textblock starting with three backticks into a code block.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @returns
 */
export function codeBlockRule(nodeType) {
  return textblockTypeInputRule(/^```$/, nodeType)
}

/**
 * Create a rule that jumps out of the current mark when
 * two spaces are typed. This makes exiting a mark while staying in the same
 * paragraph very easy and fluent.
 *
 * Taken from https://github.com/ProseMirror/prosemirror/issues/858
 *
 * @param {import('prosemirror-model').MarkType} type
 *   The mark type to exit from.
 *
 * @returns {import('prosemirror-inputrules').InputRule}
 *   A ProseMirror InputRule.
 */
export function jumpOutMarks(type) {
  // This regex matches on two consecutive whitespace characters
  // right before the cursor.
  return new InputRule(/\s\s$/, state => {
    const {$cursor} = state.selection

    // If the given mark type is in the stored marks
    // (i.e. you would begin typing with that mark next)
    // or in the marks of the cursors current position, then remove it.
    if (type.isInSet(state.storedMarks || $cursor.marks())) {
      return (
        state.tr
          // Remove the spaces.
          .insertText('', $cursor.pos - 1, $cursor.pos)
          // Remove the mark, so that we type "outside" of it next.
          .removeStoredMark(type)
      )
    }
  })
}

/**
 * Given a node type and a maximum level, creates an input rule that
 * turns up to that number of `#` characters followed by a space at
 * the start of a textblock into a heading whose level corresponds to
 * the number of `#` signs.
 *
 * @param {import('prosemirror-model').NodeType} nodeType
 * @param {number} maxLevel
 * @returns
 */
export function headingRule(nodeType, maxLevel) {
  return textblockTypeInputRule(
    new RegExp('^(#{1,' + maxLevel + '})\\s$'),
    nodeType,
    match => ({level: match[1].length}),
  )
}

/**
 * A set of input rules for creating the basic block quotes, lists,
 * code blocks, and heading.
 *
 * @param {import('prosemirror-model').Schema} schema
 * @returns
 */
export function buildInputRules(schema) {
  let rules = smartQuotes.concat(ellipsis, emDash),
    type
  if ((type = schema.nodes.blockquote)) rules.push(blockQuoteRule(type))
  if ((type = schema.nodes.ordered_list)) rules.push(orderedListRule(type))
  if ((type = schema.nodes.bullet_list)) rules.push(bulletListRule(type))
  if ((type = schema.nodes.code_block)) rules.push(codeBlockRule(type))
  if ((type = schema.nodes.heading)) rules.push(headingRule(type, 2))
  return inputRules({rules})
}
