import { keymap } from 'prosemirror-keymap'
import { history } from 'prosemirror-history'
import { baseKeymap } from 'prosemirror-commands'
import { dropCursor } from 'prosemirror-dropcursor'
import { gapCursor } from 'prosemirror-gapcursor'
import { columnResizing, tableEditing } from '@medistream/prosemirror-tables'

import { buildKeymap } from './keymap'
import { buildInputRules } from '../utils/inputRules'
import { linkPlugin } from './link'
import { linkTooltip } from './linkToolTip'
import { indicatorPlugin } from './indicator'
import { iframeLinkInputPlugin } from './iframe'
import { remoteMenu } from './remoteMenu'
import { htmlInsertPlugin } from './htmlInsert'
import { MEDISTREAM_SCHEMA_STYLE } from '../styles/classNames'
import { cellTooltip } from './cellToolTip'
import { medistreamSchema } from '../schemas'

export const tableConstants = {
  CELL_MIN_WIDTH: 25,
  HANDLE_WIDTH: 10,
}

export const builtInPlugins = [
  history(),
  gapCursor(),
  dropCursor(),
  ...linkPlugin(),
  keymap(buildKeymap(medistreamSchema)),
  keymap(baseKeymap),
  buildInputRules(medistreamSchema),
  remoteMenu,
  linkTooltip,
  cellTooltip,
  indicatorPlugin,
  iframeLinkInputPlugin,
  htmlInsertPlugin,
  columnResizing({
    lastColumnResizable: true,
    handleWidth: tableConstants.HANDLE_WIDTH,
    cellMinWidth: tableConstants.CELL_MIN_WIDTH,
    wrapperClassNames: [MEDISTREAM_SCHEMA_STYLE.etc.tableWrapper, MEDISTREAM_SCHEMA_STYLE.etc.selectable]
  }),
  tableEditing({ allowTableNodeSelection: true }), // key binding 을 위해 tableEditing 을 마지막에 추가합니다.
]
