import dayjs from "dayjs";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import { axiosClient } from "../../api/axiosClient";
import { useBoardList } from "../../api/board";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup";
import { cls } from "../../util";

export default function Notice() {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const searchQuery = urlParam.get("searchQuery");
  const boardType = urlParam.get("boardType") || "board";
  const [deletePopup, setDeletePopup] = useState(false);

  const { data: noticeList, isLoading: noticeListLoading } = useBoardList(
    boardType,
    {
      page,
      searchQuery,
      isNotice: true,
      perPage: 16,
    }
  );

  const onPageClick = (selectedItem) => {
    const selected = selectedItem.selected + 1;
    urlParam.set("page", selected);
    navigate(`/notice?${urlParam}`);
  };

  const deleteNotice = (id) => {
    axiosClient(token)
      .delete(`/admin/${boardType === "board" ? "boards" : boardType}/${id}`)
      .then(() => {
        alert("삭제되었습니다");
        mutate({
          url: `/admin/${boardType === "board" ? "boards" : boardType}`,
          page,
          searchQuery,
          isNotice: true,
          perPage: 16,
        });
      })
      .catch(() => alert("에러가 발생하였습니다"));
    setDeletePopup(false);
  };

  if (!noticeList || noticeListLoading) return <></>;
  return (
    <div className="pb-20">
      {deletePopup && (
        <Popup
          setPopup={setDeletePopup}
          fetch={() => deleteNotice(deletePopup)}
        />
      )}
      <Header />
      <div className="inner page">
        <div className="page-tit">공지관리</div>
        <div className="flex items-end justify-between mt-5 mb-7">
          <div>
            <Link to="/notice">
              <button
                className={cls(
                  boardType === "board" ? "main" : "main-white",
                  "mr-4"
                )}>
                게시판
              </button>
            </Link>
            <Link to="/notice?boardType=qna">
              <button
                className={cls(boardType === "qna" ? "main" : "main-white")}>
                Q&A
              </button>
            </Link>
          </div>
          <Link to={`/notice/write?boardType=${boardType}`}>
            <button className="main">등록</button>
          </Link>
        </div>
        <div className="flex items-center justify-evenly w-[1200px] bg-main text-white py-[6px] pl-3 text-base font-semibold">
          <div className="w-[15%]">카테고리</div>
          <div className="w-[55%]">제목</div>
          <div className="w-[10%]">등록일</div>
          <div className="w-[10%] flex items-center justify-center">수정</div>
          <div className="w-[10%] flex items-center justify-center">삭제</div>
        </div>
        {noticeList.articleList?.map((article) => (
          <div
            key={article?.id}
            className="flex items-center justify-evenly w-[1200px] py-6 border-b-[1px] border-[#999] pl-3 text-[#666] font-semibold text-base">
            <div className="w-[15%]">{article?.category?.title}</div>
            <div className="w-[55%] truncate">{article?.title}</div>
            <div className="w-[10%]">
              {article.createdAt
                ? dayjs(article.createdAt).format("YYYY-MM-DD hh:mm")
                : ""}
            </div>
            <div className="w-[10%] flex items-center justify-center">
              <button
                className="dark"
                onClick={() =>
                  navigate(
                    `/notice/modify/${article?.id}?boardType=${boardType}`
                  )
                }>
                수정
              </button>
            </div>
            <div className="w-[10%] flex items-center justify-center">
              <button
                className="white"
                onClick={() => setDeletePopup(article?.id)}>
                삭제
              </button>
            </div>
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel="←"
        nextLabel="→"
        pageCount={noticeList.totalPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        pageClassName="page"
        activeClassName="active"
        onPageChange={onPageClick}
        forcePage={noticeList.currentPage - 1}
        className="flex items-center justify-center space-x-2 pagination mt-14"></ReactPaginate>
    </div>
  );
}
