const FileInput = ({ selectFile, file }) => {
  return (
    <div>
      <label className="border-[1px] border-[#CFCFCF] w-full rounded-md cursor-pointer flex items-center space-x-4">
        <div className="bg-[#EFEFEF] w-28 h-9 rounded-l-md flex items-center justify-center text-sm">
          파일선택
        </div>
        <input
          type="file"
          className="hidden"
          placeholder="파일을 선택해주세요"
          accept="image/png,image/jpeg,image/jpg"
          onChange={selectFile}
        />
        <div className="text-sm text-[#999] font-medium truncate pr-4">
          {file || "파일을 선택해주세요"}
        </div>
      </label>
    </div>
  );
};

export default FileInput;
