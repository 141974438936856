import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "../../api/axiosClient";
import { useMemberDetail } from "../../api/member";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup";
import ZoomImg from "../../components/ZoomImg";
import { USER_TYPES } from "../../constants";
import Game from "../game/Game";

const MemberDetail = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const editMode = true;
  const [type, setType] = useState("");
  const [licenseId, setLicenseId] = useState(""); // 면허번호/학번
  const [school, setSchool] = useState("");
  const [popupState, setPopupState] = useState(false);

  const { data: memberDetail, isLoading: memberDetailLoading } =
    useMemberDetail(id);

  useEffect(() => {
    if (!memberDetail) return;
    setType(memberDetail.userType || "");
    setLicenseId(
      memberDetail?.collegeStudentId ||
        memberDetail?.hygienistCertificateId ||
        ""
    );
    setSchool(
      memberDetail?.userType === "student" ? memberDetail?.college : ""
    );
  }, [memberDetail]);

  const changeUserType = () => {
    if (!type) {
      alert("회원분류를 선택해주세요");
      return;
    }
    axiosClient(token)
      .patch(`/admin/users/${id}`, { userType: type })
      .then(() => {
        alert("변경되었습니다.");
        setPopupState(false);
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  const changeUserLicense = () => {
    if (!licenseId) {
      alert("면허/학번을 선택해주세요");
      return;
    }
    axiosClient(token)
      .patch(
        `/admin/users/${id}`,
        memberDetail?.userType === "student"
          ? { college: school, collegeStudentId: licenseId }
          : { hygienistCertificateId: licenseId }
      )
      .then(() => {
        alert("변경되었습니다.");
        setPopupState(false);
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  if (memberDetailLoading || !memberDetail) return <></>;
  return (
    <div>
      {popupState && (
        <Popup
          text={`${popupState.text} 상태를 변경하시겠습니까?`}
          setPopup={setPopupState}
          fetch={popupState.fetch}
        />
      )}
      <Header />
      <div className="inner page">
        <div
          className="text-[21px] font-semibold text-[#333] cursor-pointer"
          onClick={() => navigate("/member")}>
          ← 목록으로
        </div>
        <div className="px-3 py-1 mt-8 text-white bg-main">회원정보</div>
        <div className="flex mt-5">
          <div className="flex justify-between w-1/2">
            <div className="w-1/4 bg-[#F3F3F3] rounded-xl flex flex-col items-center py-8 font-semibold">
              <div className="memberInfo-cell">아이디</div>
              <div className="memberInfo-cell">이름</div>
              <div className="memberInfo-cell">이메일</div>
              <div className="memberInfo-cell">회원유형</div>
              <div className="memberInfo-cell">생년월일</div>
            </div>
            <div className="flex flex-col w-8/12 py-8">
              <div className="memberInfo-cell">{memberDetail?.username}</div>
              <div className="memberInfo-cell">{memberDetail?.fullname}</div>
              <div className="memberInfo-cell">{memberDetail?.email}</div>
              <div className="space-x-20 memberInfo-cell">
                {editMode ? (
                  <select
                    className="shadow-custom"
                    value={type}
                    onChange={(e) => setType(e.target.value)}>
                    <option value="">비회원</option>
                    {USER_TYPES.map((type, idx) => (
                      <option value={type.value} key={idx}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className="text-main">치과위생사</div>
                )}
                <button
                  className="border-[1px] border-[#ccc] h-[30px] px-3 rounded-md text-sm"
                  onClick={() =>
                    setPopupState({
                      text: "회원분류",
                      fetch: () => changeUserType(),
                    })
                  }>
                  수정
                </button>
              </div>
              <div className="memberInfo-cell">
                {memberDetail.birthday
                  ? dayjs(memberDetail?.birthday).format("YYYY.MM.DD")
                  : ""}
              </div>
            </div>
          </div>
          <div className="flex justify-between w-1/2">
            <div className="w-1/4 bg-[#F3F3F3] rounded-xl flex flex-col items-center py-8 font-semibold">
              <div className="memberInfo-cell">비밀번호</div>
              <div className="memberInfo-cell">연락처</div>
              <div className="memberInfo-cell">닉네임</div>
              <div className="memberInfo-cell">추천인</div>
            </div>
            <div className="flex flex-col w-8/12 py-8">
              <div className="memberInfo-cell">****</div>
              <div className="memberInfo-cell">{memberDetail?.phoneNumber}</div>
              <div className="memberInfo-cell">{memberDetail?.nickname}</div>
              <div className="memberInfo-cell">
                {memberDetail?.recommendUser?.username}
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 py-1 mt-8 text-white bg-main">가입정보</div>
        <div className="flex">
          <div className="flex w-1/2">
            <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                회원가입일
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                {memberDetail.createdAt
                  ? dayjs(memberDetail.createdAt).format("YYYY-MM-DD hh:mm")
                  : ""}
              </div>
            </div>
          </div>
          <div className="flex w-1/2">
            <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                이메일 수신 동의
              </div>
            </div>
            <div className="flex flex-col w-3/4">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                {memberDetail?.agreeMarketing ? "동의" : "비동의"}
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 py-1 mt-8 text-white bg-main">면허/학번정보</div>
        <div className="w-full flex border-b-[1px] border-[#ccc]">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="px-4 memberInfo-cell">면허번호/학번</div>
          </div>
          <div className="flex items-center w-3/4">
            {editMode ? (
              <>
                {memberDetail?.userType === "student" && (
                  <div className="flex items-center w-1/3 px-4 space-x-2">
                    <input
                      type="text"
                      className="px-1 py-1 rounded-sm shadow-custom"
                      placeholder="학교명"
                      value={school}
                      onChange={(e) => setSchool(e.target.value)}></input>
                  </div>
                )}
                <input
                  type="text"
                  className="px-2 py-1 mx-10 rounded-sm shadow-custom"
                  value={licenseId}
                  onChange={(e) => setLicenseId(e.target.value)}></input>
              </>
            ) : (
              <div className="w-1/2 px-4 memberInfo-cell">12345</div>
            )}
            <button
              className="border-[1px] border-[#ccc] h-[33px] px-5 rounded-md"
              onClick={() =>
                setPopupState({
                  text: "면허/학번",
                  fetch: () => changeUserLicense(),
                })
              }>
              수정
            </button>
          </div>
        </div>

        <div className="px-3 py-1 mt-8 text-white bg-main">
          첨부 면허/학생증
        </div>
        <ZoomImg>
          {memberDetail?.userLicense?.length > 0 &&
            memberDetail?.userLicense.map((item) => (
              <img src={item?.licenseFileUrl} alt="" />
            ))}
        </ZoomImg>

        <Game />
        <div className="flex items-center justify-center w-full mt-16">
          <button
            className="dark rounded-md w-[135px] h-[51px] m-auto cursor-pointer"
            onClick={() => navigate("/member")}>
            목록으로
          </button>
        </div>
      </div>
    </div>
  );
};

export default MemberDetail;
