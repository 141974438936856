import useSWR from "swr";
import { axiosClient } from "./api/axiosClient";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export function cls(...classnames) {
  return classnames.join(" ");
}

export const SwrReq = (url, query) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const api = axiosClient(token);
  const { disabled, ...params } =
    query && query.disabled ? query : { disabled: false, ...query };
  const swrKey = query && disabled ? null : { url, ...params }; // 조건부 fetching

  api.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) {
      navigate("/login");
      return Promise.reject(error);
    }
    if (error.response?.status === 403) {
      navigate(-1);
    }
  });

  return useSWR(swrKey, () => api.get(url, { params }), {
    errorRetryInterval: 100,
  });
};

export const SwrRes = (data, error) => {
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const CHEESTALK_CLIENT_ID = "01HMB05AHJ6Z60Z0NFXX99MQ1L";

const query = {
  client_id: CHEESTALK_CLIENT_ID,
  response_type: "code",
  redirect_uri: `${process.env.REACT_APP_BASE_URL}/auth`,
};

export const authQuery = Object.entries(query)
  .map(
    ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  )
  .join("&");

export const authURI = `${process.env.REACT_APP_REDIRECT_AUTH}/login?${authQuery}`;

export const authLogoutURI = `${process.env.REACT_APP_REDIRECT_AUTH}/logout?redirect_uri=${process.env.REACT_APP_BASE_URL}`;

export const setTokens = (tokens) => {
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    id_token: idToken,
  } = tokens;

  if (accessToken) localStorage.setItem("accessToken", accessToken);
  if (refreshToken) localStorage.setItem("refreshToken", refreshToken);
  if (idToken) localStorage.setItem("idToken", idToken);
};

export function cloudFrontFile(urlRes) {
  const url = new URL(urlRes);
  const pathParts = url.pathname.split("/").filter(Boolean);

  const isDdevelopment = process.env.REACT_APP_PUBLIC_API_URL.includes("dev");

  const relevantPath = isDdevelopment
    ? pathParts.slice(2).join("/")
    : pathParts.slice(1).join("/");

  const baseUrl = isDdevelopment
    ? "https://public-file.dev.cheestalk.com/development/"
    : "https://public-file.cheestalk.com/";

  return `${baseUrl}${relevantPath}`;
}

export const uploadImg = async (e, token, endpoint) => {
  if (
    !e ||
    !e.target ||
    !e.target.files ||
    !e.target.files.length ||
    !e.target.files[0].type.match(/image\//)
  )
    return;
  try {
    const res = await axiosClient(token).post(endpoint, {
      filename: e.target.files[0].name,
    });
    const api = res.data;
    const uploadUrl = api?.url;
    const arrayBuffer = await e.target.files[0].arrayBuffer();
    const binaryData = new Uint8Array(arrayBuffer);

    await axios.put(uploadUrl, binaryData);
    return uploadUrl;
  } catch (err) {
    console.log(err);
    alert("이미지 업로드에 실패했습니다.");
  }
};
