import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

/**
 * 이미지는 <img> 태그로 표현됩니다.
 * 허용된 Attributes 는 src, alt, title, width, height 입니다.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const imageNodeSpec = {
  attrs: {
    src: {},
    alt: {default: null},
    title: {default: null},
    width: {default: null},
    height: {default: 'auto'},
  },
  inline: true,
  group: 'inline',
  draggable: true,
  marks: 'link',
  toDOM(node) {
    const {src, alt, title, width, height} = node.attrs
    return [
      'img',
      {
        src,
        alt,
        title,
        width,
        height,
        class: MEDISTREAM_SCHEMA_STYLE.nodes.image,
      },
    ]
  },
  parseDOM: [
    {
      tag: 'img[src]',
      /**
       * @param {HTMLElement} dom
       */
      getAttrs: dom => ({
        src: dom.getAttribute('src'),
        title: dom.getAttribute('title'),
        alt: dom.getAttribute('alt'),
        width: dom.getAttribute('width'),
        height: dom.getAttribute('height'),
      }),
    },
  ],
}
