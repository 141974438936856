import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosClient } from "../../api/axiosClient";
import { useLevelDetail } from "../../api/level";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup";
import ZoomImg from "../../components/ZoomImg";

const LevelDetail = () => {
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [licenseId, setLicenseId] = useState(""); // 면허번호/학번
  const [school, setSchool] = useState("");
  const [popupState, setPopupState] = useState(false);

  const { data: levelDetail, isLoading: levelDetailLoading } =
    useLevelDetail(id);

  useEffect(() => {
    if (!levelDetail) return;
    setStatus(levelDetail?.approved);
    setType(levelDetail?.userType);
    // setLicenseId(); 누락
    // setSchool()
  }, [levelDetail]);

  const changeUserStatus = () => {
    if (!status) {
      alert("승인여부를 선택해주세요");
      return;
    }
    axiosClient(token)
      .patch(`/admin/user-licenses/${id}`, {
        approved: status,
      })
      .then(() => {
        alert("변경되었습니다.");
        setPopupState(false);
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  const changeUserLicense = () => {
    if (!type) {
      alert("회원분류를 선택해주세요");
      return;
    }
    if (!licenseId) {
      alert("면허/학번을 선택해주세요");
      return;
    }
    axiosClient(token)
      .patch(
        `/admin/users/${levelDetail?.user?.id}`,
        type === "student"
          ? {
              userType: "student",
              college: school,
              collegeStudentId: licenseId,
            }
          : { userType: type, hygienistCertificateId: licenseId }
      )
      .then(() => {
        alert("변경되었습니다.");
        setPopupState(false);
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  if (!levelDetail || levelDetailLoading) return <></>;
  return (
    <div>
      {popupState && (
        <Popup
          text={`${popupState.text} 상태를 변경하시겠습니까?`}
          setPopup={setPopupState}
          fetch={popupState.fetch}
        />
      )}
      <Header />
      <div className="px-4 inner page">
        <div className="text-[21px] font-semibold text-[#333]">상태변경</div>
        <div className="px-3 py-1 mt-8 text-white bg-main">회원정보</div>
        <div className="w-full flex border-b-[1px] border-[#ccc]">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="px-4 memberInfo-cell">승인여부</div>
          </div>
          <div className="flex items-center justify-between w-3/4">
            <div
              className="flex items-center w-1/3 px-4 space-x-2"
              onClick={() => setStatus("approve")}>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={status === "approve"}></input>
              <span>승인</span>
            </div>
            <div
              className="flex items-center w-1/3 px-4 space-x-2"
              onClick={() => setStatus("reject")}>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={status === "reject"}></input>
              <span>보류</span>
            </div>
            <button
              className="dark rounded-md w-[115px] h-[35px] m-auto mr-0"
              onClick={() =>
                setPopupState({
                  text: "승인여부",
                  fetch: () => changeUserStatus(),
                })
              }>
              상태변경
            </button>
          </div>
        </div>
        <div className="w-full flex border-b-[1px] border-[#ccc]">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="px-4 memberInfo-cell">회원분류</div>
          </div>
          <div className="flex items-center w-3/4">
            <div
              className="flex items-center w-1/6 px-4 space-x-2"
              onClick={() => setType("hygienist")}>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={type === "hygienist"}></input>
              <span>치과위생사</span>
            </div>
            <div
              className="flex items-center w-1/6 px-4 space-x-2"
              onClick={() => setType("student")}>
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={type === "student"}></input>
              <span>치위학생</span>
            </div>
          </div>
        </div>
        <div className="w-full flex border-b-[1px] border-[#ccc]">
          <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
            <div className="px-4 memberInfo-cell">면허/학번</div>
          </div>
          <div className="flex items-center w-3/4">
            {type === "student" && (
              <div className="flex items-center w-1/3 px-4 space-x-2">
                <input
                  type="text"
                  className="px-1 py-1 rounded-sm shadow-custom"
                  placeholder="학교명"
                  value={school}
                  onChange={(e) => setSchool(e.target.value)}></input>
              </div>
            )}
            <div className="flex items-center w-1/3 px-4 space-x-2">
              <input
                type="text"
                className="px-1 py-1 rounded-sm shadow-custom"
                placeholder={type === "student" ? "학번" : "면허번호"}
                value={licenseId}
                onChange={(e) => setLicenseId(e.target.value)}></input>
            </div>
            <button
              className="dark rounded-md w-[115px] h-[35px] m-auto mr-0"
              onClick={() =>
                setPopupState({
                  text: "면허/학번",
                  fetch: () => changeUserLicense(),
                })
              }>
              상태변경
            </button>
          </div>
        </div>
        <div className="flex mt-5"></div>
        <div className="px-3 py-1 mt-8 text-white bg-main">가입정보</div>
        <div className="flex">
          <div className="flex w-1/4">
            <div className="w-1/2 bg-[#F3F3F3] flex flex-col">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                이름
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                {levelDetail?.user?.fullname}
              </div>
            </div>
          </div>
          <div className="flex w-1/4">
            <div className="w-1/2 bg-[#F3F3F3] flex flex-col">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                아이디
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                {levelDetail?.user?.username}
              </div>
            </div>
          </div>
          <div className="flex w-1/4">
            <div className="w-1/2 bg-[#F3F3F3] flex flex-col">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                연락처
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                {levelDetail?.user?.phoneNumber}
              </div>
            </div>
          </div>
          <div className="flex w-1/4">
            <div className="w-1/2 bg-[#F3F3F3] flex flex-col">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                생년월일
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="memberInfo-cell px-4 border-b-[1px] border-[#ccc]">
                {levelDetail.user?.birthday
                  ? dayjs(levelDetail.user.birthday).format("YYYY.MM.DD")
                  : ""}
              </div>
            </div>
          </div>
        </div>

        <div className="px-3 py-1 mt-8 text-white bg-main">
          첨부 면허/학생증
        </div>
        <ZoomImg>
          <img src={levelDetail?.licenseFileUrl} alt="" className="w-[500px]" />
        </ZoomImg>
      </div>
    </div>
  );
};

export default LevelDetail;
