import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Popup from '../../components/Popup';

const ContentsCate = () => {
  const [popup, setPopup] = useState(false);
  const [cate, setCate] = useState('');
  const [order, setOrder] = useState(0);

  console.log(cate, order);

  return (
    <div>
      {popup && <Popup setPopup={setPopup} />}
      <Header />
      <div className="px-4 max-w-[1200px] m-auto">
        <div className="flex items-center justify-between">
          <div className="text-[23px] font-semibold text-[#333]">
            콘텐츠 카테고리
          </div>
          <Link to={'/contentscate/registration'}>
            <button className="main">등록</button>
          </Link>
        </div>
        <div className="table">
          <div
            className="row header rounded-xl"
            style={{ background: '#F4F6F8', padding: '15px 0' }}
          >
            <div className="w-[25%] ml-8 text-[#637381]">이미지</div>
            <div className="w-[25%] text-[#637381]">카테고리 명</div>
            <div className="w-[25%] text-[#637381]">노출순서</div>
            <div className="w-[25%] text-[#637381] justify-center">관리</div>
          </div>
          {[1, 2, 3, 4, 5].map((member, idx) => (
            <>
              <div
                className="row"
                key={idx}
                style={{ padding: '10px 0', borderBottom: 'none' }}
              >
                <div className="w-[25%] ml-8">
                  <div className="w-[150px] h-[150px] bg-[url('/public/dumy.png')]"></div>
                </div>
                <div className="w-[25%]">
                  <input
                    type="text"
                    value={'공동구매'}
                    onChange={(e) => setCate(e.target.value)}
                  />
                </div>
                <div className="w-[25%]">
                  <input
                    type="text"
                    value={'100'}
                    onChange={(e) => setOrder(e.target.value)}
                  />
                </div>
                <div className="w-[25%] justify-center">
                  <button className="dark">수정</button>
                  <button className="white" onClick={() => setPopup(true)}>
                    삭제
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentsCate;
