import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { authLogoutURI, setTokens } from "../../util";
import { jwtDecode } from "jwt-decode";

export const decodeToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default function Auth() {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const code = urlParam.get("code") ?? null;

  useEffect(() => {
    if (!code) return;
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API_URL}/auth/token?code=${code}`)
      .then((res) => {
        const accessToken = res.data.access_token;
        const decodedData = decodeToken(accessToken);
        const idp = decodedData?.idp;

        if (idp === "moreden") {
          window.location.href = authLogoutURI;
          return;
        }

        setTokens(res.data);

        navigate("/member");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return <></>;
}
