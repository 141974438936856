import { SwrReq } from "../util";
import { SwrRes } from "../util";
import { axiosClient } from "./axiosClient";

export const useGameList = () => {
  const { data, error } = SwrReq("/admin/games");
  return SwrRes(data, error);
};

export const useUserGameResource = (userId) => {
  const { data, error } = SwrReq(`/admin/users/${userId}/game-resources`);
  return SwrRes(data, error);
};

export const useUserGameLog = (userId) => {
  const { data, error } = SwrReq(`/admin/users/${userId}/game-logs`);
  return SwrRes(data, error);
};

export const useUserGamePassLog = (userId) => {
  const { data, error } = SwrReq(`/admin/users/${userId}/game-pass-logs`);
  return SwrRes(data, error);
};

export const postGameLog = async (token, data) => {
  try {
    const res = await axiosClient(token).post("/admin/games/pass-logs", data);
    return res;
  } catch (e) {
    console.log("e");
    throw e;
  }
};
