import {TextSelection} from 'prosemirror-state'
import { findIndicator, indicatorPlugin } from '../plugins/indicator'

/**
 * 업로드 시작시 커서 위치에 로딩 인디케이터를 표시합니다.
 * @type {() => import('prosemirror-state').Command}
 */
export const displayUploadIndicator = (id, coords = null) => (state, dispatch, view) => {
  const tr = state.tr
    if (!tr.selection.empty) {
    tr.setSelection(TextSelection.near(state.selection.$to))
    }
  tr.setMeta(indicatorPlugin, {add: {id, pos: coords ? view.posAtCoords(coords).pos : tr.selection.from}})
  dispatch(tr)
  return true
}

/**
 * 로딩 인디케이터가 표시된 위치에 업로드된 이미지를 삽입합니다.
 * @type {() => import('prosemirror-state').Command}
 */
export const insertImage = (urls, id) => (state, dispatch, view) => {
  const tr = state.tr
  const pos = findIndicator(view.state, id)
  const imageNodes = urls.map(url =>
    view.state.schema.nodes.paragraph.create(
      null,
      view.state.schema.nodes.image.create({
        src: url,
      }),
    )
  )

  tr.insert(pos, imageNodes).setMeta(indicatorPlugin, {
    remove: {id},
  })

  dispatch(tr)
  return true
}

/**
 * 로딩 인디케이터가 표시된 위치에 업로드된 비디오를 삽입합니다.
 * @type {() => import('prosemirror-state').Command}
 */
export const insertVideo = (videos, id) => (state, dispatch, view) => {
  const tr = state.tr
  const pos = findIndicator(view.state, id)
  const videoNodes = videos.map(({url, poster}) =>
    view.state.schema.nodes.paragraph.create(
      null,
      view.state.schema.nodes.video.create({
        src: url,
        poster
      }),
    )
  )

  tr.insert(pos, videoNodes).setMeta(indicatorPlugin, {
    remove: {id},
  })

  dispatch(tr)
  return true
}

/**
 * 로딩 인디케이터가 표시된 위치에 업로드된 파일을 삽입합니다.
 * @type {() => import('prosemirror-state').Command}
 */
export const insertFile = (urls, id) => (state, dispatch, view) => {
  const tr = state.tr
  const pos = findIndicator(view.state, id)
  // 파일명을 텍스트로 만들고 링크를 걸어줍니다.
  const links = urls.map(url => {
    const tokens = url.split('/')
    const fileName = tokens[tokens.length - 1]
    return view.state.schema.nodes.paragraph.create(
      null,
      view.state.schema.text(
        fileName,
        view.state.schema.marks.link.create({href: url, download: ''}),
      ),
    )
  })

  tr.insert(pos, links).setMeta(indicatorPlugin, {
    remove: {id},
  })

  dispatch(tr)
  return true
}

/**
 * 로딩 인디케이터가 표시된 위치에 업로드 실패 문구를 삽입합니다.
 * @type {() => import('prosemirror-state').Command}
 */
export const insertFailureMessage = (id) => (state, dispatch) => {
  const tr = state.tr

  tr.setMeta(indicatorPlugin, {
    remove: {id},
  })

  dispatch(tr)
  return true
}
