import axios from "axios";

export const axiosClient = (token) => {
  const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_PUBLIC_API_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return instance;
};
