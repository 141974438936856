import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const olDOM = ['ol', {class: MEDISTREAM_SCHEMA_STYLE.nodes.ol}, 0]

/**
 * Ordered List 는 <ol> 태그로 표현됩니다.
 * 한 개 이상의 list_item 을 가질 수 있습니다.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const orderedListNodeSpec = {
  group: 'block',
  content: 'list_item+',
  attrs: {order: {default: 1}},
  toDOM: node => olDOM,
  parseDOM: [
    {
      tag: 'ol',
      /**
       * @param {HTMLElement} dom
       */
      getAttrs: dom => ({
        order: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1,
      }),
    },
  ],
}
