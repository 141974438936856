import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const preDOM = ['pre', {class: MEDISTREAM_SCHEMA_STYLE.nodes.pre}, 0]

/**
 * 코드 블록은 <pre> 태그로 표현됩니다.
 * 0 개 이상의 "텍스트" 를 컨텐츠로 가집니다.
 * content: "text*" 는 Marks, Non-Text Inline 을 내용으로 받지 않겠다는 의미입니다.
 * code: true 는 내부에 <code> 요소가 포함된다는 의미입니다.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 * 
 */
export const codeBlockNodeSpec = {
  content: 'text*',
  marks: '',
  group: 'block',
  code: true,
  defining: true,
  parseDOM: [{tag: 'pre', preserveWhitespace: 'full'}],
  toDOM() {
    return preDOM
  },
}
