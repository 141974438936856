import React, { forwardRef, useEffect, useRef, useState } from "react";
import "prosemirror-view/style/prosemirror.css";
import "prosemirror-tables/style/tables.css";
import "../../submodules/integration-editors/prosemirror/styles/addCheestalk.scss";

import {
  buildEditorState,
  buildEditorView,
  restoreEditorState,
} from "../../submodules/integration-editors/prosemirror";

import colorsMoreden from "../../submodules/integration-editors/prosemirror/styles/colorsMoreden";

import { queryLinkAttrs } from "../../submodules/integration-editors/prosemirror/plugins/link";
import { remoteMenu } from "../../submodules/integration-editors/prosemirror/plugins/remoteMenu";
import { INTEGRATION_EDITOR_MENUBAR_CLASS } from "../../submodules/integration-editors/prosemirror/styles/classNames";
import axios from "axios";
import Compressor from "compressorjs";
import Popup from "../Popup";
import ProsemirrorEditorMenubar from "./ProsemirrorEditorMenubar";
import { axiosClient } from "../../api/axiosClient";
import { cloudFrontFile } from "../../util";

const ProsemirrorEditor = forwardRef(
  ({ SavedContentJson, IsAdmin, setThumbnail }, ref) => {
    const [toggleBtnName, setToggleBtnName] = useState({
      dropDown: false,
      name: "",
    });

    const token = localStorage.getItem("accessToken");
    const [popup, setPopup] = useState(false);
    const [autoSaveToast, setAutoSaveToast] = useState(false);
    const [fontNodeType, setFontNodeType] = useState("");
    const viewHost = useRef(null);

    const autoSave = () => {
      if (!ref.current?.state) return;
      const diffSizeUnit = 100;
      const writingContentLength = ref.current.state.doc.nodeSize;
      const cachedContentLength =
        localStorage.getItem("autosave:pm-article-length:") || "0";
      if (writingContentLength >= diffSizeUnit) {
        if (
          Math.abs(writingContentLength - +cachedContentLength) >= diffSizeUnit
        ) {
          localStorage.setItem(
            "autosave:pm-article-length:",
            writingContentLength
          );
          localStorage.setItem(
            "autosave:pm-article:",
            JSON.stringify(ref.current.state.doc.toJSON())
          );
          setAutoSaveToast(true);
          setTimeout(() => {
            setAutoSaveToast(false);
          }, 3000);
        }
      }
    };

    const cachedContent = localStorage.getItem("autosave:pm-article:");
    useEffect(() => {
      if (!cachedContent) return;
      setPopup(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const detectAutosave = () => {
      if (!cachedContent || !ref.current) return;
      let state = buildEditorState({ injectedPlugins: [] });
      state = restoreEditorState({
        doc: JSON.parse(cachedContent),
        injectedPlugins: [],
      });
      ref.current.updateState(state);
      localStorage.removeItem("autosave:pm-article:");
      localStorage.removeItem("autosave:pm-article-length:");
      setPopup(false);
    };

    useEffect(() => {
      let state = buildEditorState({ injectedPlugins: [] });
      const editorView = buildEditorView({
        ref: viewHost.current,
        editorState: state,
        colorScheme: colorsMoreden,
        onTransaction: (tr) => {
          const newState = ref.current.state.apply(tr);
          ref.current.updateState(newState);
          autoSave();
        },
      });

      ref.current = editorView;

      // 수정 시 이전 문서 불러오기
      if (SavedContentJson) {
        const contentJson = JSON.parse(JSON.stringify(SavedContentJson));
        if (Object.keys(contentJson).length && contentJson.type === "doc") {
          state = restoreEditorState({
            doc: contentJson,
            injectedPlugins: [],
          });
          ref.current.updateState(state);
        }
      }

      return () => {
        ref.current.destroy();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SavedContentJson]);

    const dispatchMetaData = (command) => {
      setToggleBtnName({ dropDown: false, name: "" });
      ref.current.dispatch(ref.current.state.tr.setMeta(remoteMenu, command));
    };

    const editorAttrs = {
      linkInSelection: "",
      fileSizePolicy: {
        gif: 10 * 1024 * 1024, // 모든 유저: 10MB
        staticImage: 20 * 1024 * 1024, // 모든 유저: 20MB
        video: IsAdmin ? 100 * 1024 * 1024 : 20 * 1024 * 1024, // 일반유저: 20MB, 운영자: 100MB
        file: IsAdmin ? "infinite" : 100 * 1024 * 1024, // 일반유저: 100MB, 운영자: 무제한
      },
    };

    const onLinkInputOpen = () => {
      const attrs = queryLinkAttrs()(ref.current.state);
      editorAttrs.linkInSelection = attrs?.href || "";
    };

    const onFontSizeOpen = () => {
      const { $from } = ref.current.state.selection;
      const node = $from.node();

      if (node) {
        const nodeType = node.type.name;
        setFontNodeType(nodeType);
        return;
      }

      setFontNodeType("");
    };

    const mediaTypeGuard = (files, Name) => {
      const isContainingUnacceptedMediaType = Array.from(files).some((file) => {
        if (Name === "image") {
          return !file.type.startsWith("image/");
        }
        if (Name === "video") {
          return !(file.type === "video/mp4");
        }
        return false;
      });
      if (isContainingUnacceptedMediaType) {
        throw new Error("허용되지 않은 형식입니다.");
      }
    };

    const compressImage = (file) => {
      return new Promise((resolve, reject) => {
        return new Compressor(file, {
          maxWidth: 1920,
          maxHeight: 1920,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    };

    const checkUploadingMediaSize = async (file, policies) => {
      const GIF_MAX_SIZE = policies.gif;
      const STATIC_IMAGE_MAX_SIZE = policies.staticImage;
      const VIDEO_MAX_SIZE = policies.video;
      const FILE_MAX_SIZE = policies.file;

      const isGIF = file.type === "image/gif";
      const isVideo = file.type.startsWith("video/");
      const isStaticImage = file.type.startsWith("image/") && !isGIF;
      const isFile =
        !file.type.startsWith("image/") && !file.type.startsWith("video/");

      // GIF 파일인 경우
      if (isGIF && typeof GIF_MAX_SIZE === "number") {
        if (file.size > GIF_MAX_SIZE) {
          throw new Error(
            `GIF 파일은 ${
              GIF_MAX_SIZE / 1024 / 1024
            }MB 이상 업로드할 수 없습니다.`
          );
        }
        return file;
      }
      // 정적 이미지인 경우 압축을 시도합니다.
      if (isStaticImage && typeof STATIC_IMAGE_MAX_SIZE === "number") {
        if (typeof STATIC_IMAGE_MAX_SIZE === "number") {
          if (file.size > STATIC_IMAGE_MAX_SIZE) {
            throw new Error(
              `이미지 파일은 ${
                STATIC_IMAGE_MAX_SIZE / 1024 / 1024
              }MB 이상 업로드할 수 없습니다.`
            );
          }
        }
        return await compressImage(file).catch(() => {
          throw new Error("파일 압축을 실패했습니다. 다시 시도해주세요.");
        });
      }
      // 동영상인 경우
      if (isVideo && typeof VIDEO_MAX_SIZE === "number") {
        if (file.size > VIDEO_MAX_SIZE) {
          throw new Error(
            `동영상 파일은 ${
              VIDEO_MAX_SIZE / 1024 / 1024
            }MB 이상 업로드할 수 없습니다.`
          );
        }
        return file;
      }
      // 기타 파일인 경우
      if (isFile && typeof FILE_MAX_SIZE === "number") {
        if (file.size > FILE_MAX_SIZE) {
          throw new Error(
            `파일은 ${FILE_MAX_SIZE / 1024 / 1024}MB 이상 업로드할 수 없습니다.`
          );
        }
        return file;
      }

      return file;
    };

    const hookBeforeUpload = async (files, Name) => {
      // 첨부된 파일들의 타입을 검사합니다.
      mediaTypeGuard(files, Name);

      // 첨부된 파일들을 압축합니다.
      const compressRequests = Array.from(files).map((file) => {
        return checkUploadingMediaSize(file, editorAttrs.fileSizePolicy);
      });
      const compressedFiles = await Promise.all(compressRequests);
      return compressedFiles;
    };

    const ContentTypeKorean = (Name) => {
      switch (Name) {
        case "image":
          return "이미지";
        case "video":
          return "동영상";
        case "file":
          return "파일";
        default:
          return "";
      }
    };

    const startUploadProcess = async (filesArray, indicatorId, Name) => {
      let files = [];
      // 업로드 훅을 통과시킵니다.
      try {
        files = await hookBeforeUpload(filesArray, Name);
      } catch (error) {
        console.error(error);
        dispatchMetaData({
          type: "upload-failure-message",
          message: error.message,
          id: indicatorId,
        });
      }

      // 훅을 통과하지 못한 경우 업로드를 중단합니다.
      if (!files.length) {
        dispatchMetaData({
          type: "upload-failure-message",
          message: "파일 압축을 실패했습니다. 다시 시도해주세요.",
          id: indicatorId,
        });
        return false;
      }

      // 압축된 미디어의 업로드를 시도합니다.
      try {
        const uploadedFileList = await Promise.all(
          Array.from(filesArray).map(async (file) => {
            const res = await axiosClient(token).post(
              `/article/board/upload-image`,
              {
                filename: file.name,
              }
            );

            const api = res.data;
            const uploadUrl = api?.url;
            const arrayBuffer = await file.arrayBuffer();
            const binaryData = new Uint8Array(arrayBuffer);

            await axios.put(uploadUrl, binaryData, {
              headers: {
                "Content-Type": "application/octet-stream",
              },
            });
            console.log("uploadUrl", uploadUrl);

            return cloudFrontFile(uploadUrl);
          })
        );

        const decodeUploadedFileList = uploadedFileList.map((url) => {
          return decodeURIComponent(url);
        });

        if (Name === "video") {
          const videos = uploadedFileList.map((url) => ({ url, poster: "" }));
          dispatchMetaData({ type: Name, videos, id: indicatorId });
        } else if (Name === "file") {
          dispatchMetaData({
            type: Name,
            urls: decodeUploadedFileList,
            id: indicatorId,
          });
        } else {
          dispatchMetaData({
            type: Name,
            urls: uploadedFileList,
            id: indicatorId,
          });
        }
      } catch (err) {
        console.log(err);
        dispatchMetaData({
          type: "upload-failure-message",
          message: `${ContentTypeKorean(
            Name
          )} 업로드를 실패했습니다. 다시 시도해주세요.`,
          id: indicatorId,
        });
      }
    };

    const uploadFiles = async (e, Name) => {
      // const contentType = 'image';
      // 미디어가 삽입될 위치에 인디케이터를 표시합니다. 객체의 주소를 인디케이터 식별자로 사용합니다.
      const indicatorId = {};
      dispatchMetaData({ type: "before-upload", id: indicatorId });
      startUploadProcess(Array.from(e.target.files), indicatorId, Name);
    };

    const handleAlternativeMediaInsertion = (fileList, coords) => {
      const files = Array.from(fileList);
      const indicatorId = {};
      const acceptedImageTypes = "image/";
      const acceptedVideoTypes = "video/mp4";
      const isImages = files.every((file) =>
        file.type.startsWith(acceptedImageTypes)
      );
      const isVideos = files.every((file) => file.type === acceptedVideoTypes);
      const isFiles = files.every((file) => {
        if (file.type.startsWith(acceptedImageTypes)) return false;
        if (file.type === acceptedVideoTypes) return false;
        return true;
      });

      coords
        ? dispatchMetaData({
            type: "before-drag-upload",
            id: indicatorId,
            coords,
          })
        : dispatchMetaData({ type: "before-upload", id: indicatorId });

      if (isImages) {
        return startUploadProcess(files, indicatorId, "image");
      }
      if (isVideos) {
        return startUploadProcess(files, indicatorId, "video");
      }
      if (isFiles) {
        return startUploadProcess(files, indicatorId, "file");
      }
      return dispatchMetaData({
        type: "upload-failure-message",
        message: "한 종류의 미디어만 첨부해 주세요.",
        id: indicatorId,
      });
    };

    const handleDrop = (e) => {
      e.preventDefault();
      const isFileDrop = e.dataTransfer && e.dataTransfer.files.length;
      if (isFileDrop) {
        const coords = { left: e.clientX, top: e.clientY };
        handleAlternativeMediaInsertion(e.dataTransfer.files, coords);
      }
    };

    const handlePaste = (e) => {
      const isFilePaste = e.clipboardData && e.clipboardData.files.length;
      if (isFilePaste) {
        e.preventDefault();
        handleAlternativeMediaInsertion(e.clipboardData.files);
      }
    };

    const menubarWrap = useRef(null);

    return (
      <div
        className="article-editor-pm"
        ref={viewHost}
        onDrop={handleDrop}
        onPaste={handlePaste}>
        {popup && (
          <Popup
            title="임시 저장"
            isProseMirror
            setConfirm={detectAutosave}
            setPopup={setPopup}>
            <div>
              작성 중이던 글이 있습니다.
              <br />
              이어서 계속 작성하시겠어요?
            </div>
          </Popup>
        )}
        {autoSaveToast && (
          <div className="fixed z-10 px-4 py-5 bg-white rounded-lg left-10 bottom-20 shadow-custom">
            글이 자동 저장되었습니다.
          </div>
        )}
        <div
          className={INTEGRATION_EDITOR_MENUBAR_CLASS + "-wrap"}
          ref={menubarWrap}
          style={{ top: "0" }}>
          <ProsemirrorEditorMenubar
            dispatchMetaData={dispatchMetaData}
            onLinkInputOpen={onLinkInputOpen}
            onFontSizeOpen={onFontSizeOpen}
            fontNodeType={fontNodeType}
            toggleBtnName={toggleBtnName}
            setToggleBtnName={setToggleBtnName}
            uploadFiles={uploadFiles}
            IsAdmin={IsAdmin}
          />
        </div>
      </div>
    );
  }
);

ProsemirrorEditor.displayName = "ProsemirrorEditor";

export default ProsemirrorEditor;
