/**
 * Content Expression 은 노드의 컨텐츠를 정의하는 문법입니다.
 *
 * (+) 는 1 개 이상, (*) 는 0 개 이상, (?) 는 0 또는 1 개를 의미합니다.
 * block 그룹에 속한 노드만 컨텐츠로 갖고 싶다면, content: 'block+' 를 사용합니다.
 * block 그룹에 속한 노드 중 하나만 컨텐츠로 갖고 싶다면, content: 'block' 를 사용합니다.
 * text node 만 컨텐츠로 갖고 싶다면, content: 'text*' 를 사용합니다.
 *
 * 특정 갯수를 지정하고 싶다면, 중괄호({})를 사용해서 갯수를 지정합니다.
 * content: 'block{2,3}' 는 block 노드가 2 개 이상 3 개 이하인 경우에만 컨텐츠로 갖습니다.
 * content: 'block{2,} 는 block 노드가 2 개 이상인 경우에만 컨텐츠로 갖습니다.
 * content: 'block{2} 는 block 노드가 2 개인 경우에만 컨텐츠로 갖습니다.
 *
 * 파이프(|)를 사용해서 '또는' 조건을 지정할 수 있습니다.
 * content: 'paragraph|blockquote' 는 block 노드, inline 노드, text 노드 중 하나만 컨텐츠로 갖습니다.
 *
 * 띄어쓰기( )로 '그리고' 조건을 지정할 수 있습니다.
 * content: 'heading paragraph+' 는 첫번째로 heading 노드, 그 뒤에 paragraph 노드가 1 개 이상인 경우만 허용합니다.
 */

import { Schema, DOMSerializer, DOMParser } from "prosemirror-model";

import { paragraphNodeSpec } from "./paragraph";
import headingNodeSpec from "./heading";
import { tableNodeSet } from "./table";
import { blockquoteNodeSpec } from "./blockquote";
import { docNodeSpec } from "./doc";
import { textNodeSpec } from "./text";
import { imageNodeSpec } from "./image";
import { videoNodeSpec } from "./video";
import { iframeNodeSpec } from "./iframe";
import { listItemNodeSpec } from "./listItem";
import { bulletListNodeSpec } from "./bulletList";
import { orderedListNodeSpec } from "./orderedList";
import { hardBreakNodeSpec } from "./hardBreak";
import { customTemplateNodeSpec } from "./customTemplate";
import { horizontalRuleNodeSpec } from "./horizontalRule";
import { codeBlockNodeSpec } from "./codeBlock";
import { linkMarkSpec } from "./link";
import { textColorMarkSpec } from "./textColor";
import { textBackgroundMarkSpec } from "./textBackground";
import { emMarkSpec } from "./em";
import { delMarkSpec } from "./del";
import { underlineMarkSpec } from "./underline";
import { strongMarkSpec } from "./strong";
import { codeMarkSpec } from "./code";
import { moredenHighlightedMarkSpec } from "./moredenHighlighted";
import { fontSizeMarkSpec } from "./fontSize";
import { pollNodeSpec } from "./poll";

/**
 * Integration 에디터에서 허용되는 DOM Nodes 를 정의합니다.
 *
 * Tag, Attribute 등 이곳에 명세되지 않은 요소는 View 에 적용되지 않습니다.
 *
 * 참고:
 *   Content Expression: https://prosemirror.net/docs/guide/ - #Content Expressions 부분
 *
 * @type {{[tag: string]: import('prosemirror-model').NodeSpec}}}
 */
const nodes = {
  doc: docNodeSpec,

  text: textNodeSpec,

  paragraph: paragraphNodeSpec,

  blockquote: blockquoteNodeSpec,

  horizontal_rule: horizontalRuleNodeSpec,

  heading: headingNodeSpec,

  code_block: codeBlockNodeSpec,

  image: imageNodeSpec,

  video: videoNodeSpec,

  iframe: iframeNodeSpec,

  list_item: listItemNodeSpec,

  bullet_list: bulletListNodeSpec,

  ordered_list: orderedListNodeSpec,

  hard_break: hardBreakNodeSpec,

  custom_template: customTemplateNodeSpec,

  poll: pollNodeSpec,

  hard_break: hardBreakNodeSpec,

  custom_template: customTemplateNodeSpec,

  ...tableNodeSet,
};

/**
 * Medistream 에디터에서 허용되는 Marks 를 정의합니다.
 * Marks 란 Node 를 감싸는 요소를 의미합니다.
 * 강조하고 싶은 구문을 <strong> 태그로 감싸는 것으로 예를 들 수 있습니다.
 *
 * 참고: https://prosemirror.net/examples/schema/
 *
 * @type {{[tag: string]: import('prosemirror-model').MarkSpec}}}
 */
const marks = {
  link: linkMarkSpec,

  text_color: textColorMarkSpec,

  text_background: textBackgroundMarkSpec,

  em: emMarkSpec,

  del: delMarkSpec,

  underline: underlineMarkSpec,

  strong: strongMarkSpec,

  code: codeMarkSpec,

  moreden_highlighted: moredenHighlightedMarkSpec,

  font_size: fontSizeMarkSpec,
};

export const medistreamSchema = new Schema({ nodes, marks });
export const serializer = DOMSerializer.fromSchema(medistreamSchema);
export const domParser = DOMParser.fromSchema(medistreamSchema);
