import { Navigate, Route, Routes } from "react-router-dom";
import Level from "./pages/level/Level";
import LevelDetail from "./pages/level/LevelDetail";
import Banner from "./pages/banner/Banner";
import Member from "./pages/member/Member";
import MemberDetail from "./pages/member/MemberDetail";
import BannerWrite from "./pages/banner/BannerWrite";
import Notice from "./pages/notice/Notice";
import Board from "./pages/board/Board";
import Reported from "./pages/reported/Reported";
import ContentsCate from "./pages/contentsCate/ContentsCate";
import Registration from "./pages/contentsCate/Registration";
import NoticeWrite from "./pages/notice/NoticeWrite";
import Seminar from "./pages/seminar/Seminar";
import Login from "./pages/login/Login";
import Auth from "./pages/auth/Auth";

function App() {
  return (
    <Routes>
      <Route path="/member" element={<Member />} />
      <Route path="/auth/*">
        <Route index element={<Auth />} />
      </Route>
      <Route path="/memberDetail/:id" element={<MemberDetail />} />
      <Route path="/banner/*">
        <Route index element={<Banner />} />
        <Route path="write" element={<BannerWrite />} />
        <Route path="modify/:id" element={<BannerWrite />} />
      </Route>
      <Route path="/notice/*">
        <Route index element={<Notice />} />
        <Route path="write" element={<NoticeWrite />} />
        <Route path="modify/:id" element={<NoticeWrite />} />
      </Route>
      <Route path="/level/*">
        <Route index element={<Level />} />
        <Route path="detail/:id" element={<LevelDetail />} />
      </Route>

      <Route path="/board/*">
        <Route index element={<Board />} />
      </Route>

      <Route path="/reported/*">
        <Route index element={<Reported />} />
      </Route>

      <Route path="/contentscate/*">
        <Route index element={<ContentsCate />} />
        <Route path="registration" element={<Registration />} />
      </Route>
      <Route path="/seminar/*">
        <Route index element={<Seminar />} />
      </Route>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
