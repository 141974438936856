import {MEDISTREAM_SCHEMA_STYLE} from '../styles/classNames'
import {getParagraphNodeAttrs, paragraphNodeSpec} from './paragraph'

const TAG_NAME_TO_LEVEL = {
  H1: 1,
  H2: 2,
  H3: 3,
  H4: 4,
  H5: 5,
  H6: 6,
}

/**
 * Heading 노드는 Paragraph 노드와 유사하기 때문에 Attributes 를 공유합니다.
 * level 값에 따라 h1 ~ h3 으로 표현됩니다. (아래 toDOM 메서드 참조)
 * 
 * 참고한 자료: https://github.com/chanzuckerberg/czi-prosemirror/blob/master/src/HeadingNodeSpec.js
 *
 * @type {import('prosemirror-model').NodeSpec}
 */
const headingNodeSpec = {
  ...paragraphNodeSpec,
  attrs: {
    ...paragraphNodeSpec.attrs,
    level: {default: 1},
  },
  defining: true,
  parseDOM: [
    {tag: 'h1', getAttrs},
    {tag: 'h2', getAttrs},
    {tag: 'h3', getAttrs},
    {tag: 'h4', getAttrs},
    {tag: 'h5', getAttrs},
    {tag: 'h6', getAttrs},
  ],
  toDOM,
}

/**
 *
 * @param {HTMLElement} dom
 * @returns {Object}
 */
function toDOM(node) {
  const {align} = node.attrs
  const level = node.attrs.level || 1
  const attrs = {
    class: MEDISTREAM_SCHEMA_STYLE.nodes.heading,
  }

  let style = ''

  if (align && align !== 'left') {
    style += `text-align: ${align};`
  }

  style && (attrs.style = style)

  return [`h${level}`, attrs, 0]
}

/**
 *
 * @param {Node} node
 * @returns {Array<any>}
 */
function getAttrs(dom) {
  const attrs = getParagraphNodeAttrs(dom)
  const level = TAG_NAME_TO_LEVEL[dom.nodeName.toUpperCase()] || 1
  attrs.level = level
  return attrs
}

export default headingNodeSpec
