import { htmlInsertPlugin } from '../plugins/htmlInsert'

/**
 * HTML 삽입 입력창을 표시합니다.
 *
 * @type {import('prosemirror-state').Command}
 */
export const displayHtmlInsertInput = (state, dispatch, view) => {
  if (dispatch) {
    const tr = state.tr.setMeta(htmlInsertPlugin, 'open')
    dispatch(tr)
    return true
  }
}
