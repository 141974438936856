import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

/**
 *
 * @type {import("prosemirror-model").MarkSpec}
 */
export const fontSizeMarkSpec = {
  attrs: {
    style: {
      default: null,
    },
  },
  inclusive: false,
  toDOM: (node) => [
    "span",
    { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.font_size },
    0,
  ],
  parseDOM: [
    {
      tag: "span",
      style: "font-size",
      getAttrs: dom => {
        const fontSize = dom.style?.fontSize;
        const classList = dom.getAttribute('class');

        if (classList?.includes(MEDISTREAM_SCHEMA_STYLE.marks.font_size) && fontSize) {
          return { style: `font-size: ${fontSize};` };
        }

        return false; 
      },
    },
  ],
};
