import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLevelList } from "../../api/level";
import Header from "../../components/Header/Header";
import ReactPaginate from "react-paginate";
import dayjs from "dayjs";
import { USER_STATUS } from "../../constants";

const Level = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const searchQuery = urlParam.get("searchQuery");
  const [search, setSearch] = useState("");

  const { data: levelList, isLoading: levelListLoading } = useLevelList({
    page,
    searchQuery,
    perPage: 16,
  });

  const onPageClick = (selectedItem) => {
    const selected = selectedItem.selected + 1;
    urlParam.set("page", selected);
    navigate(`/level?${urlParam}`);
  };

  const searchKeyword = () => {
    urlParam.set("searchQuery", search);
    urlParam.set("page", 1);
    navigate(`/level?${urlParam}`);
  };

  if (levelListLoading || !levelList) return <></>;
  return (
    <div>
      <Header />
      <div className="px-4 max-w-[1200px] m-auto mb-20">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="text-[23px] font-semibold text-[#333]">
              등업신청
            </div>
          </div>
          <form>
            <label className="flex items-center space-x-2">
              <input
                type="text"
                className="shadow-custom rounded-full py-1 w-[275px] placeholder:text-sm px-4"
                placeholder="검색어를 입력하세요."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
              <button
                className="dark rounded-full flex items-center justify-center"
                style={{
                  width: "25px",
                  height: "35px",
                  minWidth: "0",
                  minHeight: "0",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  searchKeyword();
                }}
              >
                <i className="ii-search text-[#fff] text-base"></i>
              </button>
            </label>
          </form>
        </div>

        <div className="table">
          <div
            className="row header rounded-xl"
            style={{ background: "#F4F6F8", padding: "15px 0" }}
          >
            <div className="w-[15%] justify-center text-[#637381]">ID</div>
            <div className="w-[12%] text-[#637381]">신청유형</div>
            <div className="w-[15%] text-[#637381]">연락처</div>
            <div className="w-[10%] text-[#637381]">이름</div>
            <div className="w-[15%] text-[#637381]">닉네임</div>
            <div className="w-[12%] text-[#637381]">추천인</div>
            <div className="w-[18%] text-[#637381]">가입일</div>
            <div className="w-[12%] text-[#637381]">상태</div>
            <div className="w-[10%] text-[#637381] justify-center">승인</div>
          </div>
          {levelList.userLicenses?.map((license) => (
            <div key={license?.id}>
              <div
                className="row"
                style={{ padding: "10px 0", borderBottom: "none" }}
              >
                <div className="w-[15%] justify-center">
                  {license?.user?.username}
                </div>
                <div className="w-[12%] text-main">{license?.userType}</div>
                <div className="w-[15%]">{license?.user?.phoneNumber}</div>
                <div className="w-[10%]">{license?.user?.fullname}</div>
                <div className="w-[15%] truncate">
                  {license?.user?.nickname}
                </div>
                <div className="w-[12%]">
                  {license?.user?.recommendUser?.username}
                </div>
                <div className="w-[18%]">
                  {license.createdAt
                    ? dayjs(license.createdAt).format("YYYY-MM-DD hh:mm")
                    : ""}
                </div>
                <div className="w-[12%]">
                  {
                    USER_STATUS.find(
                      (status) => status.value === license?.approved
                    )?.label
                  }
                </div>
                <div className="justify-center w-[10%]">
                  <Link to={`/level/detail/${license?.id}`} className="ml-3">
                    <button className="cursor-pointer">상태변경</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          pageCount={levelList.totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageClassName="page"
          activeClassName="active"
          onPageChange={onPageClick}
          forcePage={levelList.currentPage - 1}
          className="pagination flex items-center justify-center space-x-2 mt-14"
        ></ReactPaginate>
      </div>
    </div>
  );
};

export default Level;
