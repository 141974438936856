import { SwrReq } from "../util";
import { SwrRes } from "../util";

export const useBannerList = (query) => {
  const { data, error } = SwrReq(`/admin/banners`, query);
  return SwrRes(data, error);
};

export const useBannerDetail = (id, query) => {
  const { data, error } = SwrReq(`/admin/banners/${id}`, query);
  return SwrRes(data, error);
};

export const useBannerLocation = () => {
  const { data, error } = SwrReq(`/admin/banners/locations`);
  return SwrRes(data, error);
};
