import {tableNodes} from '@medistream/prosemirror-tables'
import {MEDISTREAM_SCHEMA_STYLE} from '../styles/classNames'

/**
 * prosemirror-tables 의 table 노드 스펙을 변경합니다.
 * 
 * 참고: https://github.com/ProseMirror/prosemirror-tables/blob/8ec1e96ae994c1585b07b1ca9dc3292f63e868e0/src/schema.ts#L133-L142
 *
 * @param {() => import('@medistream/prosemirror-tables').TableNodes} tableNodes
 */
const customizeTableNodeSpec = tableNodes => {
  const tableNodeSet = tableNodes({
    tableGroup: 'block',
    cellContent: 'block+',
    cellAttributes: {
      class: {
        default: MEDISTREAM_SCHEMA_STYLE.nodes.tableCell,
        setDOMAttr: (value, attrs) => {
          if (value) {
            attrs.class = value
          }
        },
      },
      background: {
        default: null,
        getFromDOM(dom) {
          if (dom.style.backgroundColor.startsWith('var')) {
            return dom.style.backgroundColor || null
          }
        },
        setDOMAttr(value, attrs) {
          if (value)
            attrs.style = (attrs.style || '') + `background-color: ${value};`
        },
      },
      style: {
        default: null,
        setDOMAttr: (value, attrs) => {
          if (attrs['data-colwidth']) {
            attrs.style =
              (attrs.style || '') + `width: ${attrs['data-colwidth']}px;`
          }
        },
      },
    },
  })

  tableNodeSet.table.attrs = {
    ...tableNodeSet.table.attrs,
    style: {
      default: null,
    },
    class: {
      default: MEDISTREAM_SCHEMA_STYLE.nodes.table,
    }
  }

  tableNodeSet.table.parseDOM = [
    {
      tag: 'table',
      getAttrs(dom) {
        const classNames = [MEDISTREAM_SCHEMA_STYLE.nodes.table]
        if (dom.classList.contains('transparent')) {
          classNames.push('transparent')
        }
        return {
          class: classNames.join(' '),
          style: dom.getAttribute('style') || ''
        }
      }
    }
  ]

  return tableNodeSet
}

export const tableNodeSet = customizeTableNodeSpec(tableNodes)
