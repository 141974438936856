import dayjs from "dayjs";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { mutate } from "swr";
import { axiosClient } from "../../api/axiosClient";
import { useBoardList } from "../../api/board";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup";
import { cls } from "../../util";

const Reported = () => {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const searchQuery = urlParam.get("searchQuery");
  const boardType = urlParam.get("boardType") || "board";
  const [search, setSearch] = useState("");
  const [popupState, setPopupState] = useState(false);
  const isComment =
    boardType === "board-comments" || boardType === "qna-comments";

  const { data: boardList, isLoading: boardListLoading } = useBoardList(
    boardType,
    {
      page,
      searchQuery,
      orderBy: "reportCount",
      isNotice: false,
      perPage: 16,
    }
  );

  const onPageClick = (selectedItem) => {
    const selected = selectedItem.selected + 1;
    urlParam.set("page", selected);
    urlParam.set("orderBy", "reportCount"); // TODO: api backend 에서도 쿼리 변경할 필요 있음
    navigate(`/reported?${urlParam}`);
  };

  const searchKeyword = () => {
    urlParam.set("searchQuery", search);
    urlParam.set("page", 1);
    urlParam.set("orderBy", "reportCount"); // TODO: api backend 에서도 쿼리 변경할 필요 있음
    navigate(`/reported?${urlParam}`);
  };

  const report = (id, isReport) => {
    axiosClient(token)
      .patch(`/admin/${boardType === "board" ? "boards" : boardType}/${id}`, {
        blind: isReport,
      })
      .then(() => {
        alert("변경되었습니다.");
        setPopupState(false);
        mutate({
          url: `/admin/${boardType === "board" ? "boards" : boardType}`,
          page,
          searchQuery,
          isNotice: false,
          perPage: 16,
        });
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  const reject = (id) => {
    axiosClient(token)
      .patch(`/admin/${boardType === "board" ? "boards" : boardType}/${id}`, {
        blind: false,
        reportCount: 0,
      })
      .then(() => {
        alert("변경되었습니다.");
        setPopupState(false);
        mutate({
          url: `/admin/${boardType === "board" ? "boards" : boardType}`,
          page,
          searchQuery,
          isNotice: false,
          perPage: 16,
        });
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  if (!boardList || boardListLoading) return <></>;
  return (
    <div>
      {popupState && (
        <Popup
          text={`블라인드 ${popupState.text} 하시겠습니까?`}
          setPopup={setPopupState}
          fetch={popupState.fetch}
        />
      )}
      <Header />
      <div className="px-4 max-w-[1200px] m-auto pb-20">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <button
              className={cls(boardType === "board" ? "main" : "")}
              onClick={() => navigate("/reported")}>
              게시판-게시글
            </button>
            <button
              className={cls(boardType === "board-comments" ? "main" : "")}
              onClick={() => navigate("/reported?boardType=board-comments")}>
              게시판-댓글
            </button>
            <button
              className={cls(boardType === "qna" ? "main" : "")}
              onClick={() => navigate("/reported?boardType=qna")}>
              Q&A-게시글
            </button>
            <button
              className={cls(boardType === "qna-comments" ? "main" : "")}
              onClick={() => navigate("/reported?boardType=qna-comments")}>
              Q&A-댓글
            </button>
          </div>
          <form>
            <label className="flex items-center space-x-2">
              <input
                type="text"
                className="shadow-custom rounded-full py-1 w-[275px] placeholder:text-sm px-4"
                placeholder="검색어를 입력하세요."
                value={search}
                onChange={(e) => setSearch(e.target.value)}></input>
              <button
                className="flex items-center justify-center rounded-full dark"
                style={{
                  width: "25px",
                  height: "35px",
                  minWidth: "0",
                  minHeight: "0",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  searchKeyword();
                }}>
                <i className="ii-search text-[#fff] text-base"></i>
              </button>
            </label>
          </form>
        </div>

        <div className="table">
          <div
            className="row header rounded-xl"
            style={{ background: "#F4F6F8", padding: "15px 0" }}>
            {isComment ? (
              <>
                <div className="w-[20%] text-[#637381] ml-4">게시글 제목</div>
                <div className="w-[25%] text-[#637381] ml-4">댓글내용</div>
              </>
            ) : (
              <div className="w-[45%] text-[#637381] ml-4">제목</div>
            )}
            <div className="w-[10%] text-[#637381] ml-5">닉네임</div>
            <div className="w-[15%] text-[#637381]">등록일</div>
            <div className="w-[15%] text-[#637381] justify-center">신고수</div>
            <div className="w-[15%] text-[#637381] justify-center">관리</div>
          </div>
          {(isComment ? boardList.commentList : boardList.articleList)?.map(
            (article, idx) => (
              <>
                <div
                  className="row"
                  key={idx}
                  style={{ padding: "10px 0", borderBottom: "none" }}>
                  {isComment ? (
                    <>
                      <div className="w-[20%] truncate ml-5">
                        {article?.article?.title}
                      </div>
                      <div className="w-[25%] truncate ml-5">
                        {article?.content}
                      </div>
                    </>
                  ) : (
                    <div className="w-[45%] truncate ml-5">
                      {article?.title}
                    </div>
                  )}
                  <div className="w-[12%] ml-5">{article?.user?.nickname}</div>
                  <div className="w-[15%]">
                    {article.createdAt
                      ? dayjs(article.createdAt).format("YYYY-MM-DD hh:mm")
                      : ""}
                  </div>
                  <div className="w-[15%] justify-center">
                    {article?.reportCount}
                  </div>
                  <div className="w-[15%] justify-center">
                    {article?.blind ? (
                      <button
                        className="text-blue-400 border-blue-400"
                        onClick={() =>
                          setPopupState({
                            text: "해제",
                            fetch: () => report(article?.id, false),
                          })
                        }>
                        해제
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          setPopupState({
                            text: "설정",
                            fetch: () => report(article?.id, true),
                          })
                        }>
                        설정
                      </button>
                    )}
                    <button
                      className="white"
                      onClick={() =>
                        setPopupState({
                          text: "거절",
                          fetch: () => reject(article?.id),
                        })
                      }>
                      거절
                    </button>
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          pageCount={boardList.totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageClassName="page"
          activeClassName="active"
          onPageChange={onPageClick}
          forcePage={boardList.currentPage - 1}
          className="flex items-center justify-center space-x-2 pagination mt-14"></ReactPaginate>
      </div>
    </div>
  );
};

export default Reported;
