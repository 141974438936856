import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'

import { medistreamSchema } from './schemas'
import { builtInPlugins } from './plugins'
import { INTEGRATION_EDITOR_CLASS } from './styles/classNames'
import { imageNodeView } from './nodeViews/image'
import { iframeNodeView } from './nodeViews/iframe'
import { hrNodeView } from './nodeViews/hr'
import { customTemplateNodeView } from './nodeViews/customTemplate'
import { pollNodeView } from './nodeViews/poll'
import { handlePMClick, handlePMMouseup, handlePMKeyDown, handlePMPaste } from './utils/prosemirror'

/**
 * 에디터를 처음 시작할 때 사용합니다.
 * 
 * 주입된 Editor State 를 기반으로 타겟 DOM 요소에 에디터를 구현합니다.
 * 
 * @param {{
 *  ref: HTMLElement
 *  editorState: import('prosemirror-state').EditorState
 *  colorScheme: { [colorName: string]: [string, string][] }
 *  onTransaction: undefined | (tr: import('prosemirror-state').Transaction) => void
 * }}
 */
export const buildEditorView = ({ ref, editorState, colorScheme, onTransaction }) => {
  /**
   * @type {import('prosemirror-view').DirectEditorProps}
   */
  const directEditorProps = {
    state: editorState,
    attributes: {
      class: INTEGRATION_EDITOR_CLASS
    },
    nodeViews: {
      image: imageNodeView,
      iframe: iframeNodeView,
      horizontal_rule: hrNodeView,
      custom_template: customTemplateNodeView,
      poll: pollNodeView
    },
    handleDOMEvents: {
      mouseup: handlePMMouseup,
    },
    handleClick: handlePMClick,
    handleKeyDown: handlePMKeyDown,
    handlePaste: handlePMPaste,
    // 각 도메인의 컬러 스키마를 EditorProps 로 전달합니다.
    // 참고한 자료: https://discuss.prosemirror.net/t/using-plugin-as-a-store/5727/3
    colorScheme,
  }

  if (onTransaction) {
    directEditorProps.dispatchTransaction = onTransaction
  }

  return new EditorView(ref, directEditorProps)
}

/**
 * 에디터를 처음 시작할 때 사용합니다.
 * 
 * 미리 정의한 에디터 스키마와 사용할 플러그인들을 기반으로 에디터의 Initial State 를 생성합니다.
 * 
 * @param {{
 *  injectedPlugins: import('prosemirror-state').Plugin[]
 * }}
 */
export const buildEditorState = ({ injectedPlugins = [] }) => {
  return EditorState.create({
    schema: medistreamSchema,
    plugins: [...injectedPlugins, ...builtInPlugins],
  })
}

/**
 * 과거에 저장한 글을 이어쓸 때 사용합니다.
 * 
 * 주입된 Editor State 를 기반으로 에디터의 Initial State 를 생성합니다.
 * 
 * @param {{
 *  doc: any,
 *  injectedPlugins: import('prosemirror-state').Plugin[]
 * }}
 */
export const restoreEditorState = ({
  doc = {},
  injectedPlugins = [],
}) => {
  return EditorState.create({
    doc: medistreamSchema.nodeFromJSON(doc),
    schema: medistreamSchema,
    plugins: [...injectedPlugins, ...builtInPlugins]
  })
}
