import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

const moredenHighlightedDOM = [
  "bdi",
  { class: MEDISTREAM_SCHEMA_STYLE.marks.bdi },
  0,
];

/**
 * 모어덴 보라색 하이라이트는 <bdi> 태그로 표현됩니다.
 *
 * @type {import("prosemirror-model").MarkSpec}
 */

export const moredenHighlightedMarkSpec = {
  toDOM() {
    return moredenHighlightedDOM;
  },
  parseDOM: [{ tag: "bdi" }],
};
