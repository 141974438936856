import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const strongDOM = ['strong', {class: MEDISTREAM_SCHEMA_STYLE.marks.strong}, 0]

/**
 * 강조 구문은 <strong> 태그로 표현됩니다.
 * 
 * 외부 컨텐츠를 에디터로 붙여넣을 때는 <b>, 'font-weight: bold' 모두 <strong> 태그로 변환합니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const strongMarkSpec = {
  toDOM() {
    return strongDOM
  },
  parseDOM: [
    {tag: 'strong'},
    // This works around a Google Docs misbehavior where
    // pasted content will be inexplicably wrapped in `<b>`
    // tags with a font-weight normal.
    {tag: 'b', getAttrs: node => node.style.fontWeight != 'normal' && null},
    {style: 'font-weight=400', clearMark: m => m.type.name == 'strong'},
    {
      style: 'font-weight',
      getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
    },
  ],
}
