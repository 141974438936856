import { TextSelection } from "prosemirror-state";

/**
 * 다음 블록에 Horizontal Rule 노드를 삽입합니다.
 *
 * @type {import('prosemirror-state').Command}
 */
export function addHorizontalRuleNextLine(state, dispatch, view) {
  if (!state.selection.empty) return false;

  const hrNode = state.schema.nodes.horizontal_rule.create();
  const paragraphNode = state.schema.nodes.paragraph.create();
  const selectionHead = state.selection.$head;
  const isInsideBlockquote =
    selectionHead.node(selectionHead.depth - 1).type.name ===
    state.schema.nodes.blockquote.name;

  // 인용문 안에 있을 경우 인용문 밖에 가로줄을 삽입하고, 그 밑에 본문 블록을 삽입합니다.
  if (isInsideBlockquote) {
    const insertAt = selectionHead.after(selectionHead.depth - 1);
    const tr = state.tr
      .insert(insertAt, hrNode)
      .insert(insertAt + 1, paragraphNode);
    const tr2 = tr.setSelection(TextSelection.create(tr.doc, insertAt + 2));

    dispatch(tr2);
    return true;
  }

  // 다음 블럭에 가로줄, 그 다음 블럭에 본문 블록을 삽입합니다.
  const endPosOfCurNode = selectionHead.end(selectionHead.depth);
  const tr = state.tr
    .insert(endPosOfCurNode + 1, hrNode)
    .insert(endPosOfCurNode + 2, paragraphNode);
  const tr2 = tr.setSelection(
    TextSelection.create(tr.doc, endPosOfCurNode + 3)
  );

  dispatch(tr2);
  return true;
}
