import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const codeDOM = ['code', {class: MEDISTREAM_SCHEMA_STYLE.marks.code}, 0]

/**
 * 코드는 <code> 태그로 표현됩니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const codeMarkSpec = {
  toDOM() {
    return codeDOM
  },
  parseDOM: [{tag: 'code'}],
}
