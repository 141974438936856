import GameTable from "../../components/game/GameTable";
import { useParams } from "react-router-dom";
import { postGameLog, useUserGameResource } from "../../api/game";
import { useState } from "react";
import Popup from "../../components/Popup";
import GameLog from "../../components/game/GameLog";

const Game = () => {
  const token = localStorage.getItem("accessToken");
  const { id } = useParams();
  const [passCount, setPassCount] = useState(0);
  const [popup, setPopup] = useState({
    isPopupOn: false,
    title: "",
  });

  const { data: userGameResource } = useUserGameResource(id);

  const givePass = () => {
    const data = {
      userId: id,
      sourceType: "ADMIN",
      passCount: Number(passCount),
    };
    postGameLog(token, data)
      .then((res) => {
        alert(`도전권 부여 성공`);
        setPopup({ isPopupOn: false, title: "" });
        setPassCount(0);
      })
      .catch((e) => {
        alert(e.response.data.message);
      });
  };

  if (!userGameResource) return <></>;

  return (
    <>
      {popup.isPopupOn && (
        <Popup text={popup.title} setPopup={setPopup} fetch={givePass} />
      )}
      <section>
        <div className="px-3 py-1 mt-8 text-white bg-main">
          치즈게임 도전권/응모권
        </div>
        <ul className="flex border-b-[1px] border-[#ccc]">
          <GameTable title="보유 도전권" content={userGameResource.passCount} />
          <GameTable title="누적 응모권" content={userGameResource.coinCount} />
        </ul>
        <ul className="flex border-b-[1px] border-[#ccc]">
          <div className="w-full flex border-b-[1px] border-[#ccc]">
            <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
              <div className="px-4 memberInfo-cell">도전권 부여하기</div>
            </div>
            <div className="flex items-center w-3/4">
              <input
                type="number"
                placeholder="부여할 도전권 수량"
                onChange={(e) => setPassCount(e.target.value)}
                className="px-2 py-1 mx-10 rounded-sm shadow-custom"></input>
              <button
                className="border-[1px] border-[#ccc] px-5 rounded-md"
                onClick={() => {
                  if (passCount > 0)
                    setPopup({
                      isPopupOn: true,
                      title: `유저에게 ${passCount}개의 도전권을 부여하시겠습니까?`,
                    });
                  else alert("도전권 수량을 입력해주세요.");
                }}>
                도전권 부여
              </button>
            </div>
          </div>
        </ul>
      </section>

      <section>
        <div className="px-3 py-1 mt-8 text-white bg-main">치즈게임 로그</div>
        <GameLog />
      </section>
    </>
  );
};

export default Game;
