import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

/**
 * 글자의 색상은 <span> 태그의 color 속성으로 표현됩니다.
 * 
 * 사내 콘텐츠 생산 직원분들은 외부 에디터에서 작성한 글을 붙여넣는 일이 잦습니다.
 * 이 때, font-color 속성값이 따라오는 경우가 있습니다.
 * 다크모드 대응에 어려움을 겪고 있어, 바탕색과 비슷한 색상이 따라오는 경우 제거하는 로직을 추가했습니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const textColorMarkSpec = {
  attrs: {
    style: {
      default: null
    },
  },
  inclusive: false,
  toDOM: node => ['span', { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.text_color }, 0],
  parseDOM: [
    {
      tag: 'span',
      style: 'color',
      getAttrs: dom => {
        if (dom.style?.color) {
          /**
           * MARK: Google Docs 에서 작성한 글을 붙여넣을 때 간헐적으로 따라오는 스타일을 제거합니다.
           *       예) <span style="color: black;"></span>
           */
          if (dom.style.color === 'rgb(255, 255, 255)') return false
          if (dom.style.color === 'white') return false
          if (dom.style.color === '#ffffff') return false
          if (dom.style.color === '#fff') return false
          if (dom.style.color === 'rgb(0, 0, 0)') return false
          if (dom.style.color === 'black') return false
          if (dom.style.color === '#000000') return false
          if (dom.style.color === '#000') return false
          if (dom.style.color === 'rgb(29, 28, 29)') return false
          return {
            style: `color: ${dom.style.color};`
          }
        }
        return false
      },
      consuming: false
    },
  ],
}
