import { _findSelectedNodeOfType } from "../utils/prosemirror";
import { Plugin } from "prosemirror-state";

import { updateLink } from "./link";
import { prependHTTPS } from "../utils/regex";

/**
 * 참고: https://prosemirror.net/examples/tooltip/
 *      https://discuss.prosemirror.net/t/edit-and-update-link/3785
 */
class LinkTooltipPluginView {
  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   */
  constructor(view) {
    this.view = view;
    this.CSS = "link-input-dialog";

    this.tooltip = document.createElement("div");
    this.tooltip.classList.add(this.CSS);

    this.form = document.createElement("form");
    this.form.classList.add(`${this.CSS}__form`);
    this.form.addEventListener("submit", this._onSubmit.bind(this));

    this.input = document.createElement("input");
    this.input.classList.add(`${this.CSS}__input`);
    this.input.type = "text";
    this.input.placeholder = "https://www.example.com";

    this.button = document.createElement("button");
    this.button.classList.add(`${this.CSS}__button`);
    this.button.type = "submit";
    this.button.textContent = "적용";

    this.form.appendChild(this.input);
    this.form.appendChild(this.button);
    this.tooltip.appendChild(this.form);
    view.dom.parentNode.appendChild(this.tooltip);

    document.addEventListener("mousedown", this._hideTooltip.bind(this));
  }

  /**
   *
   * @param {SubmitEvent} event
   */
  _onSubmit(event) {
    event.preventDefault();

    const url = prependHTTPS(this.input.value.trim());

    updateLink(url)(this.view.state, this.view.dispatch);
    this.tooltip.style.display = "none";
  }

  _showTooltip() {
    const state = this.view.state;
    const view = this.view;
    const imageNode = _findSelectedNodeOfType(
      this.view.state.schema.nodes.image
    )(state.selection);
    let href = "";

    if (imageNode && imageNode.node.marks[0]?.type.name === "link") {
      const linkMark = imageNode.node.marks[0];
      href = linkMark.attrs.href || "";
    } else {
      const linkMark = state.selection.$anchor
        .marks()
        .find(
          (mark) => mark.type.name === this.view.state.schema.marks.link.name
        );
      href = linkMark?.attrs.href || "";
    }

    this.tooltip.style.display = "flex";

    const { from, to } = state.selection;
    // These are in screen coordinates
    const start = view.coordsAtPos(from);
    const end = view.coordsAtPos(to);
    // The box in which the tooltip is positioned, to use as base
    const box = this.tooltip.offsetParent.getBoundingClientRect();
    // Find a center-ish x position from the selection endpoints (when
    // crossing lines, end may be more to the left)
    const left = Math.max((start.left + end.left) / 2, start.left + 3);

    this.tooltip.style.left = left - box.left + "px";
    this.tooltip.style.bottom = box.bottom - start.top + "px";
    this.input.value = href;
  }

  /**
   *
   * @param {MouseEvent} event
   */
  _hideTooltip(event) {
    event.stopPropagation();
    if (event.target.closest(`.${this.CSS}`)) return;
    this.tooltip.style.display = "none";
  }

  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   * @param {import('prosemirror-state').EditorState} lastState
   * @returns
   */
  update(view, lastState) {
    if (linkTooltip.getState(view.state) === "open") {
      this._showTooltip();
      return;
    }

    // Don't do anything if the document/selection didn't change
    if (
      lastState &&
      lastState.doc.eq(view.state.doc) &&
      lastState.selection.eq(view.state.selection)
    )
      return;

    const state = view.state;
    const imageNode = _findSelectedNodeOfType(view.state.schema.nodes.image)(
      state.selection
    );

    if (imageNode) {
      if (imageNode.node.marks[0]?.type.name === "link") {
        this._showTooltip();
        return;
      }
    }

    const isSelectionFromLink = view.state.schema.marks.link.isInSet(
      state.selection.$anchor.marks()
    );

    /**
     * Selection 의 시작점이 링크인 경우에만 툴팁을 보여줍니다.
     */
    if (isSelectionFromLink) {
      this._showTooltip();
      return;
    }

    this.tooltip.style.display = "none";
    this.input.value = "";
  }

  destroy() {
    this.tooltip.remove();
    document.removeEventListener("mousedown", this._hideTooltip);
  }
}

export const linkTooltip = new Plugin({
  state: {
    init() {
      return undefined; // or 'open'
    },
    apply(tr) {
      const meta = tr.getMeta(this);
      if (meta) {
        return meta;
      }
    },
  },
  view(editorView) {
    return new LinkTooltipPluginView(editorView);
  },
});
