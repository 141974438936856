import { NodeSelection, Plugin } from 'prosemirror-state'
import { GapCursor } from 'prosemirror-gapcursor'

import { CellSelection, addColumnAfter, addColumnBefore, addRowAfter, addRowBefore, deleteColumn, deleteRow, mergeCells, setCellAttr, splitCell, toggleHeaderColumn, toggleHeaderRow } from '@medistream/prosemirror-tables'
import { insertTable, toggleCellBorder } from '../commands/table'
import { MEDISTREAM_SCHEMA_STYLE } from '../styles/classNames'

class CellTooltipPluginView {
  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   */
  constructor(view) {
    this.view = view;
    this.CSS = MEDISTREAM_SCHEMA_STYLE.etc.cellMenu;

    this.tooltip = document.createElement("div");
    this.tooltip.classList.add(this.CSS);

    this.buttonContainer = document.createElement("div");
    this.buttonContainer.classList.add(`${this.CSS}__buttonContainer`);

    this.button = document.createElement("button");
    this.button.type = "submit";
    this.button.classList.add("menuButton");
    this.button.innerHTML =
      '<svg width="16" height="13" viewBox="5 6 16 13" role="presentation"><path d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z" fill="currentColor" fill-rule="evenodd"></path></svg>';

    this.buttonContainer.appendChild(this.button);

    this.tableMenu = document.createElement("ul");
    this.tableMenu.classList.add(`${this.CSS}__tableMenu`);
    this.tableMenu.style.display = "none";

    this.addTable = document.createElement("li");
    this.addTable.classList.add(`${this.CSS}__item`);
    this.addTable.textContent = "테이블 추가";
    this.addTable.addEventListener("click", (event) => {
      execMenuFunc(event, () => insertTable(view.state, view.dispatch));
    });

    this.cellBgColor = document.createElement("li");
    this.cellBgColor.classList.add(`${this.CSS}__item`);
    this.cellBgColor.textContent = "셀 배경색";
    this.cellBgColor.addEventListener(
      "mouseover",
      this._showPalette.bind(this)
    );
    this.cellBgColor.addEventListener("mouseout", this._hidePalette.bind(this));

    this.palette = document.createElement("div");
    this.palette.classList.add(`${this.CSS}__palette`);

    const colors = this.view.someProp("colorScheme");

    const cellColors = [
      ...colors.tableCellBackgroundColors,
      ["reset-style", "reset-style"],
    ];

    for (let i = 0; i < cellColors.length; i++) {
      this.figure = document.createElement("figure");
      if (i === cellColors.length - 1) {
        this.figure.classList.add(`${this.CSS}__palette-resetStyle`);
      }
      this.figure.classList.add(`${this.CSS}__palette-cellBgColor`);
      this.figure.dataset.color = cellColors[i][1];
      this.figure.style.backgroundColor = cellColors[i][1];
      this.figure.addEventListener("click", (event) => {
        event.stopPropagation();
        this._selectColor(event);
        this.view.dom.focus();
      });
      this.palette.appendChild(this.figure);
    }

    this.cellBgColor.appendChild(this.palette);

    this.border = document.createElement("li");
    this.border.classList.add(`${this.CSS}__item`);
    this.border.innerHTML = "테두리 켜기/끄기<code>Ctrl+Alt+ㅁ</code>";
    this.border.addEventListener("click", (event) => {
      execMenuFunc(event, () =>
        toggleCellBorder(view.state, view.dispatch, view)
      );
    });

    this.mergeCells = document.createElement("li");
    this.mergeCells.classList.add(`${this.CSS}__item`);
    this.mergeCells.textContent = "셀 합치기";
    this.mergeCells.addEventListener("click", (event) => {
      execMenuFunc(event, () => mergeCells(view.state, view.dispatch));
    });

    this.splitCell = document.createElement("li");
    this.splitCell.classList.add(`${this.CSS}__item`);
    this.splitCell.textContent = "셀 나누기";
    this.splitCell.addEventListener("click", (event) => {
      execMenuFunc(event, () => splitCell(view.state, view.dispatch));
    });

    this.headerRow = document.createElement("li");
    this.headerRow.classList.add(`${this.CSS}__item`);
    this.headerRow.textContent = "헤더 행으로 전환";
    this.headerRow.addEventListener("click", (event) => {
      execMenuFunc(event, () => toggleHeaderRow(view.state, view.dispatch));
    });

    this.headerColumn = document.createElement("li");
    this.headerColumn.classList.add(`${this.CSS}__item`);
    this.headerColumn.textContent = "헤더 열로 전환";
    this.headerColumn.addEventListener("click", (event) => {
      execMenuFunc(event, () => toggleHeaderColumn(view.state, view.dispatch));
    });

    this.removeRow = document.createElement("li");
    this.removeRow.classList.add(`${this.CSS}__item`);
    this.removeRow.innerHTML = "행 삭제<code>Ctrl+Alt+ㅡ</code>";
    this.removeRow.addEventListener("click", (event) => {
      execMenuFunc(event, () => deleteRow(view.state, view.dispatch));
    });

    this.removeColumn = document.createElement("li");
    this.removeColumn.classList.add(`${this.CSS}__item`);
    this.removeColumn.innerHTML = "열 삭제<code>Ctrl+Alt+ㅣ</code>";
    this.removeColumn.addEventListener("click", (event) => {
      execMenuFunc(event, () => deleteColumn(view.state, view.dispatch));
    });

    this.addRowAbove = document.createElement("li");
    this.addRowAbove.classList.add(`${this.CSS}__item`);
    this.addRowAbove.innerHTML = "위에 행 추가<code>Ctrl+Alt+↑</code>";
    this.addRowAbove.addEventListener("click", (event) => {
      execMenuFunc(event, () => addRowBefore(view.state, view.dispatch));
    });

    this.addRowBelow = document.createElement("li");
    this.addRowBelow.classList.add(`${this.CSS}__item`);
    this.addRowBelow.innerHTML = "아래에 행 추가<code>Ctrl+Alt+↓</code>";
    this.addRowBelow.addEventListener("click", (event) => {
      execMenuFunc(event, () => addRowAfter(view.state, view.dispatch));
    });

    this.addColumnRight = document.createElement("li");
    this.addColumnRight.classList.add(`${this.CSS}__item`);
    this.addColumnRight.innerHTML = "우측에 열 추가<code>Ctrl+Alt+→</code>";
    this.addColumnRight.addEventListener("click", (event) => {
      execMenuFunc(event, () => addColumnAfter(view.state, view.dispatch));
    });

    this.addColumnLeft = document.createElement("li");
    this.addColumnLeft.classList.add(`${this.CSS}__item`);
    this.addColumnLeft.innerHTML = "좌측에 열 추가<code>Ctrl+Alt+←</code>";
    this.addColumnLeft.addEventListener("click", (event) => {
      execMenuFunc(event, () => addColumnBefore(view.state, view.dispatch));
    });

    const execMenuFunc = (event, menuFunc) => {
      event.stopPropagation();
      menuFunc();
      this.view.dom.focus();
      this.tableMenu.style.display = "none";
      this.tooltip.style.display = "none";
    };

    this.tableMenu.appendChild(this.addTable);
    this.tableMenu.appendChild(this.cellBgColor);
    this.tableMenu.appendChild(this.border);
    this.tableMenu.appendChild(this.mergeCells);
    this.tableMenu.appendChild(this.splitCell);
    this.tableMenu.appendChild(this.headerRow);
    this.tableMenu.appendChild(this.headerColumn);
    this.tableMenu.appendChild(this.removeRow);
    this.tableMenu.appendChild(this.removeColumn);
    this.tableMenu.appendChild(this.addRowAbove);
    this.tableMenu.appendChild(this.addRowBelow);
    this.tableMenu.appendChild(this.addColumnRight);
    this.tableMenu.appendChild(this.addColumnLeft);

    this.tooltip.appendChild(this.buttonContainer);
    this.tooltip.appendChild(this.tableMenu);

    view.dom.parentNode.appendChild(this.tooltip);

    this.tooltip.style.display = "none";

    this.button.addEventListener("click", (event) => {
      event.stopPropagation();
      this._onSubmit(event);
    });

    // document.addEventListener('mousedown', this._hideTooltip.bind(this))
  }

  /**
   *
   * @param {SubmitEvent} event
   */
  _onSubmit(event) {
    event.preventDefault()

    this.tableMenu.style.display = "block"
  }

  _showTooltip() {
    const view = this.view
    const state = view.state
    const selection = state.selection

    this.tableMenu.style.display = 'none'

    if (selection instanceof NodeSelection || selection instanceof GapCursor) {
      this.tooltip.style.display = 'none'
      return
    }

    function isCursorInTable(state) {
      const { $anchor } = state.selection
      return !!$anchor.node(-1).type.spec.tableRole
    }

    const cursorInTable = isCursorInTable(state)

    if (!cursorInTable) return

    const pos = selection.$head.pos

    const { $anchor, $head } = selection
    const selectedCell =
      (!$anchor.nodeAfter || !$anchor.nodeBefore) ||
        (!$head.nodeAfter || !$head.nodeBefore)
        ? view.domAtPos(pos).node
        : view.domAtPos(pos).node.parentNode

    this.tooltip.style.display = 'block'
    this.tooltip.style.position = "absolute"
    const box = this.tooltip.offsetParent.getBoundingClientRect()

    let tooltipLeft
    if (selection instanceof CellSelection) {
      const selectedCellPos = selectedCell.getBoundingClientRect()
      const cellWidth = selectedCellPos.width
      tooltipLeft = selectedCellPos.x + cellWidth - box.left - 24
      this.tooltip.style.left = tooltipLeft + 'px'
      this.tooltip.style.bottom = box.bottom - selectedCellPos.y - 24 + 'px'
    } else {
      const selectedCellPos = selectedCell.offsetParent.getBoundingClientRect()
      const cellWidth = selectedCellPos.width
      tooltipLeft = selectedCellPos.x + cellWidth - box.left - 24
      this.tooltip.style.left = tooltipLeft + 'px'
      this.tooltip.style.bottom = box.bottom - selectedCellPos.y - 24 + 'px'
    }

    const editorBox = document.querySelector('.integration-editor').getBoundingClientRect()
    if (tooltipLeft > editorBox.width + 600) {
      this.tooltip.style.display = "none";
    }
  }

  /**
   *
   * @param {MouseEvent} event
   */
  _hideTooltip(event) {
    event.stopPropagation()

    const view = this.view
    const selection = view.state.selection
    const pos = selection.$head.pos

    if (
      view.domAtPos(pos).node === event.target ||
      view.domAtPos(pos).node.parentElement === event.target
    ) {
      this.tableMenu.style.display = "none";
      return;
    }

    if (event.target.closest(`.${this.CSS}`)) return

    this.tooltip.style.display = 'none'
    this.tableMenu.style.display = 'none'
  }

  _showPalette(event) {
    event.stopPropagation()
    this.palette.style.display = 'grid'
  }

  _hidePalette(event) {
    event.stopPropagation()
    this.palette.style.display = 'none'
  }

  _selectColor(event) {
    event.preventDefault()
    const colorData = event.target.getAttribute("data-color")
    const color = colorData === 'reset-style' ? '' : colorData
    this.tableMenu.style.display = 'none'
    return setCellAttr('background', color)(this.view.state, this.view.dispatch, this.view)
  }

  /**
   *
   * @param {import('prosemirror-view').EditorView} view
   * @returns
   */
  update(view) {
    if (cellTooltip.getState(view.state) === 'open') {
      this._showTooltip()
      return
    }
  }
}

export const cellTooltip = new Plugin({
  state: {
    init() {
      return undefined
    },
    apply(tr) {
      const meta = tr.getMeta(this)
      if (meta) {
        return meta
      }
    },
  },
  view(editorView) {
    return new CellTooltipPluginView(editorView)
  },
})
