export const MAIN_CATEGORY = [
  { label: "게시판", value: "board" },
  { label: "Q&A", value: "qna" },
  { label: "공동구매", value: "groupbuying" },
  { label: "콘텐츠", value: "contents" },
  { label: "DHS", value: "dhs" },
  { label: "구인공고", value: "recruit" },
  { label: "세미나", value: "seminar" },
];

export const BANNER_TYPES = [
  {
    value: "login",
    label: "로그인",
  },
  {
    value: "main",
    label: "메인배너",
  },
  {
    value: "main-middle",
    label: "메인 중간배너",
  },
  {
    value: "board",
    label: "게시판 배너",
  },
  {
    value: "qna",
    label: "Q&A 배너",
  },
  {
    value: "group-buy",
    label: "공동구매 배너",
  },
  {
    value: "contents",
    label: "콘텐츠 배너",
  },
  {
    value: "recruit",
    label: "구인공고 배너",
  },
  {
    value: "seminar",
    label: "세미나 배너",
  },
  {
    value: "board-article",
    label: "게시글 배너",
  },
];

export const USER_TYPES = [
  { value: "pending", label: "미승인회원" },
  { value: "hygienist", label: "치과위생사" },
  { value: "student", label: "치위학생" },
  { value: "partner", label: "연자" },
  { value: "admin", label: "관리자" },
];

export const USER_STATUS = [
  { value: "pending", label: "대기" },
  { value: "reject", label: "보류" },
  { value: "approve", label: "승인" },
];
