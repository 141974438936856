import dayjs from "dayjs";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMemberCount, useMemberList } from "../../api/member";
import Header from "../../components/Header/Header";
import ReactPaginate from "react-paginate";

const Member = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const searchQuery = urlParam.get("searchQuery");
  const [search, setSearch] = useState("");

  const { data: memberList, isLoading: memberListLoading } = useMemberList({
    page,
    searchQuery,
    perPage: 16,
  });
  const { data: memberCount } = useMemberCount();

  const onPageClick = (selectedItem) => {
    const selected = selectedItem.selected + 1;
    urlParam.set("page", selected);
    navigate(`/member?${urlParam}`);
  };

  const searchKeyword = () => {
    urlParam.set("searchQuery", search);
    urlParam.set("page", 1);
    navigate(`/member?${urlParam}`);
  };

  if (memberListLoading || !memberList) return <></>;
  return (
    <div>
      <Header />
      <div className="px-4 max-w-[1200px] m-auto mb-20">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="text-[23px] font-semibold text-[#333]">
              전체회원
            </div>
            <div className="text-[#666] text-md font-semibold">
              회원수 : {memberCount?.count || 0}명
            </div>
            <button className="border-[1px] space-x-1 border-[#52C171] flex items-center justify-between px-[7px] text-[#52C171] rounded-sm">
              <i className="text-sm ii-import"></i>
              <div className="text-2xs">엑셀 다운</div>
            </button>
          </div>
          <form>
            <label className="flex items-center space-x-2">
              <input
                type="text"
                className="shadow-custom rounded-full py-1 w-[275px] placeholder:text-sm px-4"
                placeholder="검색어를 입력하세요."
                value={search}
                onChange={(e) => setSearch(e.target.value)}></input>
              <button
                className="flex items-center justify-center rounded-full dark"
                style={{
                  width: "25px",
                  height: "35px",
                  minWidth: "0",
                  minHeight: "0",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  searchKeyword();
                }}>
                <i className="ii-search text-[#fff] text-base"></i>
              </button>
            </label>
          </form>
        </div>

        <div className="table">
          <div
            className="row header rounded-xl"
            style={{ background: "#F4F6F8", padding: "15px 0" }}>
            <div className="w-[10%] justify-center text-[#637381]">ID</div>
            <div className="w-[15%] justify-center text-[#637381]">
              회원유형
            </div>
            <div className="w-[15%] text-[#637381]">연락처</div>
            <div className="w-[20%] text-[#637381]">이메일</div>
            <div className="w-[12%] text-[#637381]">이름</div>
            <div className="w-[12%] text-[#637381]">닉네임</div>
            <div className="w-[12%] text-[#637381]">추천인</div>
            <div className="w-[15%] text-[#637381]">가입일</div>
            <div className="w-[10%] justify-center text-[#637381]">관리</div>
          </div>
          {memberList.users?.map((member) => (
            <>
              <div
                className="row"
                key={member.id}
                style={{ padding: "10px 0", borderBottom: "none" }}>
                <div className="w-[10%] justify-center">{member?.username}</div>
                <div className="w-[15%] justify-center text-main">
                  {member?.userType}
                </div>
                <div className="w-[15%]">{member?.phoneNumber}</div>
                <div className="w-[20%]">{member?.email}</div>
                <div className="w-[12%]">{member?.fullname}</div>
                <div className="w-[12%]">{member?.nickname}</div>
                <div className="w-[12%]">{member?.recommendUser?.username}</div>
                <div className="w-[15%]">
                  {member.createdAt
                    ? dayjs(member.createdAt).format("YYYY-MM-DD hh:mm")
                    : ""}
                </div>
                <div className="w-[10%] justify-center">
                  <Link to={`/memberdetail/${member?.id}`} className="ml-3">
                    <button className="dark">상세</button>
                  </Link>
                </div>
              </div>
            </>
          ))}
        </div>
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          pageCount={memberList.totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageClassName="page"
          activeClassName="active"
          onPageChange={onPageClick}
          forcePage={memberList.currentPage - 1}
          className="flex items-center justify-center space-x-2 pagination mt-14"></ReactPaginate>
      </div>
    </div>
  );
};

export default Member;
