import React from "react";

const Popup = ({ text = "삭제하시겠습니까?", setPopup, fetch }) => {
  return (
    <div>
      <div className="fixed left-0 right-0 top-0 bottom-0 z-[60] bg-[rgba(0,0,0,0.4)]"></div>
      <div className="fixed z-[70] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
        <div className="bg-white w-[328px] h-[168px] flex flex-col items-center justify-center space-y-[13px] shadow-custom rounded-2xl relative">
          <div className="pb-5">{text}</div>
          <div className="text-white space-x-4">
            <button
              className="px-8 py-2 rounded-full"
              onClick={() => setPopup(false)}
            >
              취소
            </button>
            <button className="main px-8 py-2 rounded-full" onClick={fetch}>
              확인
            </button>
          </div>
          <div
            className="absolute -top-2 right-2 cursor-pointer"
            onClick={() => setPopup(false)}
          >
            <i className="ii ii-remove text-xl text-[#AAA]"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
