import {Plugin} from 'prosemirror-state'
import {Decoration, DecorationSet} from 'prosemirror-view'

import { LoadingIcon } from '../assets/icons'

/**
 * 미디어 업로드 시 미디어가 삽입될 자리에 처리중 아이콘을 표시하기 위한 플러그인입니다.
 * 
 * 참고한 자료: https://prosemirror.net/examples/upload/
 */
export const indicatorPlugin = new Plugin({
  state: {
    init() {
      return DecorationSet.empty
    },
    apply(tr, set) {
      // Adjust decoration positions to changes made by the transaction
      set = set.map(tr.mapping, tr.doc)

      /**
       * Transaction 의 Meta 데이터에 add 가 있다면 이미지 업로드가 성공한 경우로,
       * remove 가 있다면 실패한 경우로 판단합니다. (startImageUpload 함수의 setMeta 호출 코드 참조)
       */
      const action = tr.getMeta(this)

      if (action && action.add) {
        // 임시로 들어갈 위젯을 생성합니다.
        const widget = document.createElement('div');

        // 이미지가 삽입될 자리에 Loading icon이 들어갑니다. 요청이 완료되면 이미지로 대체됩니다.
        widget.innerHTML = LoadingIcon;

        const deco = Decoration.widget(action.add.pos, widget, {
          id: action.add.id,
        })

        set = set.add(tr.doc, [deco])
        return set
      }

      if (action && action.remove) {
        set = set.remove(
          set.find(null, null, spec => spec.id == action.remove.id),
        )
        return set
      }

      return set
    },
  },
  props: {
    decorations(state) {
      return this.getState(state)
    },
  },
})

export function findIndicator(state, id) {
  const decos = indicatorPlugin.getState(state)
  const found = decos.find(null, null, spec => spec.id == id)
  return found.length ? found[0].from : null
}
