import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

/**
 * @type {import("prosemirror-model").NodeSpec}
 */
export const pollNodeSpec = {
	attrs: {
		'poll-id': { default: '' },
		'poll-title': { default: '' },
	},
	group: 'block',
	marks: '',
	selectable: true,
	draggable: true,
	toDOM(node) {
		return [
			'poll',
			{
				class: MEDISTREAM_SCHEMA_STYLE.nodes.poll,
				'poll-id': node.attrs['poll-id'] || null,
				'poll-title': node.attrs['poll-title'] || null,
			}
		]
	},
	parseDOM: [
		{
			tag: 'poll',
			getAttrs: (dom) => ({
				['poll-id']: dom.getAttribute('poll-id') || null,
				['poll-title']: dom.getAttribute('poll-title') || null,
			})
		}
	]
}