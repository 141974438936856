import { TextSelection } from 'prosemirror-state'

/**
 * @type {import('prosemirror-state').Command}
 */
export const createPoll = (pollData) => (state, dispatch) => {
  if (!state.selection.empty) return false

  const pollNode = state.schema.nodes.poll.create({ 'poll-id': pollData.id, 'poll-title': pollData.title });

  const selectionHead = state.selection.$head
  const isInsideBlockquote =
    selectionHead.node(selectionHead.depth - 1).type.name ===
    state.schema.nodes.blockquote.name
  const isInEmptyParagraphNode =
    selectionHead.node(selectionHead.depth).content.size === 0

  // 인용문 안에 있을 경우 인용문 밖에 poll 노드를 삽입합니다.
  if (isInsideBlockquote) {
    const insertAt = selectionHead.after(selectionHead.depth - 1)
    const tr = state.tr.insert(insertAt, pollNode)
    const tr2 = tr.setSelection(TextSelection.create(tr.doc, insertAt + 1))

    dispatch(tr2)
    return true
  }

  // 커서가 빈 Paragraph 블록에 있을 경우 poll 노드로 교체합니다.
  if (isInEmptyParagraphNode) {
    const tr = state.tr.replaceSelectionWith(pollNode)

    dispatch(tr)
    return true
  }

  // 다음 블럭에 poll 노드를 삽입합니다.
  const posAfterCurNode = selectionHead.end(selectionHead.depth)
  const tr = state.tr.insert(posAfterCurNode, pollNode)
  const tr2 = tr.setSelection(TextSelection.create(tr.doc, posAfterCurNode + 2))

  dispatch(tr2)
  return true
}