import { cartSVG, couponSVG, productSVG } from "../assets/icons"
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

class CustomTemplateNodeView {
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos

    const id = node.attrs.id
    const dataTarget = node.attrs['data-target']
    const dataOption = node.attrs['data-option']
    const dataImage = node.attrs['data-image']
    const dataComment = node.attrs['data-comment']

    this.dom = document.createElement('div')
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.nodes.custom)

    const iconWrapper = document.createElement('div')
    iconWrapper.className = MEDISTREAM_SCHEMA_STYLE.etc.iconWrapper

    const typeDiv = document.createElement('div')
    typeDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.typeDiv

    const titleDiv = document.createElement('div')
    titleDiv.innerHTML = dataTarget
    titleDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.titleDiv

    id && this.dom.setAttribute('id', id)
    dataTarget && this.dom.setAttribute('data-target', dataTarget)
    dataOption && this.dom.setAttribute('data-option', dataOption)
    dataImage && this.dom.setAttribute('data-image', dataImage)
    dataComment && this.dom.setAttribute('data-comment', dataComment)

    switch (id) {
      case 'addToCart':
        iconWrapper.innerHTML = cartSVG
        typeDiv.innerHTML = '장바구니 위젯'
        break
      case 'productPreview':
        iconWrapper.innerHTML = productSVG
        typeDiv.innerHTML = '상품 위젯'
        break
      case 'downloadCoupon':
        iconWrapper.innerHTML = couponSVG
        typeDiv.innerHTML = '쿠폰 위젯'
        break
      case 'marketingAgreement':
        typeDiv.innerHTML = '마케팅 수신동의 프로모션 위젯'
        break
      case 'marketingAgreement':
        type.textContent = '마케팅 수신동의 프로모션 위젯: '
        this.dom.appendChild(type)
        break
      default:
        typeDiv.innerHTML = '잘못된 형식입니다'
    }

    this.dom.appendChild(iconWrapper)
    this.dom.appendChild(typeDiv)
    this.dom.appendChild(titleDiv)
  }
}


export const customTemplateNodeView = (node, view, getPos) =>
  new CustomTemplateNodeView(node, view, getPos)
