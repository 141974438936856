import { SwrReq } from "../util";
import { SwrRes } from "../util";

export const useBoardList = (pathname, query) => {
  const { data, error } = SwrReq(
    `/admin/${pathname === "board" ? "boards" : pathname}`,
    query
  );
  return SwrRes(data, error);
};

export const useBoardDetail = (id, pathname, query) => {
  const { data, error } = SwrReq(
    `/admin/${pathname === "board" ? "boards" : pathname}/${id}`,
    query
  );
  return SwrRes(data, error);
};

export const useCateList = (pathname) => {
  const { data, error } = SwrReq(`/category/${pathname}`);
  return SwrRes(data, error);
};

export const useSeminarList = (query) => {
  const { data, error } = SwrReq(`/admin/seminar`, query);
  return SwrRes(data, error);
};