import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

/**
 * 글자의 배경색은 <span> 태그의 background-color 속성으로 표현됩니다.
 * 
 * text_color Mark 와 마찬가지로 외부 글을 붙여넣을 때 background-color 속성값이 따라오는 경우에 대응합니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const textBackgroundMarkSpec = {
  attrs: {
    style: {
      default: null
    }
  },
  inclusive: false,
  toDOM: node => ['span', { style: node.attrs.style, class: MEDISTREAM_SCHEMA_STYLE.marks.background_color }, 0],
  parseDOM: [
    {
      tag: 'span',
      style: 'background-color',
      getAttrs: dom => {
        if (dom.style?.backgroundColor) {
          /**
           * MARK: Google Docs 에서 작성한 글을 붙여넣을 때 간헐적으로 따라오는 스타일을 제거합니다.
           *       예) <span style="background-color: rgb(255, 255, 255);"></span>
           */
          if (dom.style.backgroundColor === 'transparent') return false
          if (dom.style.backgroundColor === 'rgb(255, 255, 255)') return false
          if (dom.style.backgroundColor === 'rgb(248, 248, 248)') return false
          return {
            style: `background-color: ${dom.style.backgroundColor};`
          }
        }
        return false
      },
      consuming: false
    }
  ]
}
