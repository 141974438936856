import { CellSelection } from '@medistream/prosemirror-tables'

/**
 * @type {(fn: string) => import('prosemirror-state').Command}
 */
export const setTextColor = (color) => (state, dispatch) => {
  const tr = state.tr
  const selection = tr.selection
  const {from, to} = state.selection

  if (selection instanceof CellSelection) {
    const heading = state.schema.nodes.heading
    const paragraph = state.schema.nodes.paragraph
    const tasks = []
    const allowedNodeTypes = new Set([heading, paragraph])

    selection.forEachCell((cell, pos) => {
      cell.content.forEach((node, offset) => {
        const resolvedNode = state.doc.resolve(pos + offset + 1).nodeAfter
        if (allowedNodeTypes.has(node.type)) {
          tasks.push({
            pos: pos + offset + 1,
            posEnd: pos + offset + 1 + resolvedNode.nodeSize,
          })
        }
      })
    })

    if (!tasks.length) return false

    tasks.forEach(job => {
      const {pos, posEnd} = job

      color === 'reset-style'
      ? tr.removeMark(pos, posEnd, state.schema.marks.text_color)
      : tr.addMark(pos, posEnd, state.schema.marks.text_color.create({style: `color: ${color};`}))
    })
    dispatch(tr)
    return true
  }

  if (color === 'reset-style') {
    tr.removeMark(from, to, state.schema.marks.text_color)
    return dispatch(tr)
  }

  tr.addMark(from, to, state.schema.marks.text_color.create({style: `color: ${color};`}))
  dispatch(tr)
  return true
}
