import PrismaZoom from "react-prismazoom";
import { useRef, useState } from "react";

const ZoomImg = ({ children }) => {
  const [zoomState, setZoomState] = useState({ state: false, src: "" });
  const [zoomScale, setZoomScale] = useState(1);
  const prismaZoom = useRef(null);

  const captureImg = (e) => {
    if (!e.target.closest("a") && e.target.closest("img")) {
      e.preventDefault();
      setZoomState({ state: true, src: e.target.closest("img").src });
    }
  };

  if (zoomState.state) {
    return (
      <div>
        <div
          className="popup-overlay dark"
          onClick={() => {
            setZoomState({ state: false, src: "" });
            setZoomScale(1);
          }}
        ></div>
        <div
          id="img-zoom"
          onContextMenu={(e) => {
            e.target.matches("img") && e.preventDefault();
          }}
        >
          <div
            className="zoom-img__close"
            onClick={() => {
              setZoomState({ state: false, src: "" });
              setZoomScale(1);
            }}
          ></div>
          <PrismaZoom
            className="prismaZoom"
            maxZoom={10}
            onZoomChange={() => setZoomScale(prismaZoom.current.getZoom())}
            ref={prismaZoom}
          >
            <img src={zoomState.src} alt="" />
          </PrismaZoom>
          <footer className="App-footer">
            <div className="App-indicator">
              <button
                className="App-button"
                onClick={() => prismaZoom.current.zoomOut(0.5)}
              >
                <svg className="App-buttonIcon" viewBox="0 0 24 24">
                  <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm4-9H8a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2z"></path>
                </svg>
              </button>
              <span className="App-zoomLabel">
                {(zoomScale * 100).toFixed(0)}%
              </span>
              <button
                className="App-button"
                onClick={() => prismaZoom.current.zoomIn(0.5)}
              >
                <svg className="App-buttonIcon" viewBox="0 0 24 24">
                  <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm4-9h-3V8a1 1 0 0 0-2 0v3H8a1 1 0 0 0 0 2h3v3a1 1 0 0 0 2 0v-3h3a1 1 0 0 0 0-2z"></path>
                </svg>
              </button>
            </div>
          </footer>
        </div>
        {children}
      </div>
    );
  }
  return <div onClickCapture={captureImg}>{children}</div>;
};

export default ZoomImg;
