import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

/**
 * 마켓 운영에 필요한 <template> 태그를 삽입하는 경우를 대응합니다.
 * 
 * <template id="addToCart" data-target=""></template> 형태로 DOM 에 표현됩니다.
 * 
 * <template/> 태그는 DOM 에서 보이지 않습니다.
 * 따라서, 에디터에서는 NodeView 를 통해 박스 형태로 표현합니다.
 * 발행된 글 에서는 v-runtime-template 라이브러리를 통해 준비된 Vue 컴포넌트로 교체 후 렌더링합니다. (파싱 과정은 medistream-frontend 저장소의 ProseMirrorView.vue 참고)
 * 
 * <template/> 태그를 사용하게된 이유: 
 *  (구)froala 에디터에서 쿠폰 위젯 등을 본문에 추가할 때 사용하던 태그입니다.
 *  기존 운영진의 사용 방식을 존중하기 위해 유지하기로 결정했습니다.
 *  
 * @type {import("prosemirror-model").NodeSpec}
 */
export const customTemplateNodeSpec = {
  attrs: {
    id: {default: ''},
    'data-target': {default: ''},
    'data-option': {default: ''},
    'data-image': {default: ''},
    'data-comment': {default: ''},
  },
  group: 'block',
  marks: '',
  selectable: true,
  draggable: true,
  toDOM(node) {
    return [
      'template',
      {
        class: MEDISTREAM_SCHEMA_STYLE.nodes.custom,
        id: node.attrs.id,
        'data-target': node.attrs['data-target'] || null,
        'data-option': node.attrs['data-option'] || null,
        'data-image': node.attrs['data-image'] || null,
        'data-comment': node.attrs['data-comment'] || null,
      }
    ]
  },
  parseDOM: [
    {
      tag: 'template',
      getAttrs: (dom) => ({
        id: dom.getAttribute('id'),
        ['data-target']: dom.getAttribute('data-target') || null,
        ['data-option']: dom.getAttribute('data-option') || null,
        ['data-image']: dom.getAttribute('data-image') || null,
        ['data-comment']: dom.getAttribute('data-comment') || null,
      })
    }
  ]
}
