import { setBlockType, toggleMark, wrapIn } from 'prosemirror-commands';
import { Plugin } from 'prosemirror-state';
import { addColumnAfter, addColumnBefore, addRowAfter, addRowBefore, deleteColumn, deleteRow, mergeCells, setCellAttr, splitCell, toggleHeaderColumn, toggleHeaderRow } from '@medistream/prosemirror-tables';

import { addCustomTemplate, addDirectContactButton } from '../commands/customTemplate';
import { addHorizontalRuleNextLine } from '../commands/hr';
import { displayHtmlInsertInput } from '../commands/htmlInsert';
import { displayIframeLinkInput } from '../commands/iframe';
import { addCallout, insertTable, toggleCellBorder } from '../commands/table';
import { alignTextCenter, alignTextLeft, alignTextRight } from '../commands/textAlign';
import { setTextColor } from '../commands/textColor';
import { setTextBackgroundColor } from '../commands/textHighlight';
import { displayUploadIndicator, insertFailureMessage, insertFile, insertImage, insertVideo } from '../commands/upload';
import { prependHTTPS } from '../utils/regex';
import { updateLink } from './link';
import { insertEmoji } from '../commands/emoji';
import { setFontSize } from '../commands/fontSize';
import { createPoll } from '../commands/poll';

export const remoteMenu = new Plugin({
  state: {
    init() {
      return undefined
    },
    apply(tr) {
      return tr.getMeta(this)
    }
  },
  view(_editorView) {
    return {
      update(view) {
        if (!remoteMenu.getState(view.state)) return
        const state = remoteMenu.getState(view.state)
        if (state === 'toggle-strong') {
          return toggleMark(view.state.schema.marks.strong)(view.state, view.dispatch)
        }
        if (state === 'toggle-em') {
          return toggleMark(view.state.schema.marks.em)(view.state, view.dispatch)
        }
        if (state === "toggle-underline") {
          return toggleMark(view.state.schema.marks.underline)(view.state, view.dispatch);
        }
        if (state === "toggle-highlighted") {
          return toggleMark(view.state.schema.marks.moreden_highlighted)(view.state, view.dispatch);
        }
        if (state === 'toggle-del') {
          return toggleMark(view.state.schema.marks.del)(view.state, view.dispatch)
        }
        if (state?.type === 'setTextFormat') {
          if (state.textFormat === 'heading') {
            return setBlockType(view.state.schema.nodes.heading, { level: 2 })(view.state, view.dispatch)
          } else if (state.textFormat === 'paragraph') {
            return setBlockType(view.state.schema.nodes.paragraph)(view.state, view.dispatch)
          }
        }
        if (state?.type === 'setFontSize') {
          return setFontSize(state.fontSize)(view.state, view.dispatch);
        }
        if (state === 'toggle-blockquote') {
          return wrapIn(view.state.schema.nodes.blockquote)(view.state, view.dispatch)
        }
        if (state === 'toggle-hr') {
          return addHorizontalRuleNextLine(view.state, view.dispatch)
        }
        if (state === 'toggle-embed') {
          return displayIframeLinkInput(view.state, view.dispatch)
        }
        if (state?.type === 'insertLink') {
          return updateLink(prependHTTPS(state.value.trim()))(view.state, view.dispatch, view)
        }
        if (state === 'displayHTMLDialog') {
          return displayHtmlInsertInput(view.state, view.dispatch)
        }
        if (state === 'alignLeft') {
          return alignTextLeft(view.state, view.dispatch)
        }
        if (state === 'alignCenter') {
          return alignTextCenter(view.state, view.dispatch)
        }
        if (state === 'alignRight') {
          return alignTextRight(view.state, view.dispatch)
        }
        if (state?.type === 'setTextColor') {
          return setTextColor(state.color)(view.state, view.dispatch)
        }
        if (state?.type === 'setTextBackgroundColor') {
          return setTextBackgroundColor(state.color)(view.state, view.dispatch)
        }
        if (state?.type === 'before-drag-upload') {
          return displayUploadIndicator(state.id, state.coords)(view.state, view.dispatch, view)
        }
        if (state?.type === 'before-upload') {
          return displayUploadIndicator(state.id)(view.state, view.dispatch, view)
        }
        if (state?.type === 'upload-failure-message') {
          const { id, message } = state
          alert(message)
          return insertFailureMessage(id)(view.state, view.dispatch)
        }
        if (state?.type === 'image') {
          const { urls, id } = state
          return insertImage(urls, id)(view.state, view.dispatch, view)
        }
        if (state?.type === 'video') {
          const { videos, id } = state
          return insertVideo(videos, id)(view.state, view.dispatch, view)
        }
        if (state?.type === 'file') {
          const { urls, id } = state
          return insertFile(urls, id)(view.state, view.dispatch, view)
        }
        if (state === 'addTable') {
          return insertTable(view.state, view.dispatch)
        }
        if (state?.type === 'setCellAttrs') {
          const colorData = state.color
          const color = colorData === 'reset-style' ? '' : colorData
          return setCellAttr('background', color)(view.state, view.dispatch, view)
        }
        if (state === 'mergeCells') {
          return mergeCells(view.state, view.dispatch)
        }
        if (state === 'splitCell') {
          return splitCell(view.state, view.dispatch)
        }
        if (state === 'headerColumn') {
          return toggleHeaderColumn(view.state, view.dispatch)
        }
        if (state === 'headerRow') {
          return toggleHeaderRow(view.state, view.dispatch)
        }
        if (state === 'removeColumn') {
          return deleteColumn(view.state, view.dispatch)
        }
        if (state === 'removeRow') {
          return deleteRow(view.state, view.dispatch)
        }
        if (state === 'addRowAbove') {
          return addRowBefore(view.state, view.dispatch)
        }
        if (state === 'addRowBelow') {
          return addRowAfter(view.state, view.dispatch)
        }
        if (state === 'addColumnRight') {
          return addColumnAfter(view.state, view.dispatch)
        }
        if (state === 'addColumnLeft') {
          return addColumnBefore(view.state, view.dispatch)
        }
        if (state === 'toggleCellBorder') {
          return toggleCellBorder(view.state, view.dispatch, view)
        }
        if (state === 'addCallout') {
          return addCallout(view.state, view.dispatch)
        }
        if (state === 'addCustomTemplate') {
          return addCustomTemplate(view.state, view.dispatch)
        }
        if (state === 'addDirectContactButton') {
          return addDirectContactButton(view.state, view.dispatch)
        }
        if (state?.type === 'addEmoji') {
          const { emoji } = state
          return insertEmoji(emoji)(view.state, view.dispatch)
        }
        if (state?.type === 'addPoll') {
          const { pollData } = state
          return createPoll(pollData)(view.state, view.dispatch)
        }
      }
    }
  }
})
