import React from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useUserGamePassLog } from "../../api/game";

export default function GameLog() {
  const { id } = useParams();

  const { data: gamePassLogList, isLoading: gamePassLogListLoading } =
    useUserGamePassLog(id);

  const sourceType = (type) => {
    switch (type) {
      case "BOARD":
        return "board 글작성";
      case "QNA":
        return "Q&A 글작성";
      case "BOARD_COMMENT":
        return "board 댓글";
      case "QNA_COMMENT":
        return "Q&A 댓글";
      case "ADMIN":
        return "관리자 부여";
      case "GAME":
        return "게임 실행";
      case "AUTO":
        return "자동 생성";
      case "STARTER":
        return "게임 시작";
      default:
        return "관리자";
    }
  };

  const replaceCheestalk = (item) => {
    switch (item.sourceType) {
      case "GAME":
        window.open(
          `https://cheestalk.com/game/${item.sourceId}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "BOARD":
        window.open(
          `https://cheestalk.com/board/${item.sourceId}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "QNA":
        window.open(
          `https://cheestalk.com/qna/${item.sourceId}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "BOARD_COMMENT":
        window.open(
          `https://cheestalk.com/board/${item.articleId}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "QNA_COMMENT":
        window.open(
          `https://cheestalk.com/qna/${item.articleId}`,
          "_blank",
          "noopener,noreferrer"
        );
        break;
      default:
        break;
    }
  };

  if (gamePassLogListLoading || !gamePassLogList) return <></>;
  return (
    <div>
      <div className="max-w-[1200px] m-auto">
        <div className="table">
          <div className="justify-evenly row header rounded-xl !bg-[#F4F6F8] !p-4">
            <div className="w-[20%] text-[#637381] flex items-center justify-center">
              게시글/댓글/게임 id
            </div>
            <div className="w-[20%] text-[#637381] flex items-center justify-center">
              획득 타입
            </div>
            <div className="w-[20%] text-[#637381] flex items-center justify-center">
              차감/획득된 도전권수
            </div>
            <div className="w-[20%] text-[#637381] flex items-center justify-center">
              댓글 내용
            </div>
            <div className="w-[20%] text-[#637381] flex items-center justify-center">
              생성일
            </div>
          </div>

          {gamePassLogList.map((item) => {
            return (
              <div key={item.id} className="!py-3 justify-evenly row">
                {item.sourceType === "ADMIN" ||
                item.sourceType === "GAME" ||
                item.sourceType === "STARTER" ||
                item.sourceType === "AUTO" ? (
                  <div className="w-[20%] flex items-center justify-center">
                    {item.sourceId}
                  </div>
                ) : (
                  <button
                    className="w-[20%] flex items-center justify-center"
                    onClick={() => replaceCheestalk(item)}>
                    {item.sourceId}
                  </button>
                )}

                <div className="w-[20%] flex items-center justify-center">
                  {sourceType(item.sourceType)}
                </div>
                <div className="w-[20%] flex items-center justify-center">
                  {item.pass}
                </div>
                <div className="w-[20%] flex items-center justify-center">
                  {item.content}
                </div>
                <div className="w-[20%] flex items-center justify-center">
                  {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
