import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const emDOM = ['em', {class: MEDISTREAM_SCHEMA_STYLE.marks.em}, 0]

/**
 * 기울임체는 <em> 태그로 표현됩니다.
 * 다만, 외부 컨텐츠를 에디터로 파싱해 올 때는 <i>, <em>, 'font-style=italic' 모두 <em> 태그로 변환합니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const emMarkSpec = {
  toDOM() {
    return emDOM
  },
  parseDOM: [
    {tag: 'i'},
    {tag: 'em'},
    {style: 'font-style=italic'},
    {style: 'font-style=normal', clearMark: m => m.type.name == 'em'},
  ],
}
