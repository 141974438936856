import { pollSVG } from "../assets/icons"
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

class PollNodeView {
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos

    const pollId = node.attrs['poll-id']
    const pollTitle = node.attrs['poll-title']

    this.dom = document.createElement('div');
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.nodes.custom)

    const iconWrapper = document.createElement('div')
    iconWrapper.innerHTML = pollSVG
    iconWrapper.className = MEDISTREAM_SCHEMA_STYLE.etc.iconWrapper

    const typeDiv = document.createElement('div')
    typeDiv.innerHTML = '투표'
    typeDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.typeDiv

    const questionText = pollTitle || 'Poll question'
    const titleDiv = document.createElement('div')
    titleDiv.innerHTML = questionText
    titleDiv.className = MEDISTREAM_SCHEMA_STYLE.etc.titleDiv

    pollId && this.dom.setAttribute('poll-id', pollId)
    pollTitle && this.dom.setAttribute('poll-title', pollTitle)

    this.dom.appendChild(iconWrapper)
    this.dom.appendChild(typeDiv)
    this.dom.appendChild(titleDiv)
  }
}

export const pollNodeView = (node, view, getPos) =>
  new PollNodeView(node, view, getPos)
