import { MEDISTREAM_SCHEMA_STYLE } from '../styles/classNames'

class HrNodeView {
  /**
   * @type {import('prosemirror-view').NodeViewConstructor}
   */
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos

    const hr = document.createElement('hr')
    hr.style.clear = 'both'
    hr.style.border = 'solid #dedede'
    hr.style.borderWidth = '0 0 1px'
    this.node.attrs.style && hr.setAttribute('style', this.node.attrs.style)

    this.dom = document.createElement('div')
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.etc.hrWrapper)
    this.dom.style.width = '100%'
    this.dom.append(hr)
  }
}

export const hrNodeView = (node, view, getPos) =>
  new HrNodeView(node, view, getPos)
