import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { axiosClient } from "../../api/axiosClient";
import { useBannerList } from "../../api/banner";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup";
import { BANNER_TYPES } from "../../constants";

export default function Banner() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("accessToken");
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const [deletePopup, setDeletePopup] = useState(false);

  const { data: bannerList, isLoading: bannerListLoading } = useBannerList({
    page,
    perPage: 16,
  });

  const onPageClick = (selectedItem) => {
    const selected = selectedItem.selected + 1;
    urlParam.set("page", selected);
    navigate(`/banner?${urlParam}`);
  };

  const deleteBanner = (id) => {
    axiosClient(token)
      .delete(`/admin/banners/${id}`)
      .then(() => {
        alert("삭제되었습니다.");
        setDeletePopup(false);
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  if (!bannerList || bannerListLoading) return <></>;
  return (
    <div className="pb-20">
      {deletePopup && (
        <Popup
          setPopup={setDeletePopup}
          fetch={() => deleteBanner(deletePopup)}
        />
      )}
      <Header />
      <div className="inner page">
        <div className="flex items-end justify-between mb-7">
          <div className="page-tit">배너관리</div>
          <Link to="/banner/write">
            <button className="main">등록</button>
          </Link>
        </div>
        <div className="flex items-center justify-evenly w-[1200px] bg-main text-white py-[6px] pl-3 text-base font-semibold">
          <div className="w-[5%]">순서</div>
          <div className="w-[15%]">위치</div>
          <div className="w-[25%]">이미지</div>
          <div className="w-[35%]">연결링크</div>
          <div className="w-[5%]">노출환경</div>
          <div className="w-[8%] flex items-center justify-center">수정</div>
          <div className="w-[7%] flex items-center justify-center">삭제</div>
        </div>
        {bannerList.bannerList?.map((banner) => (
          <div
            className="flex items-center justify-evenly w-[1200px] py-6 border-b-[1px] border-[#999] pl-3 text-[#666] font-semibold text-base"
            key={banner.id}>
            <div className="w-[5%]">{banner?.order}</div>
            <div className="w-[15%]">
              {BANNER_TYPES.find((o) => o.value === banner?.location)?.label}
            </div>
            <div className="w-[25%]">
              <img src={banner?.image} className="w-[80%]" alt="img" />
            </div>
            <div className="w-[35%] break-words">{banner?.linkedUrl}</div>
            <div className="w-[5%] flex items-center justify-center">
              {banner?.deviceType}
            </div>
            <div className="w-[8%] flex items-center justify-center">
              <button
                className="dark"
                onClick={() => navigate(`/banner/modify/${banner?.id}`)}>
                수정
              </button>
            </div>
            <div className="w-[7%] flex items-center justify-center">
              <button
                className="white"
                onClick={() => setDeletePopup(banner?.id)}>
                삭제
              </button>
            </div>
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel="←"
        nextLabel="→"
        pageCount={bannerList.totalPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        pageClassName="page"
        activeClassName="active"
        onPageChange={onPageClick}
        forcePage={bannerList.currentPage - 1}
        className="flex items-center justify-center space-x-2 pagination mt-14"></ReactPaginate>
    </div>
  );
}
