import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

/**
 * 링크는 <a> 태그로 표현됩니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const linkMarkSpec = {
  attrs: {
    href: {default: null},
    download: {default: null},
    target: {default: null},
    style: {default: null},
  },
  inclusive: false,
  toDOM: node => [
    'a',
    {
      ...node.attrs,
      class: MEDISTREAM_SCHEMA_STYLE.marks.link,
      rel: 'noopener noreferrer nofollow',
    },
    0,
  ],
  parseDOM: [
    {
      tag: 'a[href]',
      getAttrs: dom => ({
        href: dom.getAttribute('href'),
        download: dom.getAttribute('download'),
        // target: dom.getAttribute('target'), // 운영상의 이유로 target 을 제거합니다.
        // style: dom.getAttribute('style'),  // 스타일 통합을 위해 style 을 제거합니다.
      }),
    },
  ],
}
