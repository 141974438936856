import { MEDISTREAM_SCHEMA_STYLE } from '../styles/classNames'

class IframeNodeView {
  /**
   * @type {import('prosemirror-view').NodeViewConstructor}
   */
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos
    this.unresizeablePlatforms = ['youtube', 'kollus']
    this.platform = this.node.attrs['data-platform']

    this.iframe = document.createElement('iframe')
    this.iframe.src = this.node.attrs.src
    this.iframe.height = this.node.attrs.height
    this.iframe.frameBorder = 0
    this.iframe.allowFullscreen = true
    this.iframe.className = MEDISTREAM_SCHEMA_STYLE.nodes.iframe
    this.iframe.setAttribute('data-youtube-id', this.node.attrs['data-youtube-id'])
    this.iframe.setAttribute('data-platform', this.platform)
    this.iframe.setAttribute('data-embed-src', this.node.attrs.src)

    this.dom = document.createElement('div')
    this.dom.classList.add(MEDISTREAM_SCHEMA_STYLE.etc.iframeWrapper)

    this.dom.append(this.iframe)

    this._setInitialHeight()
    this._attachResizeBar()
  }

  selectNode() {
    this.dom.classList.add('ProseMirror-selectednode')
    if (this.resizeBar) {
      this.resizeBar.style.display = 'block'
    }
  }

  deselectNode() {
    this.dom.classList.remove('ProseMirror-selectednode')
    if (this.resizeBar) {
      this.resizeBar.style.display = 'none'
    }
  }

  /**
   * Youtube, Kollus 플레이어를 제외한 iframe 에는 높이를 조절하는 resize bar 를 추가합니다.
   */
  _attachResizeBar() {
    if (this.unresizeablePlatforms.includes(this.platform)) return

    this.resizeBar = document.createElement('div')
    this.resizeBar.className = MEDISTREAM_SCHEMA_STYLE.etc.iframeResizer

    const onMouseDown = (e) => {
      e.preventDefault()
      e.stopPropagation()

      const startY = e.pageY
      const startHeight = this.iframe.offsetHeight

      const onMouseMove = (e) => {
        const newHeight = startHeight + (e.pageY - startY)
        if (newHeight < 400) return
        this.iframe.height = newHeight
      }

      const onMouseUp = (e) => {
        const transaction = this.view.state.tr.setNodeAttribute(
          this.getPos(),
          'height',
          this.iframe.height
        )
  
        this.view.dispatch(transaction)
        window.removeEventListener('mousemove', onMouseMove)
        window.removeEventListener('mouseup', onMouseUp)
      }

      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    }

    this.resizeBar.addEventListener('mousedown', onMouseDown)
    this.dom.append(this.resizeBar)
  }

  _setInitialHeight() {
    if (this.node.attrs.height !== 'auto') return
    if (this.platform === 'google-form') {
      this.iframe.height = 1100
    } else if (this.platform === 'etc') {
      this.iframe.height = 700
    }
  }
}

export const iframeNodeView = (node, view, getPos) =>
  new IframeNodeView(node, view, getPos)
