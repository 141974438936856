import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

/**
 * @type {import('prosemirror-model').NodeSpec}
 */
export const videoNodeSpec = {
  attrs: {
    src: {},
    poster: {default: null},
  },
  inline: true,
  group: 'inline',
  draggable: true,
  marks: '',
  parseDOM: [
    {
      tag: 'video',
      getAttrs(dom) {
        const source = dom.querySelector('source')
        if (!source) return false
        return {
          src: source.getAttribute('src'),
          poster: dom.getAttribute('poster'),
        }
      },
    },
  ],
  toDOM(node) {
    const {src, poster} = node.attrs
    return [
      'video',
      {
        controls: '',
        draggable: 'false',
        poster,
        class: MEDISTREAM_SCHEMA_STYLE.nodes.video,
      },
      ['source', {src}],
    ]
  },
}
