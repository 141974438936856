import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const hrDOM = ['hr', {class: MEDISTREAM_SCHEMA_STYLE.nodes.horizontal_rule}]

/**
 * 가로줄은 <hr> 태그로 표현됩니다.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const horizontalRuleNodeSpec = {
  group: 'block',
  parseDOM: [{tag: 'hr'}],
  toDOM() {
    return hrDOM
  },
}
