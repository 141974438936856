import dayjs from "dayjs";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosClient } from "../../api/axiosClient";
import { useBoardList, useCateList } from "../../api/board";
import Header from "../../components/Header/Header";
import Popup from "../../components/Popup";
import { cls } from "../../util";

const Board = () => {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const searchQuery = urlParam.get("searchQuery");
  const boardType = urlParam.get("boardType") || "board";

  const [modify, setModify] = useState(false);
  const [cate, setCate] = useState(2);
  const [search, setSearch] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [modifyPopup, setModifyPopup] = useState(false);

  const { data: boardList, isLoading: boardListLoading } = useBoardList(
    boardType,
    {
      page,
      searchQuery,
      isNotice: false,
      perPage: 16,
    }
  );
  const { data: cateList } = useCateList(boardType);

  const onPageClick = (selectedItem) => {
    const selected = selectedItem.selected + 1;
    urlParam.set("page", selected);
    navigate(`/board?${urlParam}`);
  };

  const searchKeyword = () => {
    urlParam.set("searchQuery", search);
    urlParam.set("page", 1);
    navigate(`/board?${urlParam}`);
  };

  const deleteArticle = (id) => {
    axiosClient(token)
      .delete(`/admin/${boardType === "board" ? "boards" : boardType}/${id}`)
      .then(() => alert("삭제되었습니다"))
      .catch(() => alert("에러가 발생하였습니다"));
    setDeletePopup(false);
  };

  const changeCategory = (id) => {
    axiosClient(token)
      .patch(`/admin/${boardType === "board" ? "boards" : boardType}/${id}`, {
        categoryId: cate,
      })
      .then(() => alert("수정되었습니다"))
      .catch(() => alert("에러가 발생하였습니다"));
    setModifyPopup(false);
    setModify(false);
  };

  if (boardListLoading || !boardList) return <></>;
  return (
    <div>
      {deletePopup && (
        <Popup
          setPopup={setDeletePopup}
          fetch={() => deleteArticle(deletePopup)}
        />
      )}
      {modifyPopup && (
        <Popup
          text="수정하시겠습니까?"
          setPopup={setModifyPopup}
          fetch={() => changeCategory(modifyPopup)}
        />
      )}
      <Header />
      <div className="px-4 max-w-[1200px] m-auto mb-20">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <button
              className={cls(boardType === "board" ? "main" : "")}
              onClick={() => navigate("/board")}>
              게시판
            </button>
            <button
              className={cls(boardType === "qna" ? "main" : "")}
              onClick={() => navigate("/board?boardType=qna")}>
              Q&A
            </button>
          </div>
          <form>
            <label className="flex items-center space-x-2">
              <input
                type="text"
                className="shadow-custom rounded-full py-1 w-[275px] placeholder:text-sm px-4"
                placeholder="검색어를 입력하세요."
                value={search}
                onChange={(e) => setSearch(e.target.value)}></input>
              <button
                className="flex items-center justify-center rounded-full dark"
                style={{
                  width: "25px",
                  height: "35px",
                  minWidth: "0",
                  minHeight: "0",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  searchKeyword();
                }}>
                <i className="ii-search text-[#fff] text-base"></i>
              </button>
            </label>
          </form>
        </div>

        <div className="table">
          <div
            className="row header rounded-xl"
            style={{ background: "#F4F6F8", padding: "15px 0" }}>
            <div className="w-[15%] text-[#637381] ml-5">카테고리</div>
            <div className="w-[40%] text-[#637381]">제목</div>
            <div className="w-[15%] text-[#637381] justify-center">닉네임</div>
            <div className="w-[15%] text-[#637381]">등록일</div>
            <div className="w-[15%] text-[#637381] justify-center">관리</div>
          </div>
          {boardList.articleList?.map((article) => (
            <div
              className="row"
              key={article.id}
              style={{ padding: "10px 0", borderBottom: "none" }}>
              <div className="w-[15%] ml-5">
                {modify === article.id ? (
                  <select
                    className="shadow-custom"
                    value={cate}
                    onChange={(e) => setCate(e.target.value)}>
                    {cateList &&
                      cateList.categories?.map((cate) => (
                        <option value={cate?.id}>{cate?.title}</option>
                      ))}
                  </select>
                ) : (
                  <div>{article?.category?.title}</div>
                )}
              </div>

              <div className="w-[40%] truncate">{article?.title}</div>
              <div className="w-[15%] justify-center">
                {article?.user?.nickname}
              </div>
              <div className="w-[15%]">
                {article.createdAt
                  ? dayjs(article.createdAt).format("YYYY-MM-DD hh:mm")
                  : ""}
              </div>
              <div className="w-[15%] justify-center">
                {modify === article?.id ? (
                  <button
                    className="dark"
                    onClick={() => {
                      if (cate === article?.categoryId) {
                        setModify(false);
                        return;
                      }
                      setModifyPopup(article?.id);
                    }}>
                    완료
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setModify(article?.id);
                      setCate(article?.categoryId);
                    }}>
                    수정
                  </button>
                )}
                <button onClick={() => setDeletePopup(article?.id)}>
                  삭제
                </button>
              </div>
            </div>
          ))}
        </div>
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          pageCount={boardList.totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageClassName="page"
          activeClassName="active"
          onPageChange={onPageClick}
          forcePage={boardList.currentPage - 1}
          className="flex items-center justify-center space-x-2 pagination mt-14"></ReactPaginate>
      </div>
    </div>
  );
};

export default Board;
