import {TextSelection} from 'prosemirror-state'

/**
 * @type {import('prosemirror-state').Command}
 */
export const addCustomTemplate = (state, dispatch, view) => {
  if (!state.selection.empty) return false

  const customTemplateNode = state.schema.nodes.custom_template.create()
  const selectionHead = state.selection.$head
  const isInsideBlockquote =
    selectionHead.node(selectionHead.depth - 1).type.name ===
    state.schema.nodes.blockquote.name
  const isInEmptyParagraphNode =
    selectionHead.node(selectionHead.depth).content.size === 0

  // 인용문 안에 있을 경우 인용문 밖에 커스텀 템플릿 노드를 삽입합니다.
  if (isInsideBlockquote) {
    const insertAt = selectionHead.after(selectionHead.depth - 1)
    const tr = state.tr.insert(insertAt, customTemplateNode)
    const tr2 = tr.setSelection(TextSelection.create(tr.doc, insertAt + 1))

    dispatch(tr2)
    return true
  }

  // 커서가 빈 Paragraph 블록에 있을 경우 커스텀 템플릿 노드로 교체합니다.
  if (isInEmptyParagraphNode) {
    const tr = state.tr.setNodeMarkup(
      selectionHead.before(selectionHead.depth),
      state.schema.nodes.custom_template,
    )

    dispatch(tr)
    return true
  }

  // 다음 블럭에 커스텀 템플릿 노드를 삽입합니다.
  const posAfterCurNode = selectionHead.end(selectionHead.depth)
  const tr = state.tr.insert(posAfterCurNode, customTemplateNode)
  const tr2 = tr.setSelection(TextSelection.create(tr.doc, posAfterCurNode + 2))

  dispatch(tr2)
  return true
}

/**
 * @type {import('prosemirror-state').Command}
 */
export const removeCustomTemplate = (state, dispatch, view) => {
  if (!state.selection.empty) return false
  const selectionHead = state.selection.$head
  const isInsideCustomTemplate =
    selectionHead.node(selectionHead.depth).type.name ===
    state.schema.nodes.custom_template.name

  // 빈 커스텀 템플릿 노드에서 백스페이스를 누를 경우 본문 노드로 교체합니다.
  if (
    isInsideCustomTemplate &&
    selectionHead.node(selectionHead.depth).content.size === 0
  ) {
    const tr = state.tr.setNodeMarkup(
      selectionHead.before(selectionHead.depth),
      state.schema.nodes.paragraph,
    )

    dispatch(tr)
    return true
  }

  return false
}

/**
 * @type {import('prosemirror-state').Command}
 */
export const addDirectContactButton = (state, dispatch, view) => {
  const selectionHead = state.selection.$head
  const paragraphNode = state.schema.nodes.paragraph.create(
    null,
    state.schema.text('1:1 문의하기', [
      state.schema.marks.link.create({
        href: 'https://medistream.channel.io',
        target: '_blank',
        style:
          'display:inline-block;padding:10px 20px;border-radius:2px;background-color:#ffe66b;color:#3f1c1d;line-height:16px;font-size:14px;font-weight:bold;text-decoration:none;',
      }),
    ]),
  )

  dispatch(state.tr.insert(selectionHead.pos, paragraphNode))
  return true
}
