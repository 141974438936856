import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const underlineDom = ['u', {class: MEDISTREAM_SCHEMA_STYLE.marks.u}, 0]

/**
 * 밑줄은 <u> 태그로 표현됩니다.
 * 
 * @type {import("prosemirror-model").MarkSpec}
 */
export const underlineMarkSpec = {
  toDOM: () => underlineDom,
  parseDOM: [{tag: 'u'}, {style: 'text-decoration=underline'}],
}
