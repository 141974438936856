import React from "react";

const GameTable = ({ title, content }) => {
  return (
    <div className="flex w-1/2">
      <div className="w-1/4 bg-[#F3F3F3] flex flex-col">
        <div className="px-4 memberInfo-cell">{title}</div>
      </div>
      <div className="flex flex-col w-3/4">
        <div className="px-4 memberInfo-cell">{content}</div>
      </div>
    </div>
  );
};

export default GameTable;
