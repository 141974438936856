import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { useSeminarList } from "../../api/board";
import Header from "../../components/Header/Header";
import dayjs from "dayjs";
import { axiosClient } from "../../api/axiosClient";
import { useSWRConfig } from "swr";
import Popup from "../../components/Popup";
import { cls } from "../../util";

export default function Seminar() {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const location = useLocation();
  const urlParam = new URLSearchParams(location.search);
  const page = urlParam.get("page") || 1;
  const searchQuery = urlParam.get("searchQuery");
  const { mutate } = useSWRConfig();
  const [search, setSearch] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [recommendPopup, setRecommendPopup] = useState(false);

  const { data: seminarList, isLoading: seminarListLoading } = useSeminarList({
    page,
    searchQuery,
    isNotice: false,
    perPage: 16,
  });

  const recommendSeminar = ({ id, isRecommend }) => {
    axiosClient(token)
      .patch(`/admin/seminar/${id}`, { isRecommend })
      .then(() => alert("세미나 추천 정보가 변경되었습니다."))
      .then(() =>
        mutate({
          url: `/admin/seminar`,
          page,
          searchQuery,
          isNotice: false,
          perPage: 16,
        }).catch((err) => console.log(err))
      );
    setRecommendPopup(false);
  };

  const deleteSeminar = (id) => {
    axiosClient(token)
      .delete(`/admin/seminar/${id}`)
      .then(() => alert("삭제 되었습니다."))
      .then(() =>
        mutate({
          url: `/admin/seminar`,
          page,
          searchQuery,
          isNotice: false,
          perPage: 16,
        }).catch((err) => console.log(err))
      );
    setDeletePopup(false);
  };

  const searchKeyword = () => {
    urlParam.set("searchQuery", search);
    urlParam.set("page", 1);
    navigate(`/seminar?${urlParam}`);
  };

  const onPageClick = (selectedItem) => {
    const selected = selectedItem.selected + 1;
    urlParam.set("page", selected);
    navigate(`/seminar?${urlParam}`);
  };

  if (seminarListLoading || !seminarList) return <></>;
  return (
    <div>
      {deletePopup && (
        <Popup
          setPopup={setDeletePopup}
          fetch={() => deleteSeminar(deletePopup)}
        />
      )}
      {recommendPopup && (
        <Popup
          text="추천세미나로 등록하시겠습니까?"
          setPopup={setRecommendPopup}
          fetch={() => recommendSeminar(recommendPopup)}
        />
      )}
      <Header />
      <div className="px-4 max-w-[1200px] m-auto">
        <div className="flex items-center justify-end">
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="text"
                className="shadow-custom rounded-full py-1 w-[275px] placeholder:text-sm px-4"
                placeholder="검색어를 입력하세요."
                value={search}
                onChange={(e) => setSearch(e.target.value)}></input>
              <button
                className="flex items-center justify-center rounded-full dark"
                style={{
                  width: "25px",
                  height: "35px",
                  minWidth: "0",
                  minHeight: "0",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  searchKeyword();
                }}>
                <i className="ii-search text-[#fff] text-base"></i>
              </button>
            </label>
          </div>
        </div>

        <div className="table">
          <div
            className="row header rounded-xl"
            style={{ background: "#F4F6F8", padding: "15px 0" }}>
            <div className="w-[10%] text-[#637381] ml-5">카테고리</div>
            <div className="w-[40%] text-[#637381]">제목</div>
            <div className="w-[10%] text-[#637381] justify-center">닉네임</div>
            <div className="w-[10%] text-[#637381] justify-center">주최</div>
            <div className="w-[15%] text-[#637381] justify-center">등록일</div>
            <div className="w-[15%] text-[#637381] justify-center">관리</div>
          </div>
          {seminarList.articleList.map((item) => (
            <>
              <div
                className="row"
                key={item.id}
                style={{ padding: "10px 0", borderBottom: "none" }}>
                <div
                  className={cls(
                    item.isRecommend ? "text-main" : "",
                    "w-[10%] ml-5"
                  )}>
                  {item.category.title}
                </div>

                <div
                  className={cls(
                    item.isRecommend ? "text-main" : "",
                    "w-[40%] truncate"
                  )}>
                  {item.title}
                </div>
                <div
                  className={cls(
                    item.isRecommend ? "text-main" : "",
                    "w-[10%] justify-center"
                  )}>
                  {item.user.nickname}
                </div>
                <div
                  className={cls(
                    item.isRecommend ? "text-main" : "",
                    "w-[10%] justify-center"
                  )}>
                  {item.seminarHost}
                </div>
                <div
                  className={cls(
                    item.isRecommend ? "text-main" : "",
                    "w-[15%] justify-center"
                  )}>
                  {dayjs(item.createdAt).format("YYYY-MM-DD hh:mm")}
                </div>
                <div
                  className={cls(
                    item.isRecommend ? "text-main" : "",
                    "w-[15%] justify-center"
                  )}>
                  <button
                    onClick={() =>
                      setRecommendPopup({
                        id: item.id,
                        isRecommend: !item.isRecommend,
                      })
                    }>
                    {!item.isRecommend ? "추천" : "해제"}
                  </button>
                  <button onClick={() => setDeletePopup(item.id)}>삭제</button>
                </div>
              </div>
            </>
          ))}
        </div>
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          pageCount={seminarList.totalPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          pageClassName="page"
          activeClassName="active"
          onPageChange={onPageClick}
          forcePage={seminarList.currentPage - 1}
          className="flex items-center justify-center space-x-2 pagination mt-14"></ReactPaginate>
      </div>
    </div>
  );
}
