import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { SWRConfig } from "swr";
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import "dayjs/locale/ko";
import reportWebVitals from "./reportWebVitals";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "inticons/fonts/inticons.bundle.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

dayjs.extend(isLeapYear);
dayjs.locale("ko");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <SWRConfig
          value={{
            fetcher: (url) => fetch(url).then((response) => response.json()),
          }}
        >
          <App />
        </SWRConfig>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
