import { findParentDomRefOfType } from "prosemirror-utils";

import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";
import { linkTooltip } from "../plugins/linkToolTip";
import { remoteMenu } from "../plugins/remoteMenu";
import {
  alignCenterSVG,
  alignLeftSVG,
  alignRightSVG,
  deleteSVG,
  linkSVG,
  originalSizeSVG,
} from "../assets/icons";

/**
 * 참고: https://prosemirror.net/docs/ref/#view.NodeView
 *      https://discuss.prosemirror.net/t/image-resize/1489/6
 *
 */
class ImageNodeView {
  /**
   *
   * @param {import('prosemirror-model').Node} node
   * @param {import('prosemirror-view').EditorView} view
   * @param {() => number} getPos
   */
  constructor(node, view, getPos) {
    const outer = document.createElement("span");
    outer.style.position = "relative";
    outer.style.width = node.attrs.width;
    outer.style.display = "inline-block";
    outer.style.cursor = "pointer";
    outer.style.lineHeight = "0"; // necessary so the bottom right arrow is aligned nicely

    const img = document.createElement("img");
    img.setAttribute("src", node.attrs.src);
    img.classList.add(MEDISTREAM_SCHEMA_STYLE.nodes.image);
    img.classList.add(MEDISTREAM_SCHEMA_STYLE.etc.selectable);
    img.style.width = "100%";

    const resizeHandleWrapper = document.createElement("div");
    resizeHandleWrapper.className =
      MEDISTREAM_SCHEMA_STYLE.etc.imageResizeHandleWrapper;

    const resizeHandle = document.createElement("span");
    resizeHandle.className = MEDISTREAM_SCHEMA_STYLE.etc.imageResizeHandle;

    const menuWrapper = document.createElement("div");
    menuWrapper.className = MEDISTREAM_SCHEMA_STYLE.etc.imageMenuWrapper;

    const originalSizeButton = document.createElement("button");
    originalSizeButton.innerHTML = originalSizeSVG;
    originalSizeButton.className = MEDISTREAM_SCHEMA_STYLE.etc.imageMenuButton;

    const deleteButton = document.createElement("button");
    deleteButton.innerHTML = deleteSVG;
    deleteButton.className = MEDISTREAM_SCHEMA_STYLE.etc.imageMenuButton;

    const linkButton = document.createElement("button");
    linkButton.innerHTML = linkSVG;
    linkButton.className = MEDISTREAM_SCHEMA_STYLE.etc.imageMenuButton;

    const alignButton = document.createElement("button");
    alignButton.innerHTML = alignLeftSVG;
    alignButton.className = MEDISTREAM_SCHEMA_STYLE.etc.imageMenuButton;

    const alignWrapper = document.createElement("div");
    alignWrapper.className = MEDISTREAM_SCHEMA_STYLE.etc.imageAlignWrapper;

    const alignLeftButton = document.createElement("button");
    alignLeftButton.innerHTML = alignLeftSVG;
    alignLeftButton.className = MEDISTREAM_SCHEMA_STYLE.etc.imageAlignButton;

    const alignCenterButton = document.createElement("button");
    alignCenterButton.innerHTML = alignCenterSVG;
    alignCenterButton.className = MEDISTREAM_SCHEMA_STYLE.etc.imageAlignButton;

    const alignRightButton = document.createElement("button");
    alignRightButton.innerHTML = alignRightSVG;
    alignRightButton.className = MEDISTREAM_SCHEMA_STYLE.etc.imageAlignButton;

    resizeHandleWrapper.onmousedown = this._resizeHandleMouseDown.bind(this);
    resizeHandleWrapper.onpointerdown = this._resizeHandleMouseDown.bind(this);
    resizeHandle.onmousedown = this._resizeHandleMouseDown.bind(this);
    resizeHandle.onpointerdown = this._resizeHandleMouseDown.bind(this);
    originalSizeButton.onclick = this._resetImage.bind(this);
    deleteButton.onclick = this._deleteImage.bind(this);
    linkButton.onclick = this._linkImage.bind(this);
    alignButton.onclick = this._openAlignMenu.bind(this);

    outer.appendChild(resizeHandleWrapper);
    outer.appendChild(resizeHandle);
    outer.appendChild(menuWrapper);
    outer.appendChild(img);

    menuWrapper.appendChild(originalSizeButton);
    menuWrapper.appendChild(deleteButton);
    menuWrapper.appendChild(linkButton);
    menuWrapper.appendChild(alignButton);

    menuWrapper.appendChild(alignWrapper);

    alignWrapper.appendChild(alignLeftButton);
    alignWrapper.appendChild(alignCenterButton);
    alignWrapper.appendChild(alignRightButton);

    this.dom = outer;
    this.node = node;
    this.view = view;
    this.getPos = getPos;
    this.image = img;
    this.resizeHandleWrapper = resizeHandleWrapper;
    this.resizeHandle = resizeHandle;
    this.originalSizeButton = originalSizeButton;
    this.deleteButton = deleteButton;
    this.linkButton = linkButton;
    this.alignButton = alignButton;
    this.alignLeftButton = alignLeftButton;
    this.alignCenterButton = alignCenterButton;
    this.alignRightButton = alignRightButton;

    alignLeftButton.addEventListener(
      "mousedown",
      this._alignImageLeft.bind(this)
    );
    alignCenterButton.addEventListener(
      "mousedown",
      this._alignImageCenter.bind(this)
    );
    alignRightButton.addEventListener(
      "mousedown",
      this._alignImageRight.bind(this)
    );
    document.addEventListener("mousedown", this._hideAlignButtons.bind(this));
  }

  selectNode() {
    this.image.classList.add("ProseMirror-selectednode");

    this.resizeHandleWrapper.style.display = "inline";
    this.resizeHandle.style.display = "inline";
    this.originalSizeButton.style.display =
      this.image.clientWidth > 100 ? "inline" : "none";
    this.deleteButton.style.display =
      this.image.clientWidth > 150 ? "inline" : "none";
    this.linkButton.style.display =
      this.image.clientWidth > 150 ? "inline" : "none";
    this.alignButton.style.display =
      this.image.clientWidth > 150 ? "inline" : "none";
  }

  deselectNode() {
    this.image.classList.remove("ProseMirror-selectednode");

    this.resizeHandleWrapper.style.display = "none";
    this.resizeHandle.style.display = "none";
    this.originalSizeButton.style.display = "none";
    this.deleteButton.style.display = "none";
    this.linkButton.style.display = "none";
    this.alignButton.style.display = "none";
    this._hideAlignButtons();
  }

  /**
   * imageResizeHandle 을 드래그하여 이미지의 크기를 조절합니다.
   * @param {MouseEvent} event
   */
  _resizeHandleMouseDown(event) {
    event.preventDefault();

    // 최대 넓이를 계산하기 위해 부모 노드인 Paragraph/Heading 노드를 찾습니다.
    // 이미지 노드는 인라인 노드이므로 부모 노드는 언제나 Paragraph/Heading 노드입니다.
    const domAtPos = this.view.domAtPos.bind(this.view);
    const parentNode = findParentDomRefOfType(
      [
        this.view.state.schema.nodes["paragraph"],
        this.view.state.schema.nodes["heading"],
      ],
      domAtPos
    )(this.view.state.selection);

    const startX = event.pageX;
    const initialImageWidth = this.dom.offsetWidth;
    const maxImageWidth = parentNode.offsetWidth;
    const MIN_IMAGE_PERCENT = 15;

    const onMouseMove = (event) => {
      const currentX = event.pageX;
      const delta = currentX - startX;
      const newWidth = initialImageWidth + delta;
      const percent = (newWidth / maxImageWidth) * 100;

      if (percent > 23 && percent < 27) {
        this.dom.style.width = "25%";
        return;
      }
      if (percent > 48 && percent < 52) {
        this.dom.style.width = "50%";
        return;
      }
      if (percent > 73 && percent < 77) {
        this.dom.style.width = "75%";
        return;
      }
      if (newWidth >= maxImageWidth) {
        this.dom.style.width = "100%";
        return;
      }
      if (percent <= MIN_IMAGE_PERCENT) {
        this.dom.style.width = `15%`;
        return;
      }

      this.dom.style.width = `${percent}%`;
    };

    const onMouseUp = (event) => {
      event.preventDefault();

      const transaction = this.view.state.tr.setNodeMarkup(
        this.getPos(),
        null,
        {
          src: this.node.attrs.src,
          width: this.dom.style.width,
        }
      );

      this.view.dispatch(transaction);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("pointermove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("pointerup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("pointermove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("pointerup", onMouseUp);
  }

  /**
   * originalSizeButton 을 클릭하면 이미지의 넓이를 원래 넓이로 되돌립니다.
   */
  _resetImage() {
    this.dom.style.width = "";
    const transaction = this.view.state.tr.setNodeAttribute(
      this.getPos(),
      "width",
      null
    );
    this.view.dispatch(transaction);
  }

  _deleteImage(event) {
    event.preventDefault();

    const { $from, $to } = this.view.state.selection;
    this.view.dispatch(this.view.state.tr.delete($from.pos, $to.pos));
    this.view.updateState(this.view.state);
  }

  _linkImage() {
    const tr = this.view.state.tr.setMeta(linkTooltip, "open");
    this.view.dispatch(tr);
  }

  _openAlignMenu() {
    if (this.alignButton.style.display === "inline") {
      this._showAlignButtons();
    } else {
      this._hideAlignButtons();
    }
  }

  _showAlignButtons() {
    this.alignLeftButton.style.display = "inline";
    this.alignCenterButton.style.display = "inline";
    this.alignRightButton.style.display = "inline";
  }

  _hideAlignButtons(event) {
    if (event) {
      event.stopPropagation();
    }
    this.alignLeftButton.style.display = "none";
    this.alignCenterButton.style.display = "none";
    this.alignRightButton.style.display = "none";
  }

  _alignImageLeft(event) {
    event.preventDefault();
    this.view.dispatch(this.view.state.tr.setMeta(remoteMenu, "alignLeft"));
  }

  _alignImageCenter(event) {
    event.preventDefault();
    this.view.dispatch(this.view.state.tr.setMeta(remoteMenu, "alignCenter"));
  }

  _alignImageRight(event) {
    event.preventDefault();
    this.view.dispatch(this.view.state.tr.setMeta(remoteMenu, "alignRight"));
  }
}

export const imageNodeView = (node, view, getPos) =>
  new ImageNodeView(node, view, getPos);
