import {iframeLinkInputPlugin} from '../plugins/iframe'

/**
 * iframe 링크 입력창을 표시합니다.
 *
 * @type {import('prosemirror-state').Command}
 */
export const displayIframeLinkInput = (state, dispatch, view) => {
  if (dispatch) {
    const tr = state.tr.setMeta(iframeLinkInputPlugin, 'open')
    dispatch(tr)
    return true
  }
}
