/**
 * 메뉴바의 아이콘을 정의합니다. (24px x 24px)
 * https://fonts.google.com/icons -> Fill: 0, Weight: 300, Grade: 0, Optical Size: 24
 */
export const BoldIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M354.308 840.999q-25.307 0-43.038-17.73-17.73-17.731-17.73-43.038V371.769q0-25.307 17.73-43.038 17.731-17.73 43.038-17.73H488q61.153 0 110.576 38.077 49.423 38.077 49.423 102.538 0 44.845-21.654 73.115-21.654 28.269-46.654 41.038 30.769 10.616 58.769 41.962 28 31.346 28 84.423 0 76.691-56.538 112.768T496 840.999H354.308Zm25.229-79.69h113.232q47.231 0 66.77-26.231 19.539-26.231 19.539-50.309 0-24.078-19.539-50.308Q540 608.23 490.923 608.23H379.537v153.079Zm0-230.308h103.77q36.462 0 57.809-20.846 21.347-20.847 21.347-49.924 0-30.924-22.578-50.54-22.577-19.615-55.347-19.615H379.537v140.925Z"/></svg>`;

export const ItalicsIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M251.923 840.999q-15.184 0-25.668-10.494-10.485-10.494-10.485-25.691 0-15.198 10.485-25.66 10.484-10.461 25.668-10.461h116.539l129.616-385.386H381.539q-15.184 0-25.668-10.494-10.485-10.494-10.485-25.692 0-15.197 10.485-25.659 10.484-10.461 25.668-10.461h293.846q15.184 0 25.669 10.494 10.484 10.494 10.484 25.691 0 15.198-10.484 25.66-10.485 10.461-25.669 10.461H571.153L441.537 768.693h104.232q15.184 0 25.668 10.494 10.485 10.494 10.485 25.692 0 15.197-10.485 25.659-10.484 10.461-25.668 10.461H251.923Z"/></svg>`;

export const UnderlineIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M243.846 900.997q-12.749 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.625-8.615 21.374-8.615h472.308q12.749 0 21.374 8.629 8.625 8.628 8.625 21.384 0 12.755-8.625 21.37t-21.374 8.615H243.846ZM480 757.152q-93.307 0-145.653-56.653-52.346-56.653-52.346-151.806V269.308q0-15.14 11.172-25.954 11.171-10.814 26.306-10.814 15.136 0 25.905 10.814 10.769 10.814 10.769 25.954v283.077q0 60.615 32.231 97.154 32.231 36.539 91.616 36.539t91.616-36.539q32.231-36.539 32.231-97.154V269.308q0-15.14 11.172-25.954 11.171-10.814 26.307-10.814 15.135 0 25.904 10.814t10.769 25.954v279.385q0 95.153-52.346 151.806Q573.307 757.152 480 757.152Z"/></svg>`;

export const StrikeIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M486 883.691q-62.307 0-111.615-30.769-49.307-30.769-79-86.692-6.307-12.384-1.23-26.384 5.076-13.999 19.307-19.691 12.384-5.692 25.268-.616 12.885 5.077 20.807 18.077 19.539 33.846 51.539 55.039 32 21.192 76.155 21.192 46.615 0 86-23.462 39.385-23.461 39.385-74.385 0-22.616-7.192-39.154-7.192-16.539-20.346-30.847h81.998q7.308 13.231 11.346 30.616Q682.461 694 682.461 716q0 80.23-58.423 123.961-58.423 43.73-138.038 43.73ZM120 565.999q-12.769 0-21.384-8.615T90 536q0-12.769 8.615-21.384T120 506.001h720q12.769 0 21.384 8.615T869.999 536q0 12.769-8.615 21.384T840 565.999H120Zm181.539-139.998q-2.231-65.154 47.577-113.654 49.807-48.5 132.884-48.5 50.923 0 90.076 19.769 39.154 19.769 67 60.923 8.308 12.384 3.423 26.384-4.884 13.999-19.499 20.692-11.154 5.076-23.846 1.115-12.692-3.961-22.461-15.961-14.538-18.077-38-30.577-23.462-12.501-55.462-12.501-51.386 0-82.232 26.386-30.847 26.385-29.615 65.924h-69.845Z"/></svg>`;

export const TextColorIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M139.999 1016q-24.538 0-42.269-17.541Q80 980.921 80 955.999q0-24.538 17.73-42.269Q115.461 896 140 896h680.002q24.538 0 42.269 17.538Q880 931.076 880 955.999q0 24.537-17.73 42.268Q844.539 1016 820.001 1016H139.999Zm142.924-250.001q-19.307 0-28.845-12.999-9.539-13-2.231-32.076l181.539-463.155q5.077-13.23 18.73-22.499 13.654-9.269 28.499-9.269 13.846 0 27.384 9.269t18.615 22.499L707.769 719.77q7.692 19.076-2.231 32.653-9.923 13.576-30.23 13.576-9.308 0-17.923-5.884-8.615-5.885-11.846-14.577L599.385 621.23H360.307l-47.385 124.693q-3.231 8.307-11.653 14.192-8.423 5.884-18.346 5.884Zm98.461-203.998h195.694L482 309.999h-4.769l-95.847 252.002Z"/></svg>`;

export const TextHighlightIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="m158.848 812.538 113.691-113.691-4.308-4.308q-21.692-21.692-21.692-50.615 0-28.923 21.692-50.615l371-371q21.692-21.692 50.615-21.692 28.923 0 50.615 21.692l87.075 87.076q21.693 21.692 21.693 50.615 0 28.923-21.693 50.615l-370.999 371q-21.692 21.692-50.615 21.692-28.923 0-50.615-21.692l-5.077-5.078-36.001 36.001H158.848Zm406.613-328.153ZM616.846 537l-104-104-201.847 201.846q-3.462 3.462-3.462 8.463 0 5 3.462 8.462l87.076 87.075q3.462 3.462 8.462 3.462t8.462-3.462L616.846 537ZM80 1056V936.003h800V1056H80Z"/></svg>`;

export const TextFormatIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M339.955 878.306q-17.647 0-29.954-12.339-12.307-12.339-12.307-29.967V358.306H140q-17.628 0-29.967-12.352-12.34-12.353-12.34-29.999 0-17.647 12.34-29.954 12.339-12.307 29.967-12.307h400q17.628 0 29.967 12.352 12.339 12.353 12.339 29.999 0 17.647-12.339 29.954-12.339 12.307-29.967 12.307H382.306V836q0 17.628-12.352 29.967-12.353 12.339-29.999 12.339Zm360 0q-17.647 0-29.954-12.339-12.307-12.339-12.307-29.967V558.306H580q-17.628 0-29.967-12.352-12.339-12.353-12.339-29.999 0-17.647 12.339-29.954 12.339-12.307 29.967-12.307h240q17.628 0 29.967 12.352 12.339 12.353 12.339 29.999 0 17.647-12.339 29.954-12.339 12.307-29.967 12.307h-77.694V836q0 17.628-12.352 29.967-12.353 12.339-29.999 12.339Z"/></svg>`;

export const QuoteIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="m628.079 765.999 76.538-160H530.001V346.001h259.998v259.998l-76.153 160h-85.767Zm-360 0 76.538-160H170.001V346.001h259.998v259.998l-76.153 160h-85.767Z"/></svg>`;

export const AlignLeftIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M160 765.999q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0-320q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0 160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0 320q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0-640q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Z"/></svg>`;

export const AlignCenterIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M160 925.999q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h320q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H320Zm-160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h320q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H320Zm-160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Z"/></svg>`;

export const AlignRightIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M160 925.999q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H400Zm-240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H400Zm-240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Z"/></svg>`;

export const LineIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M210.001 605.999q-12.75 0-21.375-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.625-8.615 21.375-8.615h539.998q12.75 0 21.375 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.625 8.615-21.375 8.615H210.001Z"/></svg>`;

export const LinkIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M275.769 757.537q-75.338 0-128.438-53.093-53.1-53.093-53.1-128.422t53.1-128.444q53.1-53.115 128.438-53.115h122.308q12.749 0 21.374 8.629 8.625 8.628 8.625 21.384 0 12.755-8.625 21.37t-21.374 8.615H275.769q-50.385 0-85.962 35.577Q154.23 525.615 154.23 576q0 50.385 35.577 85.962 35.577 35.577 85.962 35.577h122.308q12.749 0 21.374 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.625 8.615-21.374 8.615H275.769Zm80-151.538q-12.75 0-21.375-8.628-8.624-8.629-8.624-21.384 0-12.756 8.624-21.371 8.625-8.615 21.375-8.615h240q12.75 0 21.374 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.624 8.615-21.374 8.615h-240ZM857.306 576h-59.999q0-50.385-35.576-85.962-35.577-35.577-85.962-35.577H553.461q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h122.308q75.338 0 128.437 53.099 53.1 53.1 53.1 128.438ZM715.755 885.999q-12.755 0-21.37-8.624-8.615-8.625-8.615-21.375v-90.001h-90.001q-12.75 0-21.375-8.628-8.624-8.629-8.624-21.384 0-12.756 8.624-21.371 8.625-8.615 21.375-8.615h90.001V616q0-12.75 8.628-21.375 8.629-8.624 21.384-8.624 12.756 0 21.371 8.624 8.615 8.625 8.615 21.375v90.001h90.001q12.75 0 21.374 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.624 8.615-21.374 8.615h-90.001V856q0 12.75-8.628 21.375-8.629 8.624-21.385 8.624Z"/></svg>`;

export const ImageIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M306.925 765.999h349.227q11.615 0 16.846-10.23 5.231-10.231-2-19.462l-94.999-126.614q-5.615-7.23-14.653-7.23-9.039 0-14.654 7.23l-97.461 127.076-65.153-83.614q-5.616-7.231-14.654-7.038-9.039.192-14.654 7.423l-62.306 82.767q-7.231 9.231-2 19.462 5.23 10.23 16.461 10.23Zm-94.616 150q-30.308 0-51.308-21t-21-51.308V308.309q0-30.308 21-51.308t51.308-21h535.382q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463V308.309q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846ZM200 296V856 296Z"/></svg>`;

export const VideoIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M181.925 875.999q-30.308 0-51.308-21-20.999-21-20.999-51.308V348.309q0-30.308 20.999-51.308 21-21 51.308-21h455.382q30.308 0 51.308 21t21 51.308v183.077l109.46-109.461q9.231-9.23 20.269-4.423 11.038 4.808 11.038 17.654v281.688q0 12.846-11.038 17.654-11.038 4.807-20.269-4.423l-109.46-109.461v183.077q0 30.308-21 51.308t-51.308 21H181.925Zm0-59.999h455.382q5.386 0 8.847-3.462 3.462-3.462 3.462-8.847V348.309q0-5.385-3.462-8.847-3.461-3.462-8.847-3.462H181.925q-5.385 0-8.847 3.462-3.462 3.462-3.462 8.847v455.382q0 5.385 3.462 8.847Q176.54 816 181.925 816Zm-12.309 0V336v480Z"/></svg>`;

export const FileIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M450.001 653.997v147.387q0 12.768 8.615 21.384 8.615 8.615 21.384 8.615t21.384-8.615q8.615-8.616 8.615-21.384V653.997l52.925 52.925q4.461 4.461 10.038 6.692t11.153 1.923q5.577-.308 11.039-2.538 5.461-2.231 9.922-6.693 8.693-9.307 9-21.076.308-11.769-9-21.076l-99.769-99.769q-5.615-5.616-11.846-7.923-6.23-2.308-13.461-2.308t-13.461 2.308q-6.231 2.307-11.846 7.923l-99.769 99.769q-8.923 8.922-8.808 20.884.115 11.961 9.423 21.268 9.307 8.693 21.076 9 11.769.308 21.077-9l52.309-52.309ZM252.309 955.999q-30.308 0-51.308-21t-21-51.308V268.309q0-30.308 21-51.308t51.308-21h287.769q14.461 0 27.807 5.616 13.346 5.615 23.193 15.461l167.844 167.844q9.846 9.847 15.461 23.193 5.616 13.346 5.616 27.807v447.769q0 30.308-21 51.308t-51.308 21H252.309Zm287.692-556.154V256H252.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v615.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h455.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463V435.999H576.155q-15.461 0-25.807-10.347-10.347-10.346-10.347-25.807ZM240 256v179.999V256v640V256Z"/></svg>`;

export const TableIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M787.691 915.999H212.309q-30.308 0-51.308-21t-21-51.308V308.309q0-30.308 21-51.308t51.308-21h575.382q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21ZM200 426.001h600V308.309q0-4.616-3.846-8.463-3.847-3.846-8.463-3.846H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v117.692Zm127.693 59.998H200v357.692q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h115.384V485.999Zm344.614 0V856h115.384q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463V485.999H672.307Zm-59.998 0H387.691V856h224.618V485.999Z"/></svg>`;

export const FootnoteIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10.9424 19.5577V6.55765H5.44235V4.44235H18.5577V6.55765H13.0577V19.5577H10.9424Z" fill="black"/>
    <path d="M21 2V8H20.1392V3.05762H20.1114L19 3.9541V2.95801L20.181 2H21Z" fill="black"/>
  </svg>
`;

export const CustomTemplateIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M11.375 21v-1.5H18.7q.125 0 .213-.087.087-.088.087-.213v-7.225q0-1.45-.55-2.725-.55-1.275-1.5-2.225-.95-.95-2.225-1.5-1.275-.55-2.725-.55-1.45 0-2.725.55-1.275.55-2.225 1.5-.95.95-1.5 2.225Q5 10.525 5 11.975v6.075h-.75q-.725 0-1.237-.512Q2.5 17.025 2.5 16.3v-2q0-.5.275-.913.275-.412.725-.687l.05-1.3q.125-1.725.863-3.175.737-1.45 1.887-2.488Q7.45 4.7 8.912 4.125 10.375 3.55 12 3.55t3.1.575q1.475.575 2.612 1.612 1.138 1.038 1.876 2.475.737 1.438.862 3.163l.05 1.3q.45.225.725.612.275.388.275.863v2.3q0 .45-.275.838-.275.387-.725.612v1.3q0 .75-.525 1.275Q19.45 21 18.7 21ZM9.2 14.075q-.375 0-.638-.263Q8.3 13.55 8.3 13.2q0-.375.262-.638.263-.262.638-.262.35 0 .612.262.263.263.263.638 0 .35-.263.612-.262.263-.612.263Zm5.6 0q-.35 0-.612-.263-.263-.262-.263-.612 0-.375.263-.638.262-.262.612-.262.375 0 .638.262.262.263.262.638 0 .35-.262.612-.263.263-.638.263ZM6.375 12.45q-.1-1.375.388-2.512Q7.25 8.8 8.062 8.012q.813-.787 1.863-1.212 1.05-.425 2.125-.425 2.15 0 3.712 1.35 1.563 1.35 1.888 3.375-2.2-.025-4-1.188-1.8-1.162-2.7-3.012-.4 1.9-1.612 3.375-1.213 1.475-2.963 2.175Z"/></svg>`;

export const InlineStyleIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" focusable="false">
<path d="M11.88,15h.7l.7-1.7-3-8.3h-2l-5,14h2l1.4-4Zm-4.4-2,1.9-5.2,1.9,5.2ZM15.4,21.545l3.246,1.949-.909-3.637L20.72,17H16.954l-1.429-3.506L13.837,17H10.071l2.857,2.857-.779,3.637Z"></path>
</svg>`;
export const PollIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M207.693-95.386q-30.308 0-51.307-21-21-20.999-21-51.307v-155.075l119.231-135.001L297.385-415l-97.693 110.386h560.616L664.615-413l42.768-42.769 117.231 133.001v155.075q0 30.308-21 51.307-20.999 21-51.307 21H207.693Zm0-59.998h544.614q5 0 8.655-3.654 3.654-3.655 3.654-8.655v-76.923H195.384v76.923q0 5 3.654 8.655 3.655 3.654 8.655 3.654Zm222.692-238.847-130.23-131q-21.077-21.076-20.577-51.115.5-30.038 21.577-51.115L486.77-813.075q21.076-21.077 51.23-21.692 30.154-.616 51.23 20.461l130.615 130.615q21.077 21.076 21.692 50.615.615 29.538-20.461 50.615L532.23-393.616q-21.076 21.077-50.922 20.769-29.846-.308-50.923-21.384Zm246.922-229.383q3.078-3.077 3.078-8.847t-3.078-8.847L547.462-771.153q-3.077-3.077-8.847-3.077-5.769 0-8.846 3.077L342.923-584.308q-3.077 3.077-3.077 8.847t3.077 8.847l129.846 129.845q3.077 3.077 8.846 3.077 5.77 0 8.847-3.077l186.845-186.845Zm-481.923 468.23v-89.232 89.232Z"/></svg>`;

export const LoadingIcon = `
  <svg class="lds-spinner" width="50px"  height="50px"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;"><g transform="rotate(0 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(30 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(60 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(90 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(120 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(150 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(180 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(210 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(240 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(270 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(300 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
  </rect>
  </g><g transform="rotate(330 50 50)">
  <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12" fill="#6B6D73">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
  </g></svg>
`;

export const FontSizeIcon = `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m80-280 162-400h63l161 400h-63l-38-99H181l-38 99H80Zm121-151h144l-70-185h-4l-70 185Zm347 151v-62l233-286H566v-52h272v63L607-332h233v52H548ZM384-784l96-96 96 96H384Zm96 704-96-96h192l-96 96Z"/></svg>`;

/**
 * Image NodeView 의 아이콘을 정의합니다.
 */
export const originalSizeSVG =
  '<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill="white"><path d="M480 966q-70 0-131.133-26.6-61.134-26.6-106.4-71.867-45.267-45.266-71.867-106.4Q144 700 144 630h72q0 109 77.5 186.5T480 894q109 0 186.5-77.5T744 630q0-109-77.5-186.5T479.61 366H474l51 51-51 51-138-138 138-138 51 51-52 51h7q70 0 131.133 26.6 61.134 26.6 106.4 71.867 45.267 45.266 71.867 106.4Q816 560 816 630t-26.6 131.133q-26.6 61.134-71.867 106.4-45.266 45.267-106.4 71.867Q550 966 480 966Z"/></svg>';

export const deleteSVG =
  '<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill="white"><path d="M312 912q-29.7 0-50.85-21.15Q240 869.7 240 840V360h-48v-72h192v-48h192v48h192v72h-48v479.566Q720 870 698.85 891 677.7 912 648 912H312Zm336-552H312v480h336V360ZM384 768h72V432h-72v336Zm120 0h72V432h-72v336ZM312 360v480-480Z"/></svg>';

export const linkSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill="white"><path d="M275.769 757.537q-75.338 0-128.438-53.093-53.1-53.093-53.1-128.422t53.1-128.444q53.1-53.115 128.438-53.115h122.308q12.749 0 21.374 8.629 8.625 8.628 8.625 21.384 0 12.755-8.625 21.37t-21.374 8.615H275.769q-50.385 0-85.962 35.577Q154.23 525.615 154.23 576q0 50.385 35.577 85.962 35.577 35.577 85.962 35.577h122.308q12.749 0 21.374 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.625 8.615-21.374 8.615H275.769Zm80-151.538q-12.75 0-21.375-8.628-8.624-8.629-8.624-21.384 0-12.756 8.624-21.371 8.625-8.615 21.375-8.615h240q12.75 0 21.374 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.624 8.615-21.374 8.615h-240ZM857.306 576h-59.999q0-50.385-35.576-85.962-35.577-35.577-85.962-35.577H553.461q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h122.308q75.338 0 128.437 53.099 53.1 53.1 53.1 128.438ZM715.755 885.999q-12.755 0-21.37-8.624-8.615-8.625-8.615-21.375v-90.001h-90.001q-12.75 0-21.375-8.628-8.624-8.629-8.624-21.384 0-12.756 8.624-21.371 8.625-8.615 21.375-8.615h90.001V616q0-12.75 8.628-21.375 8.629-8.624 21.384-8.624 12.756 0 21.371 8.624 8.615 8.625 8.615 21.375v90.001h90.001q12.75 0 21.374 8.628 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.624 8.615-21.374 8.615h-90.001V856q0 12.75-8.628 21.375-8.629 8.624-21.385 8.624Z"/></svg>`;

export const alignLeftSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill="white"><path d="M160 765.999q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0-320q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0 160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0 320q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm0-640q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Z"/></svg>`;

export const alignCenterSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill="white"><path d="M160 925.999q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h320q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H320Zm-160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h320q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H320Zm-160-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Z"/></svg>`;

export const alignRightSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill="white"><path d="M160 925.999q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H400Zm-240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Zm240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h400q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H400Zm-240-160q-12.75 0-21.374-8.628-8.625-8.629-8.625-21.384 0-12.756 8.625-21.371 8.624-8.615 21.374-8.615h640q12.75 0 21.375 8.628 8.624 8.629 8.624 21.384 0 12.756-8.624 21.371-8.625 8.615-21.375 8.615H160Z"/></svg>`;

/**
 * Custom template NodeView 의 아이콘을 정의합니다.
 */
export const cartSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.75H4.04195L4.42498 5.32681L6.36967 15.3338C6.52949 16.1562 7.24975 16.75 8.08753 16.75H20.9474V15.25H8.08753C7.96785 15.25 7.86496 15.1652 7.84212 15.0477L7.54136 13.5H19.3684H19.902L20.077 12.9959L22.6625 5.54533C22.8541 4.99307 22.444 4.41667 21.8594 4.41667H18.5V5.91667H20.9459L18.8348 12H7.24986L6.06767 5.91667H9.5V4.41667H5.74752L5.36038 2.82296L5.2212 2.25H4.63158H2V3.75ZM8.78571 20C8.78571 20.1803 8.59705 20.5 8.14286 20.5C7.68866 20.5 7.5 20.1803 7.5 20C7.5 19.8197 7.68866 19.5 8.14286 19.5C8.59705 19.5 8.78571 19.8197 8.78571 20ZM19.5 20C19.5 20.1803 19.3113 20.5 18.8571 20.5C18.4029 20.5 18.2143 20.1803 18.2143 20C18.2143 19.8197 18.4029 19.5 18.8571 19.5C19.3113 19.5 19.5 19.8197 19.5 20ZM10.2857 20C10.2857 21.1046 9.32632 22 8.14286 22C6.95939 22 6 21.1046 6 20C6 18.8954 6.95939 18 8.14286 18C9.32632 18 10.2857 18.8954 10.2857 20ZM21 20C21 21.1046 20.0406 22 18.8571 22C17.6737 22 16.7143 21.1046 16.7143 20C16.7143 18.8954 17.6737 18 18.8571 18C20.0406 18 21 18.8954 21 20Z" fill="#5CA6FF"/>
    <path d="M14 3V11M18 7H10" stroke="#5CA6FF" stroke-width="1.5"/>
  </svg>`;

export const productSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.75H4.04195L4.42498 5.32681L6.36967 15.3338C6.52949 16.1562 7.24975 16.75 8.08753 16.75H20.9474V15.25H8.08753C7.96785 15.25 7.86496 15.1652 7.84212 15.0477L7.54136 13.5H19.3684H19.902L20.077 12.9959L22.6625 5.54533C22.8541 4.99307 22.444 4.41667 21.8594 4.41667H5.74752L5.36038 2.82296L5.2212 2.25H4.63158H2V3.75ZM7.24986 12L6.06767 5.91667H20.9459L18.8348 12H7.24986ZM8.78571 20C8.78571 20.1803 8.59705 20.5 8.14286 20.5C7.68866 20.5 7.5 20.1803 7.5 20C7.5 19.8197 7.68866 19.5 8.14286 19.5C8.59705 19.5 8.78571 19.8197 8.78571 20ZM19.5 20C19.5 20.1803 19.3113 20.5 18.8571 20.5C18.4029 20.5 18.2143 20.1803 18.2143 20C18.2143 19.8197 18.4029 19.5 18.8571 19.5C19.3113 19.5 19.5 19.8197 19.5 20ZM10.2857 20C10.2857 21.1046 9.32632 22 8.14286 22C6.95939 22 6 21.1046 6 20C6 18.8954 6.95939 18 8.14286 18C9.32632 18 10.2857 18.8954 10.2857 20ZM21 20C21 21.1046 20.0406 22 18.8571 22C17.6737 22 16.7143 21.1046 16.7143 20C16.7143 18.8954 17.6737 18 18.8571 18C20.0406 18 21 18.8954 21 20Z" fill="#5CA6FF"/>
  </svg>`;

export const couponSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M20 5H4.00003C3.44775 5 3.00003 5.44771 3.00003 5.99999L3 10C4.10457 10 5 10.8954 5 12C5 13.1046 4.10457 14 3 14L3.00003 18C3.00003 18.5523 3.44775 19 4.00003 19H20C20.5523 19 21 18.5523 21 18V14C19.8954 14 19 13.1046 19 12C19 10.8954 19.8954 10 21 10V6C21 5.44772 20.5523 5 20 5Z" stroke="#5CA6FF" stroke-width="1.5"/>
    <path d="M9 5.5V18.5" stroke="#5CA6FF" stroke-dasharray="2 1"/>
  </svg>`;

export const pollSVG = `<svg xmlns="http://www.w3.org/2000/svg" style="width: var(--svg-size, 24px); height: var(--svg-size, 24px);"  viewBox="0 0 24 24" fill="none" preserveAspectRatio="xMidYMid">
    <path d="M4 9V20H20V9M4 9L7 4H17L20 9M4 9H7H17H20" stroke="#5CA6FF" stroke-width="1.5"/>
    <path d="M16 11L10.6667 16L8 13.5" stroke="#5CA6FF" stroke-width="1.5"/>
    <line x1="15" y1="6.5" x2="9" y2="6.5" stroke="#5CA6FF"/>
  </svg>`;
