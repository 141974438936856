import React from "react";
import { Link, useLocation } from "react-router-dom";
import { cls } from "../../util";

const Header = () => {
  const { pathname } = useLocation();

  return (
    <div className="mb-[37px]">
      <div className="flex items-end space-x-4 max-w-[1200px] m-auto mt-[34px]">
        <div className="w-[102px] h-[31px] bg-[url(../public/logo.svg)]"></div>
        <div className="text-main text-[20px] font-semibold">ADMIN</div>
      </div>
      <div className="bg-gray-300 h-[2px] mt-4" />
      <section>
        <div className="flex items-center space-x-10 py-3 border-b-[1px] border-[#F5F5F5] max-w-[1200px] m-auto">
          <Link to="/member">
            <div
              className={cls(
                pathname.startsWith("/memberdetail") || pathname === "/"
                  ? "text-main"
                  : "text-[#333]",
                "font-medium text-md"
              )}>
              회원관리
            </div>
          </Link>
          <Link to="/level">
            <div
              className={cls(
                pathname.startsWith("/level") ? "text-main" : "text-[#333]",
                "font-medium text-md"
              )}>
              등업신청
            </div>
          </Link>
          <Link to="/board">
            <div
              className={cls(
                pathname.startsWith("/board") ? "text-main" : "text-[#333]",
                "font-medium text-md"
              )}>
              게시글관리
            </div>
          </Link>
          <Link to="/reported">
            <div
              className={cls(
                pathname.startsWith("/reported") ? "text-main" : "text-[#333]",
                "font-medium text-md"
              )}>
              신고관리
            </div>
          </Link>
          {false && (
            <Link to="/contentscate">
              <div
                className={cls(
                  pathname.startsWith("/contentscate")
                    ? "text-main"
                    : "text-[#333]",
                  "font-medium text-md"
                )}>
                콘텐츠 카테고리
              </div>
            </Link>
          )}
          <Link to="/banner">
            <div
              className={cls(
                pathname.startsWith("/banner") ? "text-main" : "text-[#333]",
                "font-medium text-md"
              )}>
              배너관리
            </div>
          </Link>
          <Link to="/notice">
            <div
              className={cls(
                pathname.startsWith("/notice") ? "text-main" : "text-[#333]",
                "font-medium text-md"
              )}>
              공지관리
            </div>
          </Link>
          <Link to="/seminar">
            <div
              className={cls(
                pathname === "/seminar" ? "text-main" : "text-[#333]",
                "font-medium text-md"
              )}>
              세미나관리
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Header;
