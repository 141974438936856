import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const brDOM = ['br', {class: MEDISTREAM_SCHEMA_STYLE.nodes.br}]

/**
 * 블록 내 줄 변경은 <br> 태그로 표현됩니다. (Shift + Enter)
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const hardBreakNodeSpec = {
  inline: true,
  group: 'inline',
  selectable: true,
  atom: true,
  parseDOM: [{tag: 'br'}],
  toDOM() {
    return brDOM
  },
}
