import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FileInput from "../../components/form/FileInput";
import Header from "../../components/Header/Header";

const Registration = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [category, setCategory] = useState(state?.bannerUrl || "");
  const [order, setOrder] = useState(state?.bannerType || 0);
  const [image, setImage] = useState(state?.imageFilename);

  return (
    <>
      <Header />
      <div className="inner px-4">
        <div className="font-bold text-[21px] text-[#333] mb-8">
          콘텐츠 카테고리 등록
        </div>
        <div className="px-6">
          <div className="shadow-custom rounded-lg py-6 space-y-4">
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                카테고리명
              </div>
              <input
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="text-input"
                placeholder="카테고리명을 입력하세요."
              />
            </div>
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                노출순서
              </div>
              <input
                type="text"
                value={order}
                onChange={(e) => setOrder(e.target.value)}
                className="text-input"
                placeholder="숫자를 입력하세요."
              />
            </div>
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                이미지 첨부
              </div>
              <div className="w-[380px]">
                <FileInput
                  selectFile={async (f) => {
                    setImage(f);
                  }}
                  file={image}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end space-x-2 mt-8">
            <button className="white" onClick={() => navigate(-1)}>
              취소
            </button>
            <button className="main">
              {state?.bannerUrl ? "수정" : "등록"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
