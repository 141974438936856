import { CellSelection } from "@medistream/prosemirror-tables";

/**
 * @type {(fn: string) => import('prosemirror-state').Command}
 */
export const setFontSize = (fontSize) => (state, dispatch) => {
  const tr = state.tr;
  const selection = tr.selection;
  const { from, to } = state.selection;

  if (selection instanceof CellSelection) {
    const heading = state.schema.nodes.heading;
    const paragraph = state.schema.nodes.paragraph;
    const tasks = [];
    const allowedNodeTypes = new Set([heading, paragraph]);

    selection.forEachCell((cell, pos) => {
      cell.content.forEach((node, offset) => {
        const resolvedNode = state.doc.resolve(pos + offset + 1).nodeAfter;
        if (allowedNodeTypes.has(node.type)) {
          tasks.push({
            pos: pos + offset + 1,
            posEnd: pos + offset + 1 + resolvedNode.nodeSize,
          });
        }
      });
    });

    if (!tasks.length) return false;

    tasks.forEach(job => {
      const {pos, posEnd} = job

      fontSize === 'reset-style'
        ? tr.removeMark(pos, posEnd, state.schema.marks.font_size)
        : tr.addMark(pos, posEnd, state.schema.marks.font_size.create({style: `font_size: ${fontSize};`}))
    })

    dispatch(tr);
    return true;
  }

  if (fontSize === 'reset-style') {
    tr.removeMark(from, to, state.schema.marks.font_size)
    
    return dispatch(tr)
  }

  tr.addMark(from, to, state.schema.marks.font_size.create({ style: `font-size: ${fontSize};` }));
  
  dispatch(tr);
  return true;
};