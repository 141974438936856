import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const liDOM = ['li', {class: MEDISTREAM_SCHEMA_STYLE.nodes.li}, 0]

/**
 * 리스트 요소는 <li> 태그로 표현됩니다.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const listItemNodeSpec = {
  content: '(paragraph|heading) block*',
  defining: true,
  toDOM() {
    return liDOM
  },
  parseDOM: [{tag: 'li'}],
}
