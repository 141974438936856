import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames"

const ulDOM = ['ul', {class: MEDISTREAM_SCHEMA_STYLE.nodes.ul}, 0]

/**
 * 불릿 리스트는 <ul> 태그로 표현됩니다.
 * 한 개 이상의 list_item 을 가질 수 있습니다.
 * 
 * @type {import("prosemirror-model").NodeSpec}
 */
export const bulletListNodeSpec = {
  group: 'block',
  content: 'list_item+',
  toDOM() {
    return ulDOM
  },
  parseDOM: [{tag: 'ul'}],
}
