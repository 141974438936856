import { NodeSelection } from "prosemirror-state";
import { findParentNodeOfTypeClosestToPos } from "prosemirror-utils";
import { removeCustomTemplate } from "../commands/customTemplate";
import { cellTooltip } from "../plugins/cellToolTip";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {Number} pos
 */
export const selectTableNode = (view, pos) => {
  const tableNode = findParentNodeOfTypeClosestToPos(
    view.state.doc.resolve(pos),
    view.state.schema.nodes.table
  );
  if (!tableNode) return false;
  view.dispatch(
    view.state.tr.setSelection(
      NodeSelection.create(view.state.doc, tableNode.pos)
    )
  );
  return true;
};

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 */
export const showCellTooltipButton = (view) => {
  const tr = view.state.tr.setMeta(cellTooltip, "open");
  view.dispatch(tr);
  return true;
};

/**
 * Prosemirror DOM Event Handlers
 */

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {Number} pos
 * @param {MouseEvent} event
 * @returns {Boolean}
 */
export const handlePMClick = (view, pos, event) => {
  if (
    event.target &&
    event.target.parentElement.classList.contains(
      MEDISTREAM_SCHEMA_STYLE.nodes.tableCell
    )
  ) {
    return showCellTooltipButton(view);
  }
  if (
    event.target &&
    event.target.classList.contains(MEDISTREAM_SCHEMA_STYLE.etc.tableWrapper)
  ) {
    return selectTableNode(view, pos);
  }
};

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {MouseEvent} event
 * @returns {Boolean}
 */
export const handlePMKeyDown = (view, event) => {
  if (event.key === "Backspace") {
    return removeCustomTemplate(view.state, view.dispatch);
  }
  return false;
};

/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {ClipboardEvent} event
 */
export const handlePMPaste = (view, event) => {
  const isFilePaste = event.clipboardData && event.clipboardData.files.length;
  if (isFilePaste) {
    return true;
  }
};

export const _equalNodeType = (nodeType, node) => {
  return (
    (Array.isArray(nodeType) && nodeType.indexOf(node.type) > -1) ||
    node.type === nodeType
  );
};

export const _isNodeSelection = (selection) => {
  return selection instanceof NodeSelection;
};

export const _findSelectedNodeOfType = (nodeType) => (selection) => {
  if (_isNodeSelection(selection)) {
    const { node, $from } = selection;
    if (_equalNodeType(nodeType, node)) {
      return { node, pos: $from.pos, depth: $from.depth };
    }
  }
};
/**
 *
 * @param {import('prosemirror-view').EditorView} view
 * @param {MouseEvent} event
 * @returns {Boolean}
 */
export const handlePMMouseup = (view, event) => {
  if (
    event.target &&
    event.target.parentElement.classList.contains(
      MEDISTREAM_SCHEMA_STYLE.nodes.tableCell
    )
  ) {
    return showCellTooltipButton(view);
  }
};
