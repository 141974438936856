import {Fragment, Slice} from 'prosemirror-model'
import {Plugin} from 'prosemirror-state'
import {medistreamSchema} from '../schemas'
import { _findSelectedNodeOfType } from "../utils/prosemirror"
import {generateYoutubeEmbedSrc} from '../utils/regex'

/**
 * 참고한 자료: https://github.com/bangle-io/bangle.dev/blob/d5363e385a89aea26bf8f90fa543bda06692a4d7/core/components/link.js
 *      https://discuss.prosemirror.net/t/edit-and-update-link/3785/2
 */
export const linkPlugin = pluginsFactory

function pluginsFactory() {
  const type = medistreamSchema.marks.link

  return [
    pasteLink(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
    ),
    markPasteRule(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
      type,
      match => ({href: match}),
    ),
  ]
}

function pasteLink(regexp) {
  return new Plugin({
    props: {
      handlePaste: function handlePastedLink(view, rawEvent, slice) {
        if (!rawEvent.clipboardData) {
          return false
        }

        const text = rawEvent.clipboardData.getData('text/plain')
        const html = rawEvent.clipboardData.getData('text/html')
        const isPlainText = text && !html
        const isYoutubeLink =
          (text.startsWith('https://youtu.be') ||
            text.startsWith('https://www.youtube.com/watch?')) &&
          !text.includes(' ')

        // 유튜브 링크를 붙여넣을 경우 iframe 노드를 생성합니다.
        if (isPlainText && isYoutubeLink) {
          const tr = view.state.tr
          const {from} = view.state.selection
          const {url, videoId} = generateYoutubeEmbedSrc(text)
          const iframeNode = view.state.schema.nodes.iframe.create({
            src: url,
            'data-platform': 'youtube',
            'data-youtube-id': videoId
          })
          tr.insert(from, iframeNode)
          view.dispatch(tr)
          return false
        }

        if (!isPlainText || view.state.selection.empty) {
          return false
        }

        const {state, dispatch} = view
        const match = matchAllPlus(regexp, text)
        const singleMatch = match.length === 1 && match.every(m => m.match)

        // Only handle if paste has one URL
        if (!singleMatch) {
          return false
        }

        return createLink(text)(state, dispatch)
      },
    },
  })
}

function markPasteRule(regexp, type, getAttrs) {
  return new Plugin({
    props: {
      transformPasted: function transformPasted(slice) {
        return mapSlice(slice, node => {
          if (!node.isText) {
            return node
          }
          const text = node.text
          const matches = matchAllPlus(regexp, text)
          return matches.map(({start, end, match, subString}) => {
            let newNode = node.cut(start, end)
            if (match) {
              var attrs =
                getAttrs instanceof Function ? getAttrs(subString) : getAttrs
              newNode = newNode.mark(type.create(attrs).addToSet(node.marks))
            }
            return newNode
          })
        })
      },
    },
  })
}

// Pos 에 텍스트 노드가 있는지 확인합니다.
function isTextAtPos(pos) {
  return state => {
    const node = state.doc.nodeAt(pos)
    return !!node && node.isText
  }
}

function setLink(from, to, href) {
  href = href && href.trim()
  return filter(
    state => isTextAtPos(from)(state),
    (state, dispatch) => {
      const linkMark = state.schema.marks.link
      let tr = state.tr.removeMark(from, to, linkMark)
      if (href) {
        const mark = state.schema.marks.link.create({
          href: href,
        })
        tr.addMark(from, to, mark)
      }
      if (dispatch) {
        dispatch(tr)
      }
      return true
    },
  )
}

export function getMarkAttrs(editorState, type) {
  const {from, to} = editorState.selection
  let marks = []

  editorState.doc.nodesBetween(from, to, node => {
    marks = [...marks, ...node.marks]
  })

  const mark = marks.find(markItem => markItem.type.name === type.name)

  return mark ? mark.attrs : {}
}

export function filter(predicates, cmd) {
  return function (state, dispatch, view) {
    if (!Array.isArray(predicates)) {
      predicates = [predicates]
    }
    if (predicates.some(pred => !pred(state, view))) {
      return false
    }
    return cmd(state, dispatch, view) || false
  }
}

export function mapSlice(slice, callback) {
  const fragment = mapFragment(slice.content, callback)
  return new Slice(fragment, slice.openStart, slice.openEnd)
}

export function mapFragment(content, callback, parent) {
  const children = []
  for (let i = 0, size = content.childCount; i < size; i++) {
    const node = content.child(i)
    const transformed = node.isLeaf
      ? callback(node, parent, i)
      : callback(
          node.copy(mapFragment(node.content, callback, node)),
          parent,
          i,
        )
    if (transformed) {
      if (transformed instanceof Fragment) {
        children.push(...getFragmentBackingArray(transformed))
      } else if (Array.isArray(transformed)) {
        children.push(...transformed)
      } else {
        children.push(transformed)
      }
    }
  }
  return Fragment.fromArray(children)
}

export function getFragmentBackingArray(fragment) {
  return fragment.content
}

/**
 * Runs matchAll and gives range of strings that matched and didnt match
 *
 * @param {*} regexp
 * @param {string} str
 */
export function matchAllPlus(regexp, str) {
  const matches = [...str.matchAll(regexp)]
  if (matches.length === 0) {
    return [
      {
        start: 0,
        end: str.length,
        match: false,
        subString: str,
      },
    ]
  }

  let result = []
  let prevElementEnd = 0
  for (let i = 0; i < matches.length; i++) {
    let cur = matches[i]
    let curStart = cur.index
    // TODO there was an error saying length of undefined in this function
    // I suspect it is coming from line below. But not sure how to reproduce it.
    let curEnd = curStart + cur[0]?.length

    if (prevElementEnd !== curStart) {
      result.push({
        start: prevElementEnd,
        end: curStart,
        match: false,
      })
    }
    result.push({
      start: curStart,
      end: curEnd,
      match: true,
    })
    prevElementEnd = curEnd
  }

  const lastItemEnd = result[result.length - 1] && result[result.length - 1].end

  if (lastItemEnd && lastItemEnd !== str.length) {
    result.push({
      start: lastItemEnd,
      end: str.length,
      match: false,
    })
  }

  result = result.map(r => ({...r, subString: str.slice(r.start, r.end)}))
  return result
}

/**
 *
 * Commands
 *
 */

/**
 * Sets the selection to href
 * @param {*} href
 */
export function createLink(href) {
  return filter(
    state =>
      queryIsLinkAllowedInRange(
        state.selection.$from.pos,
        state.selection.$to.pos,
      )(state),
    (state, dispatch) => {
      const [from, to] = [state.selection.$from.pos, state.selection.$to.pos]
      const linkMark = state.schema.marks.link
      let tr = state.tr.removeMark(from, to, linkMark)

      if (href.trim()) {
        const mark = state.schema.marks.link.create({
          href: href,
        })
        tr.addMark(from, to, mark)
      }

      if (dispatch) {
        dispatch(tr)
      }
      return true
    },
  )
}

/**
 * @type {(fn: string) => import('prosemirror-state').Command}
 */
export function updateLink(href) {
  return (state, dispatch) => {
    const imageNode = _findSelectedNodeOfType(state.schema.nodes.image)(
      state.selection
    )

    // 이미지를 선택한 상태라면 이미지에 링크를 씌웁니다.
    if (imageNode) {
      const {pos} = imageNode
      const tr = state.tr.removeMark(pos, pos + 1, state.schema.marks.link)
      if (href) {
        tr.addMark(pos, pos + 1, state.schema.marks.link.create({href}))
      }
      if (dispatch) {
        dispatch(tr)
      }
      return true
    }

    // Selection 이 있다면 Selection 전체에 링크를 씌웁니다.
    if (!state.selection.empty) {
      setLink(
        state.selection.$from.pos,
        state.selection.$to.pos,
        href,
      )(state, dispatch)
      return true
    }

    const hasLink = state.selection.$from.marks().some(mark => mark.type === state.schema.marks.link)

    // 커서 주변에 이미 링크가 씌워져 있다면 링크를 업데이트 합니다.
    if (state.selection.empty && hasLink) {
      const {$from} = state.selection
      const pos = $from.pos - $from.textOffset
      const node = state.doc.nodeAt(pos)
      let to = pos
  
      if (node) {
        to += node.nodeSize
      }
  
      setLink(pos, to, href)(state, dispatch)
      return true
    }

    // Selection 과 link Mark 가 모두 없다면 링크가 씌워진 텍스트를 본문에 삽입합니다.
    const text = state.schema.text(href, [state.schema.marks.link.create({href})])
    const tr = state.tr.replaceSelectionWith(text, false)

    dispatch(tr)
    return true
  }
}

export function queryLinkAttrs() {
  return state => {
    const {$from} = state.selection

    const pos = $from.pos - $from.textOffset

    const $pos = state.doc.resolve(pos)
    const node = state.doc.nodeAt(pos)
    const {nodeAfter} = $pos

    if (!nodeAfter) {
      return
    }

    const type = state.schema.marks.link

    const mark = type.isInSet(nodeAfter.marks || [])

    if (mark) {
      return {
        href: mark.attrs.href,
        text: node.textContent,
      }
    }
  }
}

export function queryIsLinkAllowedInRange(from, to) {
  return state => {
    const $from = state.doc.resolve(from)
    const $to = state.doc.resolve(to)
    const link = state.schema.marks.link
    if ($from.parent === $to.parent && $from.parent.isTextblock) {
      return $from.parent.type.allowsMarkType(link)
    }
  }
}

export function queryIsLinkActive() {
  return state =>
    !!state.doc.type.schema.marks.link.isInSet(state.selection.$from.marks())
}

export function queryIsSelectionAroundLink() {
  return state => {
    const {$from, $to} = state.selection
    const node = $from.nodeAfter

    return (
      !!node &&
      $from.textOffset === 0 &&
      $to.pos - $from.pos === node.nodeSize &&
      !!state.doc.type.schema.marks.link.isInSet(node.marks)
    )
  }
}
