import { Fragment } from "prosemirror-model";
import { TextSelection } from "prosemirror-state";
import {
  findParentNodeOfTypeClosestToPos,
  isNodeSelection,
} from "prosemirror-utils";
import { MEDISTREAM_SCHEMA_STYLE } from "../styles/classNames";

/**
 *
 * @param {import('prosemirror-state').EditorState} state
 * @param {() => void} dispatch
 * @returns
 */
export function insertTable(state, dispatch) {
  const offset = state.tr.selection.anchor + 1;
  const transaction = state.tr;
  const cell = state.schema.nodes.table_cell.create(
    null,
    Fragment.from(state.schema.nodes.paragraph.create())
  );
  const node = state.schema.nodes.table.create(
    null,
    Fragment.fromArray([
      state.schema.nodes.table_row.create(
        null,
        Fragment.fromArray([cell, cell])
      ),
      state.schema.nodes.table_row.create(
        null,
        Fragment.fromArray([cell, cell])
      ),
    ])
  );

  if (dispatch) {
    dispatch(
      transaction
        .replaceSelectionWith(node)
        .scrollIntoView()
        .setSelection(TextSelection.near(transaction.doc.resolve(offset)))
    );
  }

  return true;
}

/**
 * 콜아웃은 셀 한개짜리 네모 박스입니다.
 *
 * @type {import('prosemirror-state').Command}
 */
export const addCallout = (state, dispatch, view) => {
  const offset = state.tr.selection.anchor + 1;
  const transaction = state.tr;
  const cell = state.schema.nodes.table_cell.create(
    null,
    Fragment.from(state.schema.nodes.paragraph.create())
  );
  const node = state.schema.nodes.table.create(
    null,
    Fragment.fromArray([
      state.schema.nodes.table_row.create(null, Fragment.fromArray([cell])),
    ])
  );

  if (dispatch) {
    dispatch(
      transaction
        .replaceSelectionWith(node)
        .scrollIntoView()
        .setSelection(TextSelection.near(transaction.doc.resolve(offset)))
    );
  }

  return true;
};

/**
 * 테이블의 모든 테두리를 제거/복원 하는 명령입니다.
 *
 * @type {import('prosemirror-state').Command}
 */
export const toggleCellBorder = (state, dispatch, view) => {
  const tr = state.tr;

  if (isNodeSelection(state.selection)) {
    if (state.selection.node.type === state.schema.nodes.table) {
      const className = state.selection.node.attrs.class?.includes(
        "transparent"
      )
        ? MEDISTREAM_SCHEMA_STYLE.nodes.table
        : `${MEDISTREAM_SCHEMA_STYLE.nodes.table} transparent`;

      tr.setNodeMarkup(state.selection.from, null, {
        ...state.selection.node.attrs,
        class: className,
      });

      dispatch(tr);
      return true;
    }
  }

  const tableNode = findParentNodeOfTypeClosestToPos(
    state.doc.resolve(state.selection.anchor),
    state.schema.nodes.table
  );

  if (!tableNode) return false;

  const className = tableNode.node.attrs.class?.includes("transparent")
    ? MEDISTREAM_SCHEMA_STYLE.nodes.table
    : `${MEDISTREAM_SCHEMA_STYLE.nodes.table} transparent`;

  tr.setNodeMarkup(tableNode.pos, null, {
    ...tableNode.node.attrs,
    class: className,
  });

  dispatch(tr);
  return true;
};
