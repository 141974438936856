import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosClient } from "../../api/axiosClient";
import { useBannerDetail } from "../../api/banner";
import FileInput from "../../components/form/FileInput";
import Header from "../../components/Header/Header";
import { BANNER_TYPES } from "../../constants";
import { cloudFrontFile, uploadImg } from "../../util";

const BannerWrite = () => {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [url, setUrl] = useState(state?.bannerUrl || "");
  const [location, setLocation] = useState(
    state?.bannerType || BANNER_TYPES[0].value
  );
  const [image, setImage] = useState(state?.imageFilename);
  const [order, setOrder] = useState(undefined);
  const [device, setDevice] = useState("pc");

  const { data: modifyData } = useBannerDetail(id, { disabled: !id });

  useEffect(() => {
    if (!id || !modifyData) return;
    setUrl(modifyData?.linkedUrl);
    setLocation(modifyData?.bannerLocation?.id);
    setImage(modifyData?.image);
    setOrder(modifyData?.order);
    setDevice(modifyData?.deviceType);
  }, [modifyData, id]);

  const submit = () => {
    axiosClient(token)
      .post("/admin/banners", {
        location: location,
        linkedUrl: url,
        order: order,
        image: image,
        deviceType: device,
      })
      .then(() => {
        alert("추가되었습니다.");
        navigate("/banner");
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  const modify = () => {
    axiosClient(token)
      .put(`/admin/banners/${id}`, {
        location: location,
        linkedUrl: url,
        order: order,
        image: image,
        deviceType: device,
      })
      .then(() => {
        alert("수정되었습니다.");
        navigate(`/banner`);
      })
      .catch(() => alert("에러가 발생하였습니다."));
  };

  return (
    <>
      <Header />
      <div className="px-4 inner">
        <div className="mb-16 page-tit">배너등록</div>
        <div className="px-6">
          <div className="py-6 space-y-4 rounded-lg shadow-custom">
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                URL
              </div>
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="text-input"
                placeholder="URL 주소를 입력하세요."
              />
            </div>
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                배너위치
              </div>
              <select
                className="w-[380px]"
                value={location}
                onChange={(e) => setLocation(e.target.value)}>
                {BANNER_TYPES.map((type, idx) => (
                  <option key={idx} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                이미지 첨부
              </div>
              <div className="w-[380px]">
                <FileInput
                  selectFile={(e) =>
                    uploadImg(e, token, "/admin/banners/upload-image").then(
                      (url) => setImage(cloudFrontFile(url))
                    )
                  }
                  file={image}
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                노출순서
              </div>
              <input
                type="number"
                value={order}
                onChange={(e) => setOrder(+e.target.value)}
                className="text-input"
                placeholder="숫자를 입력하세요."
              />
            </div>
            <div className="flex items-center">
              <div className="text-[#333] text-lg font-medium w-36 flex items-center justify-center">
                노출환경
              </div>
              <select
                className="w-[380px]"
                value={device}
                onChange={(e) => setDevice(e.target.value)}>
                <option value="pc">pc</option>
                <option value="mobile">mobile</option>
              </select>
            </div>
          </div>
          <div className="flex items-center justify-end mt-8 space-x-2">
            <button className="white" onClick={() => navigate(-1)}>
              취소
            </button>
            {id ? (
              <button className="main" onClick={modify}>
                수정
              </button>
            ) : (
              <button className="main" onClick={submit}>
                등록
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerWrite;
